.fieldable-panels-pane-chart {
  container-name: chart;
  container-type: inline-size;
  .chart-content-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 30px;
    .chart-container {
      max-width: 100%;
      position: relative;
      width: 100%;
    }
  }
  .chart-legend {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
    font-weight: bold;
    white-space: nowrap;
  }
  .legend-item {
    display: flex;
    align-items: flex-start;
    margin-right: 30px;
  }
  .legend-color {
    width: 18px;
    height: 18px;
    margin-top: 2px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    margin-right: 5px;
  }
  .label-text {
    text-anchor: middle;
    dominant-baseline: middle;
    font-size: 36px;
    font-weight: 700;
    fill: var(--kisaco-primary-color);
  }
  .percent-text {
    font-size: 28px;
    // fill: #333;
    text-anchor: middle;
    font-weight: 700;
  }
  svg {
    width: 100%;
    height: auto;
  }
}

@container chart (min-width: 800px) {
  .fieldable-panels-pane-chart {
    .chart-content-wrapper {
      flex-direction: row;
    }
    .chart-container {
      margin-top: -5rem;
      flex: 1 0 560px;
    }
  }
}

@container chart (max-width: 600px) {
  .fieldable-panels-pane-chart {
    .chart-legend {
      flex-direction: column;
    }
  }
}

@container chart (max-width: 800px) {
  .fieldable-panels-pane-chart {
    .chart-legend {
      justify-content: center;
    }
  }
}
