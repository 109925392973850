/**
 * Styling for venue pane content.
 */
#venue {
  padding-bottom: 0;
}

.pane .pane-content-venue {
  h3 {
    margin-top: 0;
    padding-top: 0;
    display: block;
  }
  .venue-address {
    margin: 0 4em;
  }
  .header-line {
    margin: 2em auto;
  }
  .event-venue-map {
    position: relative;
    .map-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .arrow-down {
    display: none;
  }
}

@media #{$mediaquery-desktop}, #{$mediaquery-large-desktop} {
  /**
   * Make map 100% height.
   */
  .pane .pane-content-venue {
    .venue-row {
      display: table;
      height: 100%;
      width: calc(100% + 30px);
      .event-venue-info {
        display: table-cell;
        height: 100%;
      }
      .event-venue-map {
        div:not(.arrow-right) {
          height: 100%;
        }
      }
    }
  }
}

@media #{$mediaquery-phone} {
  .pane .pane-content-venue {
    .event-venue-info {
      word-wrap: break-word;
    }
    .venue-address {
      margin: 0 1em;
    }
    .header-line {
      display: block;
      margin: 2em auto;
    }
    .map-overlay {
      .arrow-right {
        display: none;
      }
    }
  }
}

@media #{$mediaquery-phone}, #{$mediaquery-tablet} {
  .pane .pane-content-venue {
    .event-venue-map {
      height: 400px;
      * {
        height: 400px;
      }
    }
    .map-overlay {
      .arrow-right {
        display: none;
      }
      .arrow-down {
        margin-top: -1px;
        display: block;
      }
    }
  }
}