.field-collection-item-field-features {
  .field-name-field-title {
    font-weight: bold;
    color: $brand-blue;
    // text-align: center;
  }
  .field-name-field-featured-image {
    img {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}

.pane-bundle-solutions-widget {
  .first-feature {
    &--image {
      img {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    &--body {
      h1 {
        font-size: 32px;
        margin-top: 0;
        font-weight: bold;
      }
    }
  }
}
