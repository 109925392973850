/**
 * Pseudo element to activate the accordion container.
 */
.css-accordion {
  position: relative;

  /**
   * Hide the checkbox.
   */
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  label {
    position: absolute;
    width: 100%;
    min-height: 50px;
    top: 1.3rem;
    cursor: pointer;
    color: $gray;
    display: flex;
    justify-content: space-between;

    /**
     * Angle down (or right)
     */
    &::after {
      position: absolute;
      right: 0;
      display: block;
      content: '\F105';
      font-family: 'FontAwesome';
      font-size: 4rem;
      width: 10px;
      height: 20px;
      // border: 1px solid black;
      line-height: 18px;
      text-align: center;
      transition: all 0.4s;
      transform-origin: center;
      margin-left: 30px;
      margin-right: 4px;
    }

    /**
     * Content is initially collapsed.
     */
    & ~ .original-article-content .field-name-body,
    & ~ .field-name-field-content {
      display: none;
    }

    /**
     * Accordion is open.
     */
    &:has(input:checked) {
      &::after {
        transform: rotate(90deg);
      }
      & ~ .original-article-content .field-name-body,
      & ~ .field-name-field-content {
        display: block;
      }
    }
  }
}
