/**
 * Overrides for jobs page.
 */

/**
 * Available Jobs title should be left aligned.
 */
.page-careers {
  #available-jobs {
    .pane-title {
      text-align: left;
      margin-bottom: 30px;
    }
  }
}