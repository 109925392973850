#document-webform-wrapper,
.inverted-pane {
  .hbspt-form {
    padding: 40px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
  }
}

.fieldable-panels-pane-pardot-form {
  padding-top: 30px;
  .field-name-field-subheading {
    text-transform: uppercase;
    font-weight: bold;
    color: $brand-blue;
    font-size: $font-size-h4;
    text-align: center;
    padding: 10px 0 20px;
  }
  .rh-col {
    .field-name-field-subheading {
      @media screen and (min-width: 960px) {
        text-align: left;
      }
    }
  }
  .hubspot-form-wrapper {
    &--expand {
      @media screen and (min-width: 992px) {
        margin: 0 10%;
      }
      @media screen and (min-width: 1200px) {
        margin: 0 14%;
      }
    }
  }
}