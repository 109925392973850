// Styles for admin pages.
// --------------------------------------------------
.views-display-setting .views-admin-label {
  // From views-admin.css
  display: inline-block;
  float: left; /* LTR */
  // From views-admin.theme.css
  margin-right: 3pt; /* LTR */
}

/**
 * Admin fixes and tweaks
 */

/**
  * Fix form-control clash.
  */
#admin-menu {
  box-shadow: 0 -10px 10px 8px #333;
}
.admin-menu-search {
  width: auto !important;
}
.admin-menu-search input {
  height: 18px !important;
}

/**
 * Font size base for pretty much anything admin related should be 16px
 */
body.page-admin {
  &:not(.page-admin-config-mini-panels-ipe) {
    font-size: $font-size-admin-base;
    input.form-control {
      font-size: $font-size-admin-base;
    }
  }
}

.secondary li {
  font-size: $font-size-small;
  a {
    font-size: $font-size-small;
  }
}

/**
 * Fixes issue with linkit overlay not showing correctly with Radix theme
 * pulled from the tweak that was made in Bartik Responsive to fix this.
 */
.ui-widget-overlay {
  background: #222222;
  opacity: 0.7;
}

/**
 * Fix IPE with bootstrap so there isn't a huge gap between settings 
 * and content editor when long previews exist.
 */
.option-text-aligner {
  clear: none;
  width: 100%;
  padding: 0;
  margin: 0;
}

/**
  * Make the content editing panel sidebar headers prettier
  * for admin purposes.
  */
.page-node-add,
.page-node-edit {
  .panel-pane {
    h2.pane-title {
      font-size: 24px;
      border-bottom: 1px solid #ccc;
      text-align: left;
    }
  }
}

/**
 * Fix readability for date picker.
 */
#ui-datepicker-div {
  border-right: none;
  border-bottom: none;
}

.ui-datepicker * {
  font-family: $font-family-base;
}

.ui-widget-header {
  background: $brand-white;
}

.ui-datepicker-header {
  select {
    font-family: $font-family-base;
    font-size: $font-size-base;
    color: $brand-blue !important;
  }
}

.ui-datepicker-calendar {
  thead th span {
    font-family: $font-family-monospace !important; // to keep cells even
  }
}

.ui-datepicker-month,
.ui-datepicker-year {
  font-size: $font-size-base !important;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  border: 1px solid $brand-blue;
  background: $brand-white;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  background: $brand-blue;
  border: 1px solid $brand-white;
  color: $brand-white;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  background: $brand-gray;
  border: 1px solid $brand-white;
}

/**
 * Fixes webform_term_opts add and delete icon layout.
 */
div.options-widget table td.option-actions-cell {
  width: 55px;
}

/**
 * Fixes style of the IPE drag bar.
 */
.panels-ipe-dragbar-admin-title {
  padding-left: 8px;
  font-family: $font-family-admin;
  font-weight: bold;
}

/**
 * Apply / Reset buttons are not set properly in line with rest of form.
 */
.views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 1.4em !important;
  margin-right: 8px;
}

/**
 * Widens the author text box dropdown.
 */
#edit-author {
  width: 17em;
}

/**
 * Correct placement of media thumbnails checkboxes.
 */
.media-list-thumbnails .form-type-checkbox {
  bottom: 97px;
}

/**
 * Fix Media thumbnails filter widget alignment.
 */
.file-entity-admin-file-form {
  .exposed-filters .form-select {
    width: 13em;
  }
}

/**
 * Fix form builder widget selectors.
 */
#form-builder-field-palette ul li {
  width: 122px;
}

.form-builder-hover {
  margin-top: 8px;
  padding-top: 4px !important;
}

span.form-builder-links {
  right: 8px !important;
}

span.form-builder-bottom-links {
  a.clone {
    height: 20px !important;
    width: 17px !important;
  }
  right: 6px !important;
  bottom: -7px !important;
}

/**
 * Fix field and drag placement for webforms.
 */
.webform-add-form {
  a.tabledrag-handle {
    width: 28px;
    padding-top: 11px;
  }
  .form-item-add-name {
    margin-left: 16px;
  }
  .form-item-add-required {
    label {
      padding-left: 0 !important;
    }
  }
}

/**
 * Fix IPE display settings radio buttons overlapping labels.
 */
.form-item-view-settings input[type='radio'] {
  margin-left: -18px !important;
}

/**
 * Fix offset issue of autocomplete when rendered in the context overlay.
 */
form.ctools-auto-submit-full-form {
  position: relative;
}

/**
 * Fix container width issue when used within an overlay for 
 * References Dialog.
 */
#references-dialog-page {
  .container {
    width: auto !important;
  }
}

/**
 * Fixes minor offset issue on references dialog.
 */
div.ui-dialog {
  background: $brand-white !important;
}

/**
 * Hide double "URL" label on sponsors page 
 * (coincidentally the field name is URL, but the 
 * link field usually shows both a URL and title field, so it appears as a 
 * double "URL" label)
 */
.page-node-add-sponsor {
  .form-type-link-field > label {
    display: none;
  }
}

/**
 * Fixes for events.
 * Tab Nav fixes.
 */
.page-event {
  .nav.nav-tabs.primary {
    margin-top: 20px;
  }
}

/**
 * Adds an admin-hidden class for fading elements so admins know it's
 * not being shown publicly.
 */
.admin-hidden {
  opacity: 0.6;
}

/**
 * Additional various admin fixes.
 * Style translation page.
 */
.page-node-translate {
  .event-cover {
    display: none;
  }
  #main-wrapper {
    margin-top: 100px;
  }
}

/**
 * Correctly wraps content in containers where applicable.
 */
.page-node-revisions,
.page-node-translate {
  #content {
    @extend .container;
  }
}
/* 404 page */
.page-node-190 {
  ul.action-links {
    @extend .container;
  }
}

/**
 * Style language picker for panels.
 */
#modalContent {
  .form-item-language {
    float: left;
    margin-top: -120px;
    width: 48%;
  }
  /**
   * Fixes button styles in IPE.
   */
  .btn {
    border: 2px solid $brand-blue;
    padding: 6px 12px !important;
  }
}

/**
 * Style adjustments for draggable elements for form builder.
 */
div.form-builder-placeholder {
  border: none;
}

/**
 * Fixes an issue where the field palette can disappear behind the admin menu bar.
 */
#form-builder-field-palette {
  .item-list {
    padding-top: 120px;
  }
}

/**
 * Fixes home page tab placement.
 */
.logged-in.page-node-1 #page-header {
  margin-top: -20px;
  margin-bottom: 32px;
}

/**
 * Fixes "Customize dashboard" button.
 */
.page-admin-dashboard {
  ul.action-links {
    li {
      a {
        @extend .btn;
        @extend .btn-default;
      }
    }
  }
  #dashboard #disabled-blocks .block {
    background: $gray;
    h2 {
      font-size: 14px;
    }
  }
  a.button {
    @extend .btn;
    @extend .btn-default;
  }
}

/**
 * Fixes all "browse" buttons.
 */
a.browse {
  border: 1px solid $brand-blue !important;
}

/**
 * General fixes to the event edit page.
 */
.page-node-edit.node-type-event {
  .edit-field-facebook-url-und-0-url,
  .edit-field-linkedin-url-und-0-url,
  .edit-field-google-plus-url-und-0-url,
  .edit-field-twitter-url-und-0-url {
    display: none;
  }
  .pane-node-form-menu {
    display: none;
  }
  .pane-node-field-featured-image .image-preview img {
    width: 360px !important;
    height: auto !important;
  }
  .pane-node-form-buttons .form-actions {
    input {
      &#edit-draft {
        background: $gray-light !important;
        color: $brand-blue !important;
      }
      &#edit-preview {
        display: none;
        background: $gray-light !important;
        color: $brand-blue !important;
      }
      &#edit-delete {
        background: $brand-red !important;
      }
    }
  }
  .vertical-tabs ul.vertical-tabs-list {
    width: 10em !important;
    margin: -1px 0 -1px -10em;
  }
  div.vertical-tabs {
    margin-left: 10em !important;
  }
  #edit-cpn-css,
  #edit-cpn-js,
  #edit-cpn-noscript {
    font-family: 'Menlo', 'Courier New', monospace;
    font-size: 11px;
  }
  .redirect-list {
    font-size: 11px;
  }
  #field-attendees-values,
  #field-speaker-presentations {
    .form-submit {
      @extend .pull-right;
    }
  }
  .date-no-float .form-item .date-padding {
    label {
      display: none;
    }
    span.help-block {
      display: none;
    }
  }
  /**
   * Fix all date widget displays.
   */
  .date-form-element-content-multiline {
    @extend .row;
    border: none;
    padding: 0;
  }
  .date-float {
    @extend .col-md-12;
  }
  .start-date-wrapper,
  .end-date-wrapper {
    @extend .col-md-5;
    clear: none;
  }
  .end-date-wrapper {
    margin-top: -30px;
  }

  .field-name-field-speaker-presentations {
    .dialog-links {
      ul {
        padding-left: 0;
      }
    }
    table.field-multiple-table {
      td {
        @extend .row;
      }
    }
    .field-name-field-speaker-sponsor {
      @extend .col-md-7;
    }
    .field-name-field-basic-file-file {
      @extend .col-md-4;
    }
  }

  label[for='edit-field-regional-references-und-0-field-url-und-0'] {
    display: none;
  }
}

/**
 * Make all field collection remove buttons red.
 */
.field-type-field-collection {
  .form-submit:not(.field-add-more-submit) {
    background: $brand-red !important;
    color: white !important;
    border: none !important;
    /*border-radius: 100px;
    border: none;*/
  }
}

/**
 * Fix panel heading color.
 */
.panel-default > .panel-heading {
  color: $brand-blue;
}

/**
 * Style partner display page and line items.
 */
.node-type-partner {
  .submitted {
    margin-bottom: 15px;
  }
  .field,
  .field-collection-container,
  .field-name-field-primary-contact,
  .field-name-field-secondary-contact {
    @extend .panel;
    @extend .panel-default;
    .field-label {
      @extend .panel-heading;
    }
    .field-items {
      @extend .panel-body;
      &:empty::before {
        content: '- No data entered -';
        color: $gray-light;
      }
    }
    .field-collection-view .field-label {
      width: 100%;
    }
  }
}

.page-field-collection-field-line-items {
  .content {
    .field {
      @extend .panel;
      @extend .panel-default;
      .field-label {
        @extend .panel-heading;
      }
      .field-items {
        @extend .panel-body;
        &:empty::before {
          content: '- No data entered -';
          color: $gray-light;
        }
      }
    }
  }
}

body.front #page-header .tabs {
  position: absolute;
  z-index: 500;
}

/**
 * The language picker just isn't needed enough to justify it showing up on
 * the IPE, and sometimes overlaps with everything else.
 */
.ctools-modal-dialog .form-item-language {
  display: none;
}

/**
 * Implement field collection admin tweaks.
 */
.action-links-field-collection-add,
.field-collection-view-links {
  margin-right: 0 !important;
  padding-top: 5px !important;
}

.action-links-field-collection-add li {
  margin-right: 0 !important;
}

.action-links-field-collection-add a,
.field-collection-view-links a {
  font-size: 10px;
  border: 1px solid #ddd;
  padding: 2px 5px;
  border-radius: 1000px;
}
.action-links-field-collection-add a::before {
  content: '+ ';
}
