/**
 * Display styles for search page.
 */

.page-search {
  .container-fluid {
    @extend .container;
  }
  h1.title {
    text-transform: initial;
  }
  h3.field-content {
    text-align: left;
    text-transform: initial;
    padding-top: 0;
    font-size: 24px;
    margin-top: 0;
  }
  .views-row {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid $gray-light;
  }
  h2.pane-title {
    text-align: left;
    text-transform: initial;
    font-size: 24px;
    border-bottom: 1px solid $gray-light;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
  .pane-search-box form .form-wrapper .form-group {
    display: inline-block;
  }
  .form-item-keys {
    margin-top: 9px;
  }
  .panopoly-image-quarter {
    @extend .pull-right;
    margin-left: 15px;
  }
  
  .facetapi-facetapi-links {
    list-style-type: none;
    li {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid $gray-light;
      margin-left: 0;
    }
  }
}