@import './mixins';

div {
  .caption-inner {
    @include caption;
  }
}

.notranslate {
  a[href^='tel'] {
    color: inherit;
    text-decoration: none;
    pointer-events: none;
  }
}
