/**
 * Style for key statistics section.
 */
.field-name-field-key-statistic {
  .content {
    padding-top: 6rem;
  }
  color: var(--kisaco-primary-color);
  container-name: key-statistics;
  container-type: inline-size;
  .field-name-field-icon {
    .fa-3x {
      font-size: 3.6em;
    }
  }
  .field-name-field-stat {
    font-size: $font-size-h1;
    font-weight: bold;
    margin-top: 0.2em;
    line-height: 1em;
    /**
      * Override field labels themselves in IPE.
      */
    .form-item {
      margin-top: initial;
      line-height: initial;
      font-size: initial;
    }
  }
  .field-name-field-description {
    font-size: $font-size-smaller;
    text-transform: lowercase;
    font-weight: bold;
    text-align: center;
    .field-item {
      margin: 0 auto;
      max-width: 13em;
    }
    /**
      * Override field labels themselves in IPE.
      */
    .form-item {
      font-size: initial;
      text-align: initial;
      text-transform: initial;
    }
  }
}

@container key-statistics (min-width: 800px) {
  .field-name-field-description {
    margin-bottom: 30px !important;
  }
}

@container key-statistics (max-width: 801px) {
  .field-name-field-key-statistic {
    .content {
      padding-top: 0;
    }
    .row {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      > div {
        width: 100% !important;
        > div {
          border-bottom: none !important;
        }
      }
    }
  }
}
