/**
 * Overrides and customizations for easy-pie-chart.
 */

/**
 * Example usage:
 * 
<div class="chart">
  <div class="percentage" data-percent="73"><span>73</span>%</div>
  <div class="chart-label">New visitors</div>
</div>
*/

/**
 * The chart width. This must match the value given in the initialization
 * script (easyPieChart object - size attribute).
 */
$chart-width: 190px;

/**
 * Override absolute positioning left value to center.
 * Always 50% of the width of the chart wrapping element, minus half the 
 * chart width.
 */
.chart {
  margin-top: 1em;
  canvas {
    left: calc(50% - #{$chart-width * 0.5});
  }
}

/**
 * Fix the inline styling that gets applied to the chart after
 * initialization.
 */
.percentage {
  width: auto !important;
  height: auto !important;
}

/**
 * Actual customization begins here.
 */
.chart {
  text-align: center;
  .percentage {
    font-size: $font-size-h3;
    margin-bottom: 0.1em;
    span {
      font-weight: bold;
      font-size: $font-size-h1;
    }
  }
  .chart-label {
    text-align: center;
    font-weight: bold;
  }
}