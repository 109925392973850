#main-wrapper.page.has-background-image {
  @media #{$mediaquery-not-phone} {
    padding-top: 0px;
  }
}

#main-wrapper.page.has-notch {

  @media #{$mediaquery-not-phone} {
    padding-top: 60px;
    margin-top: -145px;
  }

  /**
   * Prevent content from going up underneath the notch.
   * This allows content to display over it.
   */
  position: relative;

  #messages {
    margin-top: 100px;
  }

  /**
   * Ensure that if a pane-content is present but the pane has no title, 
   * add 35px margin so it doesn't flush with the top edge.
   */
  .panel-pane > .pane-content {
    margin-top: 35px;
  }
  .panel-pane > .pane-title + .pane-content {
    margin-top: 0;
  }



}

.cover-header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: middle;
  justify-content: flex-end;
  height: 400px;
  @media screen and (max-width: 767px) {
    height: 270px;
  }
}

.cover-header-full {
  height: auto;
  min-height: 400px;
  @media screen and (max-width: 767px) {
    min-height: 270px;
  }
  padding: 30px 0 60px;
  .portfolio-logo {
    img {
      @include atf-logo-size;
    }
  }
  .portfolio-description {
    @media screen and (min-width: 1200px) {
      max-width: 70%;
    }
  }
}

.notch-wrapper {
  position: relative;
  padding-top: 40px;
}

.notch {
  background-color: white;
  height: 100px;
  margin-left: -100px;
  margin-right: -100px;
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.uk-heading-line>::after, .uk-heading-line>::before {
  border-bottom-color: $brand-blue;
}