// Styles for Menus and Navs
// -----------------------------------------------------------------------------

/**
 * Fix panopoly's conflict with bootstrap fixed top navigation and 
 * drupal admin menu
 */
body {
  &.logged-in {
    padding-top: 29px;
    .navbar-fixed-top {
      margin-top: 29px;
    }
  }
  &.navbar-tray-open {
    .navbar-fixed-top {
      margin-top: 79px;
    }
  }
}

.navbar {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
}

.navbar-default {
  border: none;
  .navbar-collapse,
  .navbar-form {
    border: none;
  }
}

.navbar-brand {
  height: 100px;
  /**
   * Logos need to contain top and bottom padding for 
   * scrolling header shrink to work properly.
   */
  padding-top: 0;
  padding-bottom: 0;
  img#logo {
    object-fit: contain;

    /**
     * The padding top and bottom changes based on
     * the current size of the logo based on where we've
     * scrolled to on the page.
     * These values are the default padding for when the page
     * has scrolled down and the logo is small.
     * It is changed dynamically to a max of 20px in headerresize.js.
     * The formula is that the padding is always 1/5 of the height of the container.
     * E.g. 50px tall = 10px padding, or 100px tall = 20px padding.
     */
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

/**
 * When scrolling down, we want the header to be slightly transparent.
 */
.header-transparent {
  .navbar-default {
    background-color: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(5px);
  }
  .navbar {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05);
  }
}

/**
 * Ensure the logo container stays the same width as we scroll,
 * to prevent the main navigation from collapsing to the left.
 */
a.navbar-brand {
  width: 211px !important;
}

/**
 * Style main navigation.
 */
@media #{$mediaquery-desktop}, #{$mediaquery-large-desktop} {
  .navbar-default .navbar-nav > li {
    // prevents unsightly flash of navigation as header scroll
    // takes over the height
    height: 100px;
    line-height: 100px;
    & > a {
      height: 100px;
      line-height: 100px;
      padding-top: 0;
      padding-bottom: 0;
      font-size: $font-size-small;
    }
  }
}
.navbar-default .navbar-nav > li > a {
  color: $brand-blue;
  font-weight: 500;
}

/**
 * Style social media icons for main navigation.
 */
#main-menu {
  .social-media-icons {
    height: 100px;
    line-height: 100px;
    margin-left: 20px;
    &.condensed {
      margin-left: 10px;
    }
    float: right;
    .icon-circular {
      width: 36px;
      height: 36px;
      margin: 0 3px;
    }
  }
  ul.dropdown-menu {
    border: none;
    // margin-top: -1px;
    border-radius: 0;
    min-width: 300px;
    padding-top: 20px;
    @media #{$mediaquery-hamburger-nav} {
      padding-top: 0;
    }
    padding-bottom: 20px;
    box-shadow: 12px 12px 12px rgba(0, 0, 0, 0.3);
    li {
      padding: 10px;
      @media #{$mediaquery-edge} {
        padding: 0;
      }
      a {
        // display: inline;
        color: $brand-blue;
        background-color: $brand-white;
        &:hover {
          background-color: $gray-lighter;
        }
        &.active {
          background-color: $brand-white;
          &:hover {
            background-color: $gray-lighter;
          }
        }
      }
    }
  }
}

/**
 * Style main navigation active links.
 */
.navbar-default {
  .navbar-nav {
    & > li > a {
      border-top: 5px solid transparent;
      border-top-color: transparent !important;
      border-bottom: 5px solid transparent;
      @media #{$mediaquery-hamburger-nav} {
        border-top: none;
        border-bottom: none;
        border-left: 5px solid transparent;
      }
    }
    & > li.active > a,
    & > li.active > a:hover,
    & > li.active > a:focus,
    & > li > a:hover {
      color: $brand-blue;
      background-color: inherit;
      border-bottom: 5px solid $brand-blue;
      @media #{$mediaquery-hamburger-nav} {
        border-bottom: none;
        border-left: 5px solid $brand-blue;
      }
    }
    & > li.highlighted > a,
    & > li.highlighted > a:hover,
    & > li.highlighted > a:focus {
      margin-top: 0;
    }
    @media #{$mediaquery-hamburger-nav} {
      & > li.highlighted {
        margin-bottom: 1rem;
      }
    }
  }
}

/**
 * Changes the main navigation breakpoint so as to show the hamburger menu 
 * on Tablet portrait mode.
 * https://stackoverflow.com/questions/28659556/change-navbar-breakpoint-in-bootstrap-3-3-2
 */
@media #{$mediaquery-hamburger-nav} {
  .navbar-header {
    float: none;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    &::before,
    &::after {
      content: none;
    }
  }

  .navbar-left,
  .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    background: $brand-white;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    .dropdown-menu {
      background: $brand-white;
    }
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
    li,
    li > a {
      height: 50px !important;
      line-height: 50px !important;
    }
    li.open,
    li.open > a {
      height: auto !important;
      line-height: auto !important;
    }
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in {
    display: block !important;
  }
  .navbar-nav .open {
    .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: $brand-white;
      border: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      z-index: 10;
    }
  }
}

/**
 * Vertically centers the hamburger menu and adjust style.
 */
.navbar-toggle {
  border: none;
  border-radius: 0;
  .icon-bar {
    background: $brand-blue;
    border-radius: 0;
    height: 4px;
  }
}

.header-scrolled-full {
  .navbar-toggle {
    margin-top: 8px;
    @media screen and (max-width: 767px) {
      margin-bottom: 4px;
    }
  }
}

/**
 * Center the social media icons on mobile.
 */
@media #{$mediaquery-not-desktop} {
  #main-menu .social-media-icons {
    float: none;
    clear: both;
    margin: 0 auto;
    text-align: center;
  }
}

/**
 * Special handling for iPad landscape mode and small desktop.
 */
@media #{$mediaquery-desktop} {
  .navbar-default {
    .nav > li > a {
      padding: 0px 9px;
      font-size: 14px;
    }
  }
}

/**
 * Fix for admin shortcut icons.
 */
.drupal-navbar {
  .nav > li > a {
    padding-top: 13px;
    padding-bottom: 13px;
  }
}

/**
 * Transparent navbar styling.
 */
.navbar-transparent,
.navbar-expanded {
  transition: all 0.3s;
  > .container {
    width: 100% !important;
  }
  .menu.nav.navbar-nav {
    float: right;
    @media #{$mediaquery-hamburger-nav} {
      border-top: 1px solid #f2f2f2;
      margin-bottom: 2rem;
    }
  }
  .nav > li {
    > a {
      font-weight: 500;
    }
  }
}
.navbar-transparent {
  box-shadow: none;
  .nav > li {
    height: auto !important;
    line-height: initial !important;
    > a {
      height: auto !important;
      line-height: initial !important;
    }
  }
}
.navbar-expanded {
  background-color: $brand-white;
  #main-menu {
    li > a {
      padding-top: 0;
      padding-bottom: 0;
    }
    .social-media-icons {
      a {
        background-color: $brand-blue;
        i {
          color: $brand-white;
        }
      }
    }
  }
  @media #{$mediaquery-edge} {
    @include smaller-menu;
  }
}

/**
 * Color of the cover dropdown menu background.
 */
$cover-menu-bg-color: $brand-blue;

.header:not(.header-scrolled) {
  .navbar-expanded {
    @media screen and (min-width: 640px) {
      padding: 0 30px;
    }
  }
  .navbar-transparent {
    @media screen and (min-width: 640px) {
      padding: 30px 60px;
    }

    /**
    * Additional adjustments for edge.
    */
    @media #{$mediaquery-edge} {
      padding-top: 20px;
      @include smaller-menu;
    }

    .menu.nav.navbar-nav {
      padding-top: 15px;
      padding-bottom: 15px;
      @media #{$mediaquery-not-hamburger-nav} {
        padding-top: 30px;
      }
    }
    .nav > li {
      height: auto !important;
      line-height: initial !important;
      > a {
        height: auto !important;
        line-height: initial !important;
        &:not(:hover):not(:focus):not(:active) {
          color: $brand-white;
        }
        font-weight: 500;
      }
      &.open {
        a {
          background-color: $cover-menu-bg-color;
          color: $brand-white !important;
        }
      }
      .dropdown-menu {
        background-color: $cover-menu-bg-color;
        li > a {
          background: none !important;
          &:hover,
          &:focus,
          &:active {
            background-color: rgba($brand-white, 0.2) !important;
            // background-color: $brand-white !important;
          }
        }
      }
    }
    .social-media-icons {
      padding-top: 7px;
      height: auto !important;
      line-height: initial !important;
      a {
        background-color: $brand-white;
        i {
          color: $brand-blue;
        }
      }
    }
    .icon-bar {
      background: $brand-white;
    }
    @media #{$mediaquery-hamburger-nav} {
      .navbar-collapse {
        background-color: $cover-menu-bg-color;
        .nav {
          @media #{$mediaquery-hamburger-nav} {
            border-top: none;
          }
          > li {
            > a {
              &:not(:hover):not(:focus):not(:active) {
                color: $brand-white;
              }
            }
          }
        }
      }
    }
  }
}
.header-scrolled {
  .navbar-transparent {
    background-color: $brand-white;

    /**
     * Change the social icons back to blue background when scrolled.
     */
    .social-media-icons {
      a {
        background-color: $brand-blue !important;
        i {
          color: $brand-white !important;
        }
      }
    }
  }
  @media #{$mediaquery-edge} {
    @include smaller-menu;
  }
}

@media screen and (max-width: 767px) {
  .uk-position-center-right-out,
  .uk-position-center-left-out {
    display: none;
  }
}
