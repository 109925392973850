.panels-add-content-modal {
  .panels-section-columns {
    .panels-section-column {
      &:first-child {
        width: 100% !important;
        .inside {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-gap: 20px;
          margin-top: 20px;
          > div {
            border: 1px solid $brand-blue;
            padding: 15px;
            border-radius: 10px;
            a {
              font-weight: bold;
              border-radius: 5px;
            }
            a.btn {
              color: white;
              border: none !important;
              background-color: $brand-blue;
            }
          }
        }
      }
    }
  }
  .panels-section-column {
    &:last-child {
      display: none;
    }
  }
}

#modalContent {
  label {
    font-size: 14px;
  }
  .panel-body.fieldset-wrapper * {
    font-size: 14px;
  }
  .form-control {
    font-size: 14px;
    padding: 3px 6px;
    height: 30px;
  }
  .btn.btn-default {
    padding: 2px 6px;
    font-size: 12px;
    border-radius: 1000px;
  }
  th.field-label {
    padding: 2px 10px !important;
  }
}

/**
 * Disable the "change layout" button.
 */
// .panels-ipe-button-container {
//   .panels-ipe-pseudobutton-container:nth-child(2) {
//     display: none;
//   }
//   .panels-ipe-change-layout {
//     display: none !important;
//   }
// }

body.logged-in > .hbspt-form {
  display: none;
}

/**
 * Allow admins to view the "Save as default" button.
 * This is disabled by Panopoly by default (since it's a dangerous button).
 */
body.is-admin #panels-ipe-edit-control-form #panelizer-save-default {
  display: inline-block;
}
