.fieldable-panels-pane-what-next-cta-widget {
  border-bottom-left-radius: 20px;
  border: 2px dotted $brand-blue;
  border-right: none;
  border-top: none;
  padding: 0 30px 20px;
  color: $brand-blue;

  .field-name-field-title {
    font-size: $font-size-h2;
    color: $brand-blue;
    font-weight: bold;
    text-transform: uppercase;
  }

  .field-name-field-subheading {
    font-weight: bold;
  }

}