// Styles for Forms
// -----------------------------------------------------------------------------

/**
 * Make all form fields blue text, not default light gray.
 */
.form-control {
  color: $brand-blue;
  &::placeholder {
    color: lighten($gray, 15%);
  }
}

/**
 * Hide particular elements on webforms globally.
 */
.webform-component--sponsorship-exhibit-info > label,
.webform-component--e-newsletter-signup > label,
.webform-component--e-newsletter-signup-2 > label {
  display: none;
}

/**
 * Webform company registered details
 */
.webform-client-form-5 {
  .webform-component--registered-details {
    p {
      font-size: 14px;
      border-top: 1px solid white;
      border-bottom: 1px solid white;
      padding: 10px 0;
      margin-bottom: 40px;
    }
  }
}