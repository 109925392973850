@import '../base/mixins';

.node-type-blog {
  .pane,
  .pane-content,
  .panel-pane {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: initial;
    }
  }
  .cover-header {
    visibility: hidden;
  }
  .pane-node-field-tags {
    .pane-content {
      padding-bottom: 0;
      .field-name-field-tags {
        .field-items {
          @include generic-tag-container;
          gap: 1rem;

          .field-item {
            @include generic-tag;
            padding: 0.5rem 1rem;
            background-color: #f2f2f2;
            font-weight: bold;
            font-size: $font-size-h6;
          }
        }
      }
    }
  }
  // Let the summary be more flush up against the title.
  .pane:has(.field-type-text-with-summary) {
    margin: 0;
  }
  .field-name-field-featured-image-caption {
    .field-item {
      @include caption;
    }
  }
  // title
  .pane-node-title {
    .pane-content {
      padding-bottom: 0;
    }
  }
  // the summary
  .pane.basic-pane .field-name-body {
    padding: 0;
    background-color: transparent;
  }
  // the byline - provided by custom text in panelizer
  .panel-pane.pane-custom.pane-1,
  .pane-kisaco-global-kisaco-global-blog-author-byline {
    .pane-content {
      padding-bottom: 0;
    }
  }
  // body copy
  .pane-node-body {
    .field-name-body {
      > .field-items > .field-item {
        a {
          text-decoration: underline;
          /* make the underline itself blue, but not the text */
          text-decoration-color: $brand-red;
          /* make the decoration a squiggly line */
          text-decoration-thickness: 0.25rem;
          text-underline-offset: 0.3rem;
        }
        .caption {
          width: 100% !important;
          img {
            width: 100%;
          }
        }
        @media screen and (min-width: 768px) {
          padding-right: 30rem;
          > {
            p,
            ul,
            ol,
            li {
              font-size: 2.1rem;
              line-height: 1.6;
            }
          }
        }
        /* Indent content */
        --padding-inline: 0rem; // we're already inside a uk-container, so this doesn't apply.  But it STILL must be specified as a rem or px unit for calculations to work
        --content-max-width: 760px;
        --breakout-max-width: 900px;

        --breakout-size: calc(
          (var(--breakout-max-width) - var(--content-max-width)) / 2
        );

        display: grid;
        grid-template-columns:
          [full-width-start] minmax(var(--padding-inline), 1fr)
          [breakout-start] minmax(0, var(--breakout-size))
          [content-start] min(
            100% - (var(--padding-inline) * 2),
            var(--content-max-width)
          )
          [content-end]
          minmax(0, var(--breakout-size)) [breakout-end]
          minmax(var(--padding-inline), 1fr) [full-width-end];

        > * {
          grid-column: content;
        }
        > p,
        blockquote,
        iframe {
          & > :not(.breakout, .full-width) {
            grid-column: content;
          }
        }

        // make the first image of any blog larger, as a feature
        // the team keeps forgetting to put a caption which normally triggers this
        p:first-child:has(img) {
          grid-column: full-width;
          img {
            grid-column: full-width;
            width: 100%;
          }
        }
        .caption {
          grid-column: full-width;
        }

        // > p,
        // blockquote,
        // iframe {
        //   @media screen and (min-width: 768px) {
        //     font-size: 2.1rem;
        //     line-height: 1.6;
        //     margin-left: 10rem;
        //   }
        // }
        blockquote {
          margin-top: 2rem;
          font-weight: bold;
          font-size: $font-size-h4;
        }
      }
    }
  }
}

.blog-card {
  .uk-card-body {
    .content {
      img {
        aspect-ratio: 16/9;
        object-fit: cover;
      }
    }
  }
  .uk-card-footer {
    h2 {
      font-size: ceil($font-size-base * 1.4);
      font-weight: 900;
      margin-top: 1rem;
      text-align: left;
      padding-top: 5px;
    }
    .field-name-field-tags > .field-items {
      @include generic-tag-container;
      padding-bottom: 1rem;
      .field-item {
        @include generic-tag;
        font-weight: bold;
        font-size: $font-size-h6;
        color: $brand-blue;
      }
    }
  }
}
