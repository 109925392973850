// Typography
// -----------------------------------------------------------------------------

/**
 * Set branding color standards.
 */
.blue {
  color: $brand-blue;
}

.red {
  color: $brand-red;
}

.gray {
  color: $brand-gray !important;
}

/**
 * Set branding colors for headers.
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $brand-blue;
}

/**
 * Set headers slightly smaller on mobile.
 */
@media #{$mediaquery-phone} {
  h1 {
    font-size: $font-size-h1 * $mobile-reduction;
  }
  h2 {
    font-size: $font-size-h2 * $mobile-reduction;
  }
  h3 {
    font-size: $font-size-h3 * $mobile-reduction;
  }
  /**
   * ... but not for the smaller headers, since otherwise they'll
   * end up smaller than p font.
  h4 {
    font-size: $font-size-h4 * $mobile-reduction;
  }
  h5 {
    font-size: $font-size-h5 * $mobile-reduction;
  }
  h6 {
    font-size: $font-size-h6 * $mobile-reduction;
  }
  */
}

/**
 * Set headers and leads slightly smaller on iPad portrait and small desktop.
 */
@media #{$mediaquery-desktop} {
  h1 {
    font-size: $font-size-h1 * $desktop-reduction;
  }
  h2 {
    font-size: $font-size-h2 * $desktop-reduction;
  }
  h3 {
    font-size: $font-size-h3 * $desktop-reduction;
  }
  h4 {
    font-size: $font-size-h4 * $desktop-reduction;
  }
  h5 {
    font-size: $font-size-h5 * $desktop-reduction;
  }
  h6 {
    font-size: $font-size-h6 * $desktop-reduction;
  }
  .lead {
    font-size: $font-size-large;
  }
}

a {
  &:link,
  &:visited,
  &:active,
  &:focus {
    color: $brand-blue;
  }
  &:hover {
    color: $brand-red;
  }
}

a.btn-primary {
  &:link,
  &:visited,
  &:active,
  &:focus {
    color: $brand-white;
  }
}

/**
 * Page title should always be uppercase.
 */
h1.title {
  text-transform: uppercase;
  font-weight: bold;
}

/**
 * Font weight styles.
 */
.thin {
  font-weight: 100;
}
.normal {
  font-weight: normal;
}
.bold {
  font-weight: 500;
}
.xbold {
  font-weight: 700;
}

.upper,
.uppercase {
  text-transform: uppercase;
}

/**
 * Panels pane titles should be uppercased & centered.
 */
// .pane,
// .pane-content,
// .panel-pane {
//   h2 {
//     // text-align: center;
//     // text-transform: uppercase;
//     font-size: $font-size-h2;
//     // padding-top: 3rem;
//     // padding-bottom: 2rem;
//   }
//   h3 {
//     // text-align: center;
//     // text-transform: uppercase;
//     font-size: $font-size-h3;
//     // padding-top: 3rem;
//     // padding-bottom: 2rem;
//   }
// }

@media #{$mediaquery-phone} {
  .pane,
  .pane-content,
  .panel-pane {
    h2 {
      font-size: $font-size-h2 * $mobile-reduction;
    }
  }
}

.inverted-pane {
  a {
    &:link,
    &:visited,
    &:active,
    &:focus {
      color: #ffffff !important;
    }
  }
  /**
   * Fix color on inverted selects.
   */
  select {
    color: $brand-blue !important;
  }
}

.parallax-pane {
  a {
    &:link,
    &:visited,
    &:active,
    &:focus {
      color: #ffffff !important;
    }
  }
  /**
   * Fix color on inverted selects.
   */
  select {
    color: $brand-blue !important;
  }
}

/**
 * Webform labels should be normal weight.
 */
.webform-component label {
  font-weight: normal;
}

/** 
 * Hide permission label.
 */
.webform-component--permission {
  label[for='edit-submitted-permission'] {
    display: none;
  }
}

/**
 * Removes hyperlinks from H1, H2 titles.
 */
h2 > a {
  pointer-events: none;
  cursor: default;
}

/**
 * Undo UIkit's weird color for em styles.
 */
em {
  color: inherit;
}
