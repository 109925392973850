.field-name-field-logos {
  & > ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
    }
    img {
      /* border-radius: 1000px; */
      width: 120px;
      object-fit: contain;
      /* border: 1px solid #f2f2f2; */
      animation: fadeIn 0.7s ease-in-out;
      display: inline-block;
      animation-delay: calc(var(--index) * 0.04s);
      transform-style: preserve-3d;
      transform-origin: center center;
      backface-visibility: hidden;
      animation-fill-mode: forwards;
      opacity: 0;
    }
  }
}

/* p {
  text-align: center;
  font-size: 0.8rem;
  margin: 0;
  display: none;
} */

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: rotateY(-60deg);
  }
  to {
    opacity: 1;
    transform: rotateY(0);
  }
}
