/**
 * Styles for sponsors.
 */
.field-name-field-sponsor-list,
.field-name-field-sponsor-list-fc {
  padding: 2em 0;
  .listing {
    &.sponsor-listing {
      height: auto;
      margin-bottom: 1em;
      .element-invisible {
        display: none !important;
      }
    }
  }
}
.sponsor-description {
  display: none;
  background: $brand-white;
  color: $brand-blue;
  padding: 0 4em 4em;
  position: relative;
  .x-closer {
    position: absolute;
    top: 0;
    right: 0;
    padding: 30px;
  }
}

@media #{$mediaquery-phone} {
  .sponsor-description {
    padding: 0 1em 1em;
    word-wrap: break-word;
    h3 {
      font-size: $font-size-h3;
      text-align: center;
      word-wrap: break-word;
    }
  }
}

img.sponsor {
  margin: 0 auto;
}

.field-name-field-sponsor-group-name {
  .header-line {
    opacity: 0.2;
  }
  .header-wrapper {
    h3 {
      margin: 0;
      padding: 0;
    }
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/**
 * Provides a class to invert a logo to a knockout / white version.
 * Only works with PNG files.
 */
img.inverted,
.inverted img {
  @include inverted-logo;
}
