.btn {
  border-radius: 0;
  padding-left: 3rem;
  padding-right: 3rem;
  text-transform: uppercase;
  transition: all 0.3s;
}

@media screen and (max-width: 639px) {
  .btn {
    margin-top: 10px;
  }
}

@media screen and (min-width: 640px) {
  .btn + .btn {
    margin-left: 10px;
  }
}

.btn-large {
  padding: 10px 4rem;
}

.btn-secondary {
  border: 2px solid $brand-white;
  color: $brand-white;
  &:hover,
  &:focus,
  &:active {
    background-color: $brand-white;
    color: $brand-blue;
  }
}

.cta-fixed-sidebar {
  .btn.btn-outline {
    color: white !important;
  }
}
