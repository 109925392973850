/**
 * Styling for when a journey profile has followups, which means a completely different layout for the article.
 */
$bullet-width-height: 40px;
$border-width: 5px;
$bullet-left: -($bullet-width-height / 2 + ($border-width / 2));
$bullet-top-offset: 2.3rem; // whatever the height of the date is
$article-inset: 4rem;

@mixin bullet {
  content: '';
  width: $bullet-width-height;
  height: $bullet-width-height;
  border-radius: 100rem;
  background-color: $brand-blue;
  position: absolute;
  left: calc(#{($bullet-left)} + #{-$article-inset});
  margin-top: $bullet-top-offset;
  border: $border-width solid #eee;
}
@mixin article-end {
  display: inline-block;
  content: '';
  border-bottom: 1px solid $gray-light;
  width: 5rem;
  height: 1px;
}

.journey-profile-article.has-followups {
  .journey-profile.content {
    /**
     * Followups are in their own field container, separate from the body.
     * Adjustments to make this look consistent.
     */
    .field-collection-container {
      margin-bottom: -2rem;
    }

    padding-top: 0;
    margin-top: 12rem;
    padding-left: $article-inset;
    border-left: $border-width solid #eee;
    position: relative;
    .ordinal {
      position: absolute;
      top: -100px;
    }
    .original-article-content {
      &::before {
        @include bullet;
        /**
         * Followup content structurally has dates and followup headings contained within their field, but body content 
         * is a sibling element to its date and heading. Add additional negative margin to offset the bullet correctly.
         */
        margin-top: -$bullet-top-offset - 7.4rem;
      }
      .field-name-body::after {
        @include article-end;
      }
    }
  }

  .field-collection-item-field-followups .content {
    position: relative;
    .ordinal {
      position: absolute;
      top: -100px;
    }
    &::before {
      @include bullet;
    }
    .field-name-field-content {
      padding-bottom: 5rem;
      &::after {
        @include article-end;
      }
    }
  }

  .date-display-single {
    font-weight: bold;
    color: $brand-gray;
    font-size: 1.6rem;
  }

  .followup-sequence {
    font-weight: bold;
    font-size: 2.8rem;
    color: $brand-blue;
    padding-bottom: 1.2rem;
    border-bottom: $brand-blue $border-width solid;
    margin-bottom: 4rem;
  }

  .field-name-field-date-posted {
    margin-top: -$bullet-top-offset;
  }

  .original-article {
    position: relative;
  }
}
