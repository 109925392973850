.section-divider-icon {
  width: 100%;
  text-align: center;
  position: relative;
  height: 4px;
  margin-top: 37px;
  border-bottom: 4px solid var(--kisaco-section-divider-border-color);

  i {
    /**
     * Default color is kisaco brand color.
     * Should be overridden by page template styling.
     */
    color: $brand-blue;

    /**
     * Assist with making sure the icon doesn't push around the box it's in.
     */
    line-height: 32px;
    font-size: clamp(26px, 28px, 28px);

    margin-top: -37px;

    text-align: center;
    padding: 15px;
    border: 4px solid var(--kisaco-section-divider-border-color);
    border-radius: 1000px;
    width: 70px;
    height: 70px;
    background-color: $brand-white;

    &::before {
      right: calc(50% + (50px / 2));
      width: 100%;
    }
    &::after {
      left: calc(50% + (50px / 2));
      width: 100%;
    }
  }
}
