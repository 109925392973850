/**
 * Styles for attendees section.
 */

:root {
  --audience-background-color: #f6f6f6;
  --audience-accent-color: $kisaco-blue;
}

.field-name-field-attendees {
  .audience-segment-tabs {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%, 12ch), 1fr));
    column-gap: 3rem;
    row-gap: 0;

    // width: auto; // best way to do, but unfortunately creates unsightly gaps when < 5 items
    width: max-content;

    // target mozilla specifically for width, since max-content doesn't seem to work correctly
    @supports (-moz-appearance: none) {
      width: auto;
    }

    max-width: 100%;
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
    .audience-segment-tab {
      padding-bottom: 15px;
      border-bottom: 5px solid transparent;
      position: relative;
      z-index: 2;
      a {
        text-decoration: none !important;
      }
      &:hover {
        border-bottom: 5px solid var(--audience-accent-color);
      }
      &.uk-active {
        // add a white css arrow to the active tab
        &::after {
          content: '';
          width: 0;
          height: 0;
          border-left: 82px solid transparent;
          border-right: 82px solid transparent;
          border-top: (82px - 14px) solid white;
          margin: 0 calc(50% - 82px);
          position: absolute;
          bottom: -85px;
          z-index: 1;
        }
      }

      // not shown to the user. Purely used for grid CSS calculation
      // grid calulcation is done based on max-content within,
      // but max-content is dependent on container query column size -
      // chicken and the egg problem.
      .pseudo-content {
        text-align: center;
        visibility: hidden;
        opacity: 0;
        line-height: 1px !important;
        font-size: 16px !important;
      }
      .chart {
        container-type: inline-size;
        color: var(--audience-accent-color);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20%;
        max-width: 250px;
        max-height: 250px;
        aspect-ratio: 1/1;
        .percentage {
          position: static;
          line-height: 1.6rem !important;
          width: auto !important;
          height: auto !important;
          span {
            font-size: clamp($font-size-h3, $font-size-h1, 29cqw);
          }
        }
        .chart-label {
          margin-bottom: clamp(11cqw, 0.6rem, 2rem);
          font-size: clamp(9px, 16px, 12cqw);
          line-height: clamp(0.9rem, 12cqw, 1.7rem);
          // margin-bottom: clamp(0.8rem, 2vw, 2rem);
          // font-size: clamp(10px, 2vw, 16px);
          // line-height: clamp(1.2rem, 2vw, 1.7rem);
          color: var(--kisaco-primary-color);
        }
        canvas {
          position: absolute;
          width: 100% !important;
          height: auto !important;
          aspect-ratio: 1/1;
          left: 0;
          z-index: -1;
        }
      }
    }
  }
}

.audience-segments-wrapper {
  margin-top: 3rem;
  background-color: var(--audience-background-color);
  padding: 6rem 0;
  position: relative;

  hr {
    border-top-color: $gray;
  }

  .description-with-stats {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    @media screen and (width > 768px) {
      flex-direction: row;
    }
  }

  .logo-cloud-with-sidebar {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 2rem;
    @media screen and (width > 768px) {
      flex-direction: row;
    }
    & > :first-child {
      @media screen and (width > 768px) {
        border-right: 1px solid $gray-light;
      }
    }
    & > :nth-child(2) {
      @media screen and (width > 768px) {
        padding-left: 3rem;
      }
      flex: 1 0 320px;
    }
  }

  // D7 fields.
  .field-name-field {
    &-description {
      font-weight: 700;
      font-size: $font-size-h2;
      color: var(--kisaco-primary-color) !important;
      line-height: 3.8rem;
    }
    &-stat {
      font-size: 86px;
      & * {
        color: var(--audience-accent-color) !important;
      }
      font-weight: 700;
    }
    &-key-statistic {
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        @media screen and (width > 768px) {
          border-left: 1px solid $gray;
          padding-left: 2rem;
        }
        .field-name-field {
          &-icon {
            display: none;
            & * {
              color: var(--audience-accent-color) !important;
            }
          }
          &-stat {
            font-size: clamp(20px, 12vw, 40px);
            & * {
              color: var(--audience-accent-color) !important;
            }
            font-weight: 700;
          }
          &-description {
            font-weight: 700;
            font-size: $font-size-h4;
            * {
              color: var(--audience-accent-color) !important;
            }
            line-height: 1.8rem;
          }
        }
      }
    }
    &-logo-cloud {
      h2 {
        font-weight: 700;
        font-size: $font-size-h3;
        line-height: $font-size-h3;
        text-align: left;
        color: var(--kisaco-primary-color);
        padding-top: 0;
        margin-top: 0;
        @media screen and (width < 767px) {
          text-align: center;
        }
      }
      .field-name-field-logos {
        ul {
          @media screen and (width > 768px) {
            justify-content: flex-start;
          }
        }
      }
    }
    &-testimonials-heading {
      font-weight: 700;
      font-size: $font-size-h3;
      line-height: $font-size-h3;
      text-align: left;
      color: var(--kisaco-primary-color);
      padding-top: 0;
      margin-top: 0;
      @media screen and (width < 767px) {
        text-align: center;
      }
    }
    &-testimonials {
      .node-testimonial {
        margin: 0;
        .field-name-body {
          font-weight: bold;
        }
      }
      .node-video {
        h2 {
          ::before {
            content: 'Watch video of ';
          }
          text-align: left;
          font-size: $font-size-h5;
          font-weight: bold;
          padding-top: 3rem;
          padding-bottom: 0;
        }
      }
    }
    &-charts {
      h2 {
        font-weight: 700;
        font-size: $font-size-h3;
        line-height: $font-size-h3;
        padding-bottom: 4rem;
        @media screen and (width > 768px) {
          display: flex;
          line-height: 1px;
          gap: 2rem;
          &::after {
            content: '';
            border-bottom: 1px solid var(--kisaco-primary-color);
            flex: 1;
            margin-left: 1rem;
            align-items: center;
          }
        }
      }
    }
  }

  .arrow-down {
    border-top-color: #ffffff !important;
  }
  .attendee-description:not(:first-child) {
    display: none;
  }
  .attendee-description {
    &.no-content {
      display: none !important;
    }
    .attendee-description-inner {
      padding: 0 4em 4em;
      h3 {
        font-size: 32px;
      }
      .attendee-content * {
        color: #58595b !important;
      }
    }
  }
  .x-closer {
    position: absolute;
    top: 0;
    right: 0;
    padding: 30px;
    .fa {
      color: #cccccc !important;
    }
  }
}

.logo-cloud {
  img {
    width: 114px;
    margin-right: 30px;
    margin-bottom: 15px;
  }
}

@media #{$mediaquery-phone} {
  .logo-cloud {
    img {
      width: 80px;
      margin-bottom: 15px;
      margin-right: 0;
      &:not(:nth-child(3n)) {
        margin-right: 32px;
      }
    }
  }
}

@media #{$mediaquery-tablet} {
  .logo-cloud {
    img {
      width: 95px;
      margin-bottom: 15px;
      margin-right: 0;
      &:not(:nth-child(5n)) {
        margin-right: 32px;
      }
    }
  }
}

@media #{$mediaquery-desktop} {
  .logo-cloud {
    img {
      width: 80px;
      margin-bottom: 15px;
      margin-right: 0;
      &:not(:nth-child(8n)) {
        margin-right: 29px;
      }
    }
  }
}

// hook to allow the team to hide the key statistic
.hide-key-stats .field-name-field-key-statistic {
  display: none;
}
