// Styles for panels and panes.
// -----------------------------------------------------------------------------

/**
 * Styles for all panes.
 * See _typography.scss for base styles.
 */
.pane {
  /**
   * H1 - H6 usually have margin at the top, remove it for these
   * in favor of padding instead to prevent gap between panes.
   */
  h1,
  h2,
  h3 {
    // margin-top: 0 !important;
    // padding-top: 60px;
  }
  // h3:not(.uk-heading-line) {
  //   display: inline-block;
  // }
  // padding-bottom: 30px;
}

.panel-pane {
  .pane-content {
    /**
     * Try to add padding below all pane-content panes.
     */
    padding-bottom: 30px;
  }
}

/**
 * Proper padding for the end of any panel.
 */
.node-webform {
  padding-bottom: 30px;
}

/**
 * Styles for Kisaco basic panes.
 */
.basic-pane {
  background-color: $brand-white;
  .diamond-line {
    border-bottom-color: $brand-blue;
  }
  .arrow-down {
    border-top-color: $brand-blue;
  }
}

/**
 * Styles for Kisaco inverted panes.
 */
.inverted-pane {
  background-color: $brand-blue;
  .diamond-line {
    border-bottom-color: $brand-white;
  }
  .arrow-down {
    border-top-color: $brand-white;
  }
  .header-line {
    background-color: $brand-white !important;
  }
  * {
    color: #ffffff;
    .form-control,
    div.alert li {
      color: $brand-blue;
    }
  }
}

/**
 * Styles for Kisaco light panes.
 */
.light-pane {
  background-color: $gray-light;
  .diamond-line {
    border-bottom-color: $brand-blue;
  }
  .arrow-down {
    border-top-color: $brand-blue;
  }
}

/**
 * Styles for Kisaco parallax panes.
 */
.parallax-pane {
  background-color: $brand-blue;
  .diamond-line {
    border-bottom-color: $brand-white;
  }
  .arrow-down {
    border-top-color: $brand-white;
  }
  .header-line {
    background-color: $brand-white !important;
  }
  *,
  .brand-event-primary-content {
    color: #ffffff;
    .form-control,
    div.alert li {
      color: $brand-blue;
    }
  }
}
