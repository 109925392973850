.field-name-field-topics {
  &.tongue-grid {
    text-align: center;
    .uk-card-body {
      /**
       * for the slide up to work, padding must be removed and applied to the inner div.
       */
      padding: 0;
    }

    h4 {
      font-weight: bold;
      color: $brand-blue;
      font-size: 18px;
    }

    .tongue-wrapper {
      .circle-icon {
        border-radius: 1000px;
        background: $brand-blue;
        padding: 15px;
        position: absolute;
        left: calc(50% - 24px);
        margin-top: -43px;
        width: 50px;
        height: 50px;
        color: $brand-white;

        i {
          font-size: clamp(20px, 21px, 21px);
          text-align: center;
        }
      }

      padding: 15px;
      margin-bottom: 30px;
      overflow: hidden;
      height: 120px;
      background-color: white;
      transition: all 0.4s;
      .tongue {
        transition: all 0.2s;
        opacity: 0;
      }
      .description {
        transition: all 0.2s;
      }
      &:hover {
        cursor: pointer;
        height: 220px;
        margin-top: -100px;
        .tongue {
          opacity: 1;
          z-index: 1000;
        }
        .description {
          height: 0;
          opacity: 0;
        }
      }
      &.has-no-links {
        &:hover {
          cursor: default;
          .tongue {
            opacity: 0;
            z-index: 1000;
          }
          .description {
            height: auto;
            opacity: 1;
          }
        }
      }
      ul {
        // margin-top: -15px;
        padding-top: 0;
        text-align: left;
        li {
          width: 100%;
          padding: 5px 8px;
          position: relative;
          &::after {
            position: absolute;
            right: 0;
            top: 0;
            text-align: right;
            font-family: fontawesome;
            content: '';
            color: $brand-blue;
            padding: 5px 8px;
            pointer-events: none;
          }
          a {
            display: block;
            width: 100%;
            &:hover {
              text-decoration: none;
              color: $brand-blue;
            }
          }
          &:hover {
            background-color: $gray-light;
          }
        }
      }
    }
    .uk-card-body {
      /**
       * The entire card body might have a link - use a pointer cursor.
       */
      > a {
        .tongue-wrapper {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    &.uk-child-width-1-4\@m {
      .tongue-wrapper {
        height: 130px;
        &:hover {
          height: 220px;
        }
      }
    }
  }
}
