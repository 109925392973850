/**
 * Overrides for bootstrap carousel.
 */

/**
 * Remove the fader to the left and right of the carousel.
 */
.carousel-control {
  &.left,
  &.right {
    background-image: none;
    text-shadow: none;
    color: $brand-gray;
    /**
     * Strange bug required me to remove href attribute on controls and 
     * use data-target instead (was causing id of carousel to be removed
     * whenever clicking on a next / prev button)
     */
    cursor: pointer;
  }
  .icon-prev,
  .icon-next {
    &:before {
      content: "";
    }
    margin-top: -60px;
  }
}

/**
 * In the case of testimonials, we never want the border at the bottom.
 */
.carousel-inner {
  .node-testimonial {
    border-bottom: none;
  }
}

/**
 * In the case of a non-inverted pane, show the main color.
 */
.basic-pane,
.light-pane {
  .carousel-indicators {
    bottom: 0;
    li {
      border-color: $brand-blue;
    }
    .active {
      background-color: $brand-blue;
    }
  }
}

/**
 * Footer button styling.
 */
.view-testimonials {
  .view-footer {
    padding-top: 30px; 
  }
}