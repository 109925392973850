// Styles for countdownclock.
// -----------------------------------------------------------------------------

.countdownclock_wrapper {
  text-align: center;
  padding-bottom: 2rem;

  h2 {
    padding-top: 1.5rem !important;
  }

  .countdown-epoch {
    @media screen and (min-width: 768px) {
      font-size: 5rem;
      font-weight: 700;
      padding-top: 0;
      margin-top: 0;
    }
  }

  .countdownclock {
    padding-top: 1rem;
    line-height: 5rem;
    &.inverted {
      color: #fff;
    }
    display: inline-block;
    white-space: nowrap;
    font-weight: bold;
    text-align: center;
    font-size: 8rem;
    @media screen and (max-width: 767px) {
      font-size: 12vw;
      line-height: 3rem;
      padding-top: 0;
    }
    > div {
      border-radius: 3px;
      display: inline-block;
      &:not(:last-child) span::after {
        padding: 0 2rem 0 1.4rem;
        @media screen and (max-width: 767px) {
          padding: 0 0.5rem 0 0.4rem;
        }
        content: ':';
        //font-family: serif;
        line-height: 0;
      }
    }

    div > span {
      border-radius: 3px;
      display: inline-block;
    }

    .smalltext {
      padding-top: 5px;
      font-size: 2.1rem;
      @media screen and (max-width: 400px) {
        padding-top: 0;
        font-size: 3.5vw;
      }
      font-weight: normal;
      color: #aaa;
      text-transform: lowercase;
    }

    > div:not(:last-child) .smalltext {
      padding-right: 40px;
      @media screen and (max-width: 767px) {
        padding-right: 20px;
      }
    }
  }
}
