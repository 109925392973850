/**
 * Styles for leadership.
 * Styles for bootstrap adjusted 5ths column so center column is slightly wider.
 */
.col-xs-17,
.col-sm-17,
.col-md-17,
.col-lg-17 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-17 {
  width: 17.5%;
  float: left;
}

@media (min-width: 768px) {
.col-sm-17 {
  width: 17.5%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-17 {
    width: 17.5%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-17 {
    width: 17.5%;
    float: left;
  }
}

.col-xs-29,
.col-sm-29,
.col-md-29,
.col-lg-29 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-29 {
  width: 35%;
  float: left;
}

@media (min-width: 768px) {
.col-sm-29 {
  width: 35%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-29 {
    width: 35%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-29 {
    width: 35%;
    float: left;
  }
}

.col-xs-25,
.col-sm-25,
.col-md-25,
.col-lg-25 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-25 {
  width: 30%;
  float: left;
}

@media (min-width: 768px) {
.col-sm-25 {
  width: 30%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-25 {
    width: 30%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-25 {
    width: 30%;
    float: left;
  }
}

/**
 * Begin actual styles.
 */
.field-name-field-leadership,
.fieldable-panels-pane-advisory-board-list {
  ul.leadership-tabs {
    padding-top: 60px;
    li {
      a {
        font-size: $font-size-h3;
        font-weight: bold;
        text-transform: uppercase;
        color: $gray-light;
        &:hover {
          color: $gray;
          background: $brand-white;
        }
      }
      &.active {
        border-bottom: 5px solid $brand-blue;
        a {
          background: $brand-white;
          color: $brand-blue;
        }
      }
    }
  }
  .has-tabs {
    .field-name-field-group-name {
      /**
       * We can hide this completely, since this is provided by the tabs
       * themselves.
       * But we don't want to hide this through Drupal's Manage Display
       * properties since we might need it later.
       */
      display: none;
    }
  }
  .field-collection-item-field-leadership {
    .leadership-pane {
      border-top: 1px solid $brand-blue;
      padding-top: 10px;
    }
    .leadership-description-wrapper {
      background: $brand-blue;
      color: $brand-white;
    }
    .leadership-description {
      padding: 0 15px 20px;
    }
    .arrow-down {
      border-top-color: $brand-white;
    }
    .node-speaker {
      margin-bottom: 15px;
      .field-name-field-featured-image {
        margin-top: 15px;
        img {
          @extend .img-circle;
        }
      }
      h4 {
        font-size: $font-size-h5;
        margin-bottom: 0.2em;
        font-weight: bold;
      }
    }
    .leadership-item {
      img.leadership-company-logo {
        float: none !important;
        width: 100%;
        height: 40px;
        margin-left: 0 !important;
        object-fit: contain;
        object-position: left;
        // float: right;
        margin-top: 16px;
        // margin-right: 5px;
        // max-height: 50px;
        // max-width: 8.5vw;
        @media screen and (max-width: 991px) {
          max-width: 100px;
        }
        @media screen and (min-width: 1200px) {}
        margin-left: 15px;
      }
    }
  }
}

@media #{$mediaquery-desktop}, #{$mediaquery-large-desktop} {
  .leadership-description-wrapper {
    border-left: 15px solid $brand-white;
    border-right: 15px solid $brand-white;
  }
  .field-name-field-leadership {
    padding: 0 60px;
    ul.leadership-tabs {
      li:last-child {
        a {
          text-align: right;
        }
      }
      li:first-child {
        a {
          text-align: left;
        }
      }
    }
  }
}

@media #{$mediaquery-phone}, #{$mediaquery-tablet} {
  .leadership-description-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .leadership-description {
    padding: 0 30px 30px;
  }
}