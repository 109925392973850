/**
 * Styles for toaster popup for related events content.
 */

.toaster {
  .item {
    float: left;
  }
  @include transition(margin 2s);
  &.toaster-showing {
    margin-bottom: 0;
  }
  margin-bottom: -1000px;
  &.toaster-dismissed {
    margin-bottom: -1000px !important;
  }
  position: fixed;
  bottom: 0;
  width: 275px;
  right: 0;
  padding: 50px;
  padding-bottom: 20px;
  padding-top: 0;
  .img-wrapper {
    position: relative;
  }
  .img-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  img {
    width: 170px;
    border-radius: 3px;
  }
  h2 {
    color: $brand-white;
    font-size: 16px;
    text-shadow: 3px 3px 16px rgba(0,0,0,0.8);
  }
  h3 {
    color: $brand-white;
    font-size: 13px;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 12px;
    text-shadow: 3px 3px 6px rgba(0,0,0,0.8);
  }
  .thumbnail {
    border-radius: 6px;
    position: relative;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
    margin-bottom: 15px;
  }
  .close-button {
    width: 24px;
    height: 24px;
    background-color: #000000;
    display: block;
    position: absolute;
    top: -5px;
    left: 48px;
    border-radius: 50px;
    border: solid 2px #ffffff;
    color: #ffffff !important;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.6);
    padding: 4px;
    @media screen and (max-width: 767px) {
      left: 28px;
      top: 18px;
    }
  }
  
  @media screen and (max-width: 767px) {
    padding: 30px;
    padding-bottom: 0;
    width: 100%;
    max-height: 400px !important;
    img {
      width: 100px;
    }
    h3 {
      font-size: 12px;
    }
    .thumbnail {
      margin-right: 20px;
    }
  }
  
}