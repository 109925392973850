/**
 * Overrides for portfolio pages.
 */

.page-taxonomy-term:not(.page-taxonomy-term-edit),
.page-categories,
.panopoly-landing-page {

  .portfolio-logo-inline {
    @media screen and (min-width: 768px) {
      margin-left: -10%;
    }
  }

  .portfolio-description-wrapper {
    z-index: 2;
    @media screen and (max-width: 767px) {
      // prevent logo collision with kisaco main logo
      top: 7rem;
    }
    @media (min-width: 640px) {
      max-width: calc(100% - (80px * 2));
      margin: 80px;
    }
    .portfolio-logo {
      img {
        @include atf-logo-size;
      }
    }
    @media #{$mediaquery-edge} {
      .portfolio-title h1 {
        font-size: 3.2rem;
      }
    }
    .portfolio-description {
      @media screen and (min-width: 1200px) {
        font-size: $font-size-large;
        line-height: 3.2rem;
        max-width: clamp(900px, 50%, 1200px);
      }
      /**
       * Special case for Windows at 150% view (small browser)
       */
      @media #{$mediaquery-edge} {
        font-size: $font-size-small;
        line-height: 2.3rem;
      }
    }
    .portfolio-navigation {
      padding-top: 20px;
      @media screen and (max-width: 767px) {
        a.btn.btn-large {
          &.btn-default,
          &.btn-primary {
            display: block;
            width: 100%;
            white-space: normal !important;
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
      }
        
    }
    .btn-circle {
      .fa-cubes {
        /**
         * Slightly off alignment so adjust.
         */
        @media screen and (min-width: 767px) {
          margin-left: -3px;
          margin-top: -3px;
        }
      }
    }
  }

  .btn {
    &.btn-circle {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.7) 100%);
    }
  }
  .portfolio-cover-content {
    @media screen and (max-width: 767px) {
      text-align: center;
    }
    text-align: left;
    padding-top: 120px;
    .portfolio-icon {
      padding-top: calc(100vh - 828px);
      .fa {
        color: $brand-white;
        font-size: 9em;
      }
    }
    .portfolio-title {
      text-transform: uppercase;
      * {
        color: $brand-white;
      }
      h1 {
        font-weight: 800;
      }
    }
    .portfolio-description, .portfolio-description * {
      color: $brand-white;
    }
    .item {
      text-align: center;
      .fa.fa-handshake-o {
        margin-left: -8px;
      }
      .cta {
        font-weight: bold;
        color: $brand-white;
        text-transform: uppercase;
        text-align: center;
      }
    }
    .menu-block-wrapper {
      ul.menu {
        margin-top: 15px;
        @media screen and (min-width: 768px) {
          display: flex;
          align-items: middle;
          justify-content: flex-start;
          li {
            margin-top: 0;
          }
        }
        li {
          margin-top: 15px;
          a {
            background: none;
            color: $brand-white;
            text-transform: uppercase;
            border: 2px solid $brand-white;
            border-radius: 0;
            margin-right: 10px;
            padding: 5px 2rem;
            text-align: center;
            transition: all 0.3s;
            @media screen and (min-width: 768px) {
              white-space: nowrap;
            }
            &:hover, &:active, &:focus {
              background: $brand-white;
              color: $brand-blue;
            }
          }
        }
      }
    }
  }
  .events-listings-filters {
    display: none;
  }

  .uk-card {
    h4, h5 {
      a:hover {
        color: initial;
        text-decoration: none;
      }
    }
  }
}

.page-taxonomy-term:not(.page-taxonomy-term-edit) {
  #main-wrapper {
    padding-top: 15px;
  }
}

@media #{$mediaquery-phone} {
  .page-taxonomy-term {
    .navbar {
      margin-bottom: 0;
    }
    .portfolio-cover-content {
      padding-top: 25px;
      .topics {
        display: none;
      }
    }
    .topic-icon-button {
      width: 100%;
      max-width: 100%;
      text-align: left;
      min-height: 50px !important;
      max-height: 30px !important;
      padding-top: 12px;
      .views-field-name {
        div.small {
          div.small {
            .field-content {
              font-size: 18px !important;
            }
          }
        }
      }
      .fa {
        float: left;
        font-size: 30px;
        width: 50px;
        padding-right: 20px;
      }
    }
    .btn {
      &.btn-circle {
        border-radius: 5px !important;
        padding: 10px 15px;
        height: auto;
        width: 95%;
        text-align: left;
        margin-bottom: 20px;
        text-transform: uppercase;
        font-size: 1.2em;
        .fa {
          font-size: 1.2em;
        }
      }
    }
    .cta {
      display: none;
    }
    
  }
}

@media #{$mediaquery-tablet} {
  .page-taxonomy-term {
    .portfolio-cover-content {
      .portfolio-icon {
        padding-top: 20px;
      }
    }
  }
}

@media #{$mediaquery-desktop} {
  .page-taxonomy-term {
    .btn {
      &.btn-circle {
        width: 150px;
        height: 150px;
        padding-top: 40px;
        padding-left: 38px;
        .fa.fa-check-circle {
          padding-left: 6px;
        }
      }
    }
  }
}

@media #{$mediaquery-large-desktop} {
  .page-taxonomy-term {
    .ctas {
      padding-top: 60px;
    }
  }
}

.card-height-large-adjust {
  height: 400px;
}

.articles .summary {
  display: none;
}

.pane-kisaco-global-kisaco-global-our-network {
  h2 {
    padding-top: 0;
  }
}

/**
 * Add an arrow for the kisaco portfolio home dropdown
 * so it's clearer that the menu items that follow are 
 * children of that item.
 */
li.kisaco-portfolio-home-link {

  @media screen and (min-width: 768px) {
    > a::after {
      content: "";
      display: inline-block;
      border-right: 1px solid rgba($brand-blue, 0.5);
      border-top: 1px solid rgba($brand-blue, 0.5);
      width: 20px;
      height: 20px;
      transform: translateY(1px) rotate(45deg);
      margin-left: 22px;
      margin-bottom: -5px;

      @media #{$mediaquery-edge} {
        width: 14px;
        height: 14px;
        margin-bottom: -2px;
      }

      /**
      * If the containing navbar is transparent,
      * make the arrow white instead.
      */
      .header:not(.header-scrolled) .navbar-transparent & {
        border-right-color: rgba($brand-white, 0.5);
        border-top-color: rgba($brand-white, 0.5);
      }

    }

  }

}