/**
 * Style meet the team section.
 */
.view-meet-the-team {
  .node-speaker {
    // display: flex;

    @media screen and (max-width: 767px) {
      min-height: 150px;
    }

    .field-name-field-featured-image {
      width: 130px;
      float: left;
      margin-right: 15px;
    }
    h4 {
      padding-top: 15px;
      margin-bottom: 2px;
      font-weight: bold;
      font-size: 21px;
    }
    .field-name-field-job-title {
      color: $gray;
    }
    .panopoly-image-square {
      width: 130px;
      border-radius: 1000px;
    }
  }
}
