/**
 * Styles for full agenda FPP.
 */
.node-type-agenda,
.fieldable-panels-pane-agenda:not(.agenda-teaser-mode) {
  --base-color: #{$brand-blue};

  .filterset {
    margin-bottom: 30px;

    button {
      @include filterset-button;
    }

    input {
      font-weight: bold;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 16px;
    }

    a.uk-button {
      text-transform: initial;
      font-size: 14px;
      padding: 2px 0.8rem;
      line-height: 17px;
      border: 1px solid var(--tag-color);
      color: var(--tag-color);
      &.module-job-focus {
        background-color: var(--tag-color);
        color: $brand-white;
      }
    }
  }

  .filter-selections {
    .uk-text-meta {
      font-size: 14px;
      color: var(--base-color);
      font-weight: bold;
    }
    .uk-close {
      width: 10px;
      height: 10px;
    }
    .event-filter-link {
      padding: 2px 0.8rem;
      line-height: 15px;
      font-size: 12px;
      border: 1px solid var(--base-color);
    }
    .uk-list-inline {
      margin-right: 20px;
    }
  }

  // Agenda day tabs

  // Quick navigation at bottom

  .field-name-field-day .field-item {
    font-weight: bold;
    color: var(--base-color);
    font-size: 32px;
    border-bottom: 1px solid var(--base-color);
    display: block;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }

  .header-line {
    display: none;
  }

  /**
   * An agenda timeslot row...
   */
  .field-name-field-agenda-time .field-items .field-item {
    margin-bottom: 0;
    .field-collection-item-field-agenda-time {
      .field-name-field-time {
        padding: 15px;
      }
      .field-name-field-description,
      .field-name-field-agenda-sessions {
        padding: 15px;
      }
      & > .content {
        border-bottom: 1px solid lighten($gray, 90);
      }

      & > .content {
        /**
          * Help prevent visible overlap when using uk-filter.
          */
        background: #f2f2f2;

        display: grid;
        position: relative;
        grid-template-columns: [time] 1fr [details] 4fr;
        grid-column-gap: 0;
        @media screen and (min-width: 768px) and (max-width: 991px) {
          grid-template-columns: [time] 1fr [details] 3fr;
        }
        @media screen and (max-width: 767px) {
          display: block;
        }
        grid-template-rows: auto;

        .field-name-field-time {
          grid-column: time;
          font-weight: bold;
          color: var(--base-color);
          font-size: 85%;
        }

        .field-name-field-description {
          grid-column: details;
          font-weight: bold;
          color: var(--base-color);
          border-left: 1px solid white;
          & + .field-name-field-agenda-sessions {
            padding-top: 15px;
          }
        }

        .field-name-field-session-type {
          grid-column: details;
          border-left: 1px solid white;
        }

        .field-name-field-agenda-sessions {
          grid-column: details;
          border-left: 1px solid white;
        }

        .field-name-field-agenda-featured-image,
        a.featured-image-url {
          grid-column: 1 / 3;
          & > div {
            margin-top: 20px;
          }
        }

        .field-name-field-speakers {
          padding-bottom: 20px;
          .field-items {
            display: flex;
            gap: 15px;
            flex-wrap: wrap;
            font-size: 85%;
            .speaker-meta {
              width: 300px;
              display: flex;
              gap: 15px;
              align-items: flex-start;
              .speaker-headshot {
                min-width: 60px;
              }
              h4 {
                font-size: 85%;
                margin: 0;
              }
            }
          }
        }

        .field-name-field-sponsor-list {
          padding: 0;
          > .field-items {
            &::before {
              content: 'Sponsored by:';
              font-weight: bold;
              padding-left: 15px;
            }
            pointer-events: none;
            .col-xs-6 {
              width: auto;
            }
            img.img-responsive {
              max-height: 90px;
              width: auto !important;
              object-fit: contain;
            }
          }
        }

        .node-agenda-session {
          /**
            * This is a teaser but we don't need a read more link.
            */
          .links.inline {
            display: none;
          }

          h2 {
            font-size: 18px;
            text-transform: initial;
            text-align: left;
          }

          /**
            * Description is hidden by default.
            */
          .field-name-body {
            max-height: 0;
            overflow: hidden;
            transition: all 0.3s;
          }

          /**
            * Pseudo element to activate the accordion container.
            */
          input {
            position: absolute;
            opacity: 0;
            z-index: -1;
          }

          label {
            cursor: pointer;
            color: var(--base-color);
            display: flex;
            justify-content: space-between;
            &.has-session-description {
              &::after {
                content: '\F105';
                font-family: 'FontAwesome';
                font-size: 20px;
                width: 10px;
                height: 20px;
                line-height: 18px;
                text-align: center;
                transition: all 0.4s;
                transform-origin: center;
                margin-left: 30px;
                margin-right: 4px;
              }
            }
          }

          /**
            * Accordion is open.
            */
          input:checked {
            & + label {
              &::after {
                transform: rotate(90deg);
              }
            }
            & ~ .content .field-name-body {
              transition: all 0.8s ease-in;
              max-height: 100vh;
            }
          }

          .field-name-body {
            font-size: 16px;
            .field-item {
              padding: 0 0 15px 0;
            }
          }

          /**
            * Topics / tags.
            */
          .field-name-field-session-topics,
          .field-name-field-session-job-focus {
            display: inline-flex;
            flex-wrap: wrap;
            .field-items {
              display: inline-flex;
              flex-wrap: wrap;
              gap: 5px;
              .field-item {
                border: 1px solid var(--tag-color);
                background-color: transparent;
                color: var(--tag-color);
                font-size: 12px;
                padding: 1px 0.8rem;
                display: inline;
              }
            }
          }

          .field-name-field-session-job-focus {
            .field-items {
              .field-item {
                border: 1px solid var(--tag-color);
                background-color: var(--tag-color);
                color: $brand-white;
                font-size: 12px;
                padding: 1px 0.8rem;
                display: inline;
              }
            }
          }

          .field-name-field-session-topics
            + .field-name-field-session-job-focus {
            margin-left: 5px;
          }

          /**
            * The header that appears above speakers (moderator, panelist, etc)
            */
          .field-name-field-speaker-type {
            padding-top: 20px;

            .field-items .field-item {
              font-weight: bold;
              &::after {
                content: ':';
              }
            }

            /**
              * If we have a header (moderator, panelist, etc),
              * reduce the padding between it and the set of 
              * speakers below it.
              */
            & + .field-name-field-speakers {
              .field-items {
                padding-top: 5px !important;
              }
            }
          }

          /**
            * The speakers.
            */
          .field-name-field-speakers {
            .speaker-headshot {
              img {
                width: 60px;
                height: 60px;
                object-fit: contain;
                border-radius: 1000px;
                border: 1px solid var(--base-color);
              }
            }
          }
        }
      }

      /**
         * If we have an image but no time or description, remove the padding above the image.
         */
      &.has-image-only {
        padding: 0;
        margin: 0;
        & > .content {
          padding: 0;
          margin: 0;
          .field-name-field-agenda-featured-image > div {
            margin-top: 0 !important;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }

  /**
   * Support for track side-by-side styling.
   */
  @media screen and (min-width: 992px) {
    .field-name-field-agenda-sessions > .field-items {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
      gap: 60px;
    }
  }

  .cta-fixed-sidebar {
    margin-top: 13.5rem;
    background-color: var(--base-color);
    .btn {
      max-width: 100%;
      padding: 1.5rem 3rem !important;
    }
  }
}

/**
 * Styles for agenda download pane.
 */
.pane-content-agenda {
  margin-top: 30px;
  border-top: 1px solid $gray-light;
  .field-name-field-day {
    text-transform: uppercase;
    font-size: $font-size-h3;
    padding-top: 20px;
  }
  .field-name-field-time {
    margin-top: 1em;
    font-weight: 500;
  }
  .agenda-content {
    padding: 60px;
    padding-right: 120px;
    position: relative;
    .arrow-left {
      border-right-color: $gray-light;
      position: absolute;
      right: 0;
      top: 0;
      margin-top: 5em;
    }
  }
  .agenda-webform {
    padding: 90px;
    background-color: $gray-light;
    text-align: left;
    label {
      color: $gray-dark;
      font-size: $font-size-small;
      margin-bottom: 0;
    }
    .form-item {
      margin-bottom: 0.8em;
    }
    h3 {
      padding-top: 0;
      text-transform: none;
      text-align: left;
    }
  }
}

@media #{$mediaquery-phone} {
  .pane-content-agenda {
    .agenda-content {
      padding: 30px;
    }
    .agenda-webform {
      padding: 30px;
    }
    .arrow-left {
      display: none;
    }
    // ensure images never exceed 100% width
    img {
      max-width: 100% !important;
      height: auto !important;
    }
  }
}

@media #{$mediaquery-tablet} {
  .pane-content-agenda {
    .arrow-left {
      display: none;
    }
  }
}

#agenda {
  padding-bottom: 0;
}

.node-type-event {
  .agenda-actions {
    display: none;
  }
  .field-name-field-agenda-days {
    .date-display-single {
      font-size: 3rem;
      font-weight: normal;
    }
  }
}

/**
 * Adjustments for Teaser Alternate, which appears on events home pages.
 * "event-agenda-time" only occurs on the event home page, not agenda pages.
 */
.fieldable-panels-pane-agenda.agenda-teaser-mode {
  .filterset,
  .agenda-actions {
    display: none;
  }
}
.event-agenda-time {
  article.node-agenda-session {
    padding-top: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #f2f2f2;
  }
  .field-name-field-description {
    font-weight: bold;
    margin-bottom: 1.5rem;
    font-size: 1.4rem;
  }
  .field-name-field-speakers-grouped {
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media screen and (min-width: 768px) {
      float: right;
    }
  }
  .field-name-field-speakers {
    & > .field-items {
      display: flex;
      padding-top: 0 !important;
      .speaker-info {
        display: none;
      }
      .speaker-headshot {
        margin-right: -5px;
        img {
          width: 50px;
          height: 50px;
          object-fit: contain;
          border-radius: 1000px;
        }
      }
    }
  }
  .node-teaser-alternate {
    .content {
      display: flex;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
      .field-collection-container {
        flex: 1 0 auto;
        padding-left: 4rem;
        padding-top: 1rem;
        @media screen and (max-width: 767px) {
          padding: 1rem;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .field-name-field-speakers-grouped {
      .field-items {
        display: flex;
        .field-name-field-speaker-type {
          display: none;
        }
      }
    }
  }
}

body.is-admin {
  &.node-type-agenda {
    .field-collection-view-links {
      border: 2px solid $brand-warning;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 10px;
      border-top: 10px solid $brand-warning;
      width: 100%;
      margin-top: 10px;
    }
    .action-links {
      border: 2px solid $brand-success;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 10px;
      border-top: 10px solid $brand-success;
      width: 100%;
      margin-top: 10px;
    }
    .field-name-field-agenda-time {
      & > .field-items > .field-item {
        border: 2px solid $brand-primary;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 10px;
        border-top: 20px solid $brand-primary;
      }
    }
    .field-name-field-agenda-sessions {
      & > .field-items > .field-item {
        border: 2px dotted $brand-primary;
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 10px;
        border-top: 20px solid $brand-primary;
      }
    }
  }
}
