/**
 * Styles for documents.
 */
.field-name-field-resources {
  .document-listing {
    margin-bottom: 2em;
  }
}
#document-webform-wrapper {
  display: none;
}