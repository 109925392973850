.kisaco-main-feature {
  margin-top: 5rem;
  margin-bottom: 5rem;
  .uk-card-title {
    font-size: 2.8rem;
    line-height: 3.2rem;
    color: $brand-blue;
  }
  .uk-card-footer {
    border-top: none;
    text-align: right;
    .uk-button {
      font-size: 1.5rem;
    }
  }
}
.kisaco-secondary-features {
  margin-top: 3rem;
  margin-bottom: 3rem;
  h5 {
    line-height: 2.2rem;
  }
  .uk-card-footer {
    border-top: none;
    text-align: right;
    .uk-button {
      font-size: 1.5rem;
    }
  }
}
.kisaco-four-sliders {
  h5 {
    line-height: 2rem;
  }
  .uk-card-footer {
    border-top: none;
    text-align: right;
    .uk-button {
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-width: 767px) {
  .card-footer {
    position: static !important;
  }
}
