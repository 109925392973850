// Styles for Views
// -----------------------------------------------------------------------------

/**
 * Overrides for default panopoly general content lists.
 */

.view-display-id-list_of_content {
  .views-field-title {
    font-weight: bold;
    a {
      font-weight: bold;
    }
  }
  
  .general-content-list-item {
    padding-bottom: 60px;
  }
}