.show-if-truncated {
  display: none;
}

.node-type-report {
  h1.title {
    text-transform: none;
    font-weight: 900;
  }
  .submitted {
    margin-bottom: 40px;
    margin-top: -10px;
  }
  .field-name-field-speakers {
    .p-t-md {
      padding-top: 0 !important;
    }
    .panopoly-image-square {
      border-radius: 10000px;
      width: 120px;
    }
  }
  .node-speaker {
    h4 {
      margin-bottom: 0;
    }
    .speaker-meta {
      float: left;
    }
    .speaker-headshot {
      float: left;
      margin-right: 10px;
    }
    .speaker-info {
      float: left;
      margin-left: 10px;
      margin-right: 30px;
      padding-right: 30px;
      border-right: 1px solid $gray-light;
    }

  }

  /**
   * Summary bio display.
   */
  .show-speaker-bio-truncated {
    h4.heading:not(.show-if-truncated) {
      display: none;
    }
    h4.heading + h4 {
      margin-top: 0;
    }
    .speaker-bio {
      max-height: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      // white-space: nowrap;
    }
    margin-bottom: 20px;
    .show-if-truncated {
      display: inline-block;
    }
  }

  /**
   * Full bio display.
   */
  .show-speaker-bio-full {
    .node-speaker {
      .field-name-field-featured-image {
        .panopoly-image-square {
          width: 180px;
        }
      }
      .speaker-meta {
        float: right;
      }
      .speaker-headshot {
        float: left;
        margin-left: 20px;
        padding-left: 20px;
      }
      .speaker-info {
        float: left;
        margin-left: 10px;
        border-right: none;
      }
      .speaker-info {
        float: right;
        height: 180px;
        margin-bottom: 40px;
      }
    }
  }

  .uk-tab>*>a {
    font-size: 1.3rem;
    font-weight: bold;
    border-bottom: 2px solid transparent;
  }

  .uk-tab>.uk-active>a {
    border-color: $brand-red;
  }

}

body:not(.node-type-report) {
  .node-speaker {
    .field-name-body {
      display: none !important;
    }
  }
}

.contributors-card {
  .uk-card-body {
    height: 177px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.kisaco-content-type-sponsor {
    .uk-card-body {
      padding: 20px !important;
    }
  }
}