/* Global Style overrides */
.page-node-20768,
.page-node-20817 {
  --kisaco-event-navbar-background-color: #592493;
  &.page-event .navbar-default .navbar-nav > li.leaf.last {
    height: 40px;
    display: flex;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      padding: 10px;
      color: #592493 !important;
      border-radius: 100rem;
      line-height: 0;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: 10px;
    }
  }
  &.page-event .navbar-brand,
  &.node-type-event .navbar-brand,
  &.is-event-subpage .navbar-brand {
    background: transparent;
    img {
      filter: contrast(50%) grayscale(100%) brightness(20);
    }
  }
}
