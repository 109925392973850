/**
 * Overrides for speaker assets.
 */

$show-names-height: 100px;
$speaker-listing-height: 400px;
$speaker-listing-compact-height: 300px;
$speaker-listing-xs-height: 200px;
$speaker-extra-height: 30px;

.listing {
  &.speaker-listing,
  &.speaker-listing-compact {
    color: $brand-white;
    height: $speaker-listing-height;
    @media screen and (max-width: 767px) {
      height: $speaker-listing-xs-height;
    }
    .element-invisible {
      display: none !important;
    }
    .speaker-info {
      margin-bottom: 15px;
      h4 {
        color: $brand-white;
        margin-bottom: 5px;
        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
    /**
     * Style overlay content.
     */
    .blur-overlay-popup-content {
      max-height: 131px;
      min-height: (131px * 0.5);
      overflow: hidden;
      margin-bottom: 15px;
      position: relative;
      @media screen and (max-width: 767px) {
        display: none !important;
      }
    }

    /**
     * Style for when showing speaker names, titles, companies outside overlay.
     */
    &.speaker-show-names {
      overflow: visible !important; // otherwise, the names underneath the overlay won't show.
      border-bottom: $show-names-height solid white;
      height: $speaker-listing-height + $show-names-height;
      @media screen and (max-width: 767px) {
        height: $speaker-listing-xs-height + $show-names-height;
      }
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        border-bottom: $show-names-height + $speaker-extra-height solid white;
        height: $speaker-listing-height + $speaker-extra-height +
          $show-names-height;
      }
      .speaker-show-name-display {
        width: 100%;
        position: absolute;
        left: 0;
        top: $speaker-listing-height; // don't use bottom, otherwise the speaker name could spill over the bottom of the overlay
        @media screen and (max-width: 767px) {
          top: $speaker-listing-xs-height;
        }
        padding: 5px 5px 0 20px;
        h4 {
          font-size: 16px;
          margin-bottom: 0;
          font-weight: bold;
        }
        font-size: 13px;
        color: #999;
        line-height: 16px;

        img.speaker-company-logo {
          float: right;
          margin-top: 16px;
          margin-right: 5px;
          max-height: 50px;
          max-width: 4.5vw;
          @media screen and (max-width: 991px) {
            max-width: 100px;
          }
          @media screen and (min-width: 1200px) {
          }
          margin-left: 15px;
        }
      }
    }
  }
  &.speaker-listing-compact {
    height: $speaker-listing-compact-height;
    &.speaker-show-names {
      height: $speaker-listing-compact-height + $show-names-height;
      @media screen and (max-width: 767px) {
        height: $speaker-listing-xs-height + $show-names-height;
      }
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        height: $speaker-listing-compact-height + $speaker-extra-height +
          $show-names-height;
      }
      .speaker-show-name-display {
        top: $speaker-listing-compact-height;
        @media screen and (max-width: 767px) {
          top: $speaker-listing-xs-height;
        }
      }
    }
    .blur-overlay-popup {
      padding-bottom: 10px;
      @media screen and (min-width: 992px) {
        padding-left: 7px;
        .blur-overlay-popup-content {
          display: none !important;
        }
      }
    }
  }
}

#speakers {
  padding-bottom: 0;
  overflow: hidden;
}
.speaker-btn-see-more-wrapper {
  padding-bottom: 30px;
}

/**
 * Hide the full bio so it can be brought into a colorbox later.
 * TODO: deprecate in favor of uk modal popup instead of magnific
 */
.speaker-full-bio {
  position: relative;
  background: $brand-white;
  padding: 20px;
  width: auto;
  max-width: 80%;
  margin: 20px auto;
  .header-line {
    margin-left: 0;
  }
}

.speaker-bio-popup {
  .header-line {
    margin-left: 0;
  }
}

.blur-overlay-bio-read-more {
  padding-bottom: 10px;
  a {
    color: $brand-white !important;
    @media screen and (max-width: 767px) {
      font-size: 13px;
    }
  }
}

/**
 * Special condition for when the speaker list is within an FPP.
 */
.kisaco-fpp-speaker-layout-compact {
  .speaker-listing {
    @media screen and (min-width: 1200px) {
      /**
       * All speakers are hard-baked to use bootstrap grid ... ugh.
       * Forceably set the layout to 6 per row, in this case.
       */
      width: 16.667%;
    }
    /* UK breakpoints */

    height: $speaker-listing-compact-height;
    @media screen and (max-width: 767px) {
      height: $speaker-listing-xs-height;
    }
    &.speaker-show-names {
      height: $speaker-listing-compact-height + $show-names-height;
      @media screen and (max-width: 767px) {
        height: $speaker-listing-xs-height + $show-names-height;
      }
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        height: $speaker-listing-compact-height + $speaker-extra-height +
          $show-names-height;
      }
      .speaker-show-name-display {
        top: $speaker-listing-compact-height;
        @media screen and (max-width: 767px) {
          top: $speaker-listing-xs-height;
        }
      }
    }
    .blur-overlay-popup {
      padding-bottom: 10px;
      @media screen and (min-width: 992px) {
        padding-left: 7px;
        .blur-overlay-popup-content {
          display: none !important;
        }
      }

      .speaker-info {
        margin-bottom: 15px;
        h4 {
          color: $brand-white;
          margin-bottom: 5px;
          font-size: 21px;
        }
        font-size: 16px;
      }

      .blur-overlay-bio-read-more {
        padding-bottom: 10px;
        a {
          font-size: 13px;
        }
      }
    }
  }
}

.speaker-card {
  .speaker-card-name,
  .uk-card-footer {
    font-size: 70%;
    line-height: 1.3rem;
  }
  img.speaker-card-logo,
  .field-name-field-company-logo img {
    max-height: 60px;
    max-width: 90px;
    object-fit: contain;
    object-position: left top;
  }
  .field-name-field-company-logo img {
    margin-top: 10px;
  }
}

.parent-event-speakers {
  .speaker-meta {
    display: flex;
    flex-direction: column;
  }
  .field-name-field-featured-image {
    width: auto !important;
    height: auto !important;
  }
  .speaker-headshot img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
    border-radius: 100rem;
  }
}

.square {
  .parent-event-speakers {
    .speaker-headshot img {
      border-radius: 0;
    }
  }
}
