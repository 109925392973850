.pane-bundle-faq,
.fieldable-panels-pane-faq {
  --theme-primary-color: #1d3459;
  --theme-secondary-color: #a30e17;

  --faq-font-size: 1.4rem;
  --faq-border: 1px solid #ccc;
  --faq-title-font-size: 1.8rem;
  --faq-title-font-weight: bold;
  --faq-content-font-size: 1.6rem;
  --faq-li-margin-top: 20px;
  --faq-li-padding: 2rem;

  .uk-accordion {
    background-color: rgba(255, 255, 255, 0.9);

    margin-top: 3rem;

    font-size: var(--faq-font-size);
    border: var(--faq-border);

    .uk-accordion-title {
      font-size: var(--faq-title-font-size);
      font-weight: var(--faq-title-font-weight);

      @media screen and (min-width: 768px) {
        &::before {
          float: left;
          margin-left: 0;
          margin-right: 10px;
          color: var(--theme-primary-color);
          stroke: var(--theme-primary-color);
          fill: var(--theme-primary-color);
        }
      }
    }

    .uk-accordion-content {
      font-size: var(--faq-content-font-size);

      @media screen and (min-width: 768px) {
        margin-left: 35px;
      }
    }

    li {
      margin-top: var(--faq-li-margin-top);
      padding: var(--faq-li-padding);
      padding-top: 0;
      border-bottom: var(--faq-border);
      &:last-child {
        border-bottom: 0;
      }
    }
  }

  &.contextual-links-region {
    .contextual-links-wrapper {
      display: none !important;
    }
  }
}

.inverted-pane .fieldable-panels-pane-faq .uk-accordion-content * {
  color: black !important;
}
