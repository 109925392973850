/**
 * Overrides for marketing plan admin pages.
 */

.node-type-marketing-plan {
  
  h2.pane-title {
    text-transform: initial;
  }
  
  .fa-clock-o {
    color: $brand-blue;
  }
  
  .row {
    &.past {
      background-color: $gray-lighter;
    }
    &.future {
      background: $brand-white url(../images/dotted-repeat-y.png) repeat-y 54px;
    }
    .future-dotted {
      margin-right: 50%;
      min-height: 200px;
      
    }
  }
  .view-footer {
    margin-top: 10px;
  }
  tr {
    &.view-subfooter-number { 
      display: none;
    }
    &.even {
      background-color: $gray-light;
    }
    &.odd {
      background-color: $gray-lighter;
    }
  }
  .tab-selector {
    cursor: pointer;
  }
  
  /**
   * Make table content smaller so things fit on one row where possible.
   */
  .table-responsive, 
  .views-table {
    th, td {
      font-size: 12px;
    }
    td {
      border-right: 1px solid $gray;
      &:empty::before {
        content: "--";
        color: $gray;
      }
    }
  }
  
  .col-xs-11 p:first-child {
    @extend .pull-right;
  }
  
  .col-xs-1 .fa {
    background: $brand-white;
  }
  
  .btn-sm {
    @extend .btn-sm;
    border: 1px solid $brand-blue;
  }
  
  td.views-field-field-planned-audience-reach,
  td.views-field-field-actual-audience-reach,
  td.views-field-field-eqs-actual,
  td.views-field-field-registration-goal,
  td.views-field-field-registration-actual {
    text-align: right;
    a {
      @extend .badge;
      font-size: 12px;
      background: $brand-blue;
    }
  }
  
  .gray {
    color: $gray;
  }
  
  .nav-pills::before {
    content: "View: ";
    font-weight: bold;
  }
  
  /**
   * Correct styling for labels within the context of marketing plan.
   */
  .chart {
    .label {
      color: $brand-blue;
    }
  }
  
  .field-name-field-attachments {
    text-align: center;
    border-bottom: 1px solid $gray-light;
    padding-bottom: 30px;
  }
  
}
