/**
 * Chosen conflicts with bootstrap's form-control.
 */
.chosen-container {
  &.form-control {
    padding: 0;
    border: none;
    border-radius: 4px;
    height: auto;
    max-width: 100%;
    ul {
      list-style-type: none;
      li.search-choice {
        border-radius: 10px;
        font-size: 12px;
        background: #f2f2f2;
        border-color: #eee;
      }
    }
  }
}

.field-name-field-featured-categories {
  label {
    position: static !important;
    height: auto;
    margin-top: 20px;
  }
}