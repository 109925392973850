$card-height: 300px;

@mixin tag {
  display: flex;
  gap: 0.6rem;
  &::before {
    content: ' ';
    width: 2rem;
    height: 2rem;
    border: 2px solid white;
    border-radius: 100rem;
    display: inline-block;
    background-color: var(--item-color);
    flex: 1 0 auto;
    box-shadow: 5px 5px 20px $gray-light;
  }
}
.fieldable-panels-pane-monthly-highlights-calendar {
  .field-name-field-month-collection {
    .resource-tags {
      margin-top: 3rem;
      margin-bottom: 2rem;
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      font-weight: bold;
      .tag {
        @include tag;
      }
      &.vertical {
        .tag::before {
          flex: 0 1 auto;
        }
        flex-direction: column;
      }
    }
    & > .field-items {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      gap: 3rem;
      & > .field-item {
        min-height: $card-height;
        border: 1px solid $gray-light;
        border-radius: 3px;
        box-shadow: 5px 5px 20px $gray-light;
        .field-collection-view {
          .field-collection-item-field-month-collection {
            min-height: $card-height;
            > .content {
              &:not(:has(.field-name-field-arbitrary-list-items)) {
                .field-collection-container {
                  padding: 0 !important;
                }
              }
            }

            background: var(--month-background-supporting-image-url) no-repeat;
            background-size: cover;

            .field-name-field-month {
              background: linear-gradient(
                90deg,
                var(--kisaco-fpp-theme-color-1) 0%,
                var(--kisaco-fpp-theme-color-2) 100%
              );
              color: white;
              padding: 0.5rem 1rem;
              font-weight: 900;
              text-transform: uppercase;
              letter-spacing: 3px;
              ~ .field-collection-container {
                padding: 2.5rem;
              }
            }

            .field-collection-container,
            .autopull-events {
              background: linear-gradient(
                to bottom,
                #ffffffff 0%,
                #ffffffee 80%,
                #ffffffcc 100%
              );
              .field-name-field-arbitrary-list-items {
                .auto-pull-item {
                  @include tag;
                  padding-bottom: 2.5rem;
                  margin-bottom: 2.5rem;
                  border-bottom: 1px solid $gray-lighter;
                  .field-type-taxonomy-term-reference {
                    font-size: 12px;
                    font-weight: bold;
                  }
                }
                > .field-items > .field-item:not(:last-child) {
                  border-bottom: 1px solid $gray-lighter;
                  padding-bottom: 2.5rem;
                  margin-bottom: 2.5rem;
                }
                .field-collection-item-field-arbitrary-list-items {
                  .field-type-taxonomy-term-reference {
                    font-size: 12px;
                    font-weight: bold;
                  }
                  @include tag;
                  .content {
                    margin-bottom: 2rem;
                    padding-bottom: 2.5rem;
                  }
                  .field-name-field-url {
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Support for inventory items
.panel-pane.pane-fieldable-panels-pane {
  &:not(.inventory):not(.show-inventory) {
    .inventory-item {
      display: none !important;
    }
    .fieldable-panels-pane-monthly-highlights-calendar
      .field-items
      > .field-item {
      &:has(.has-no-public-items) {
        display: none !important;
      }
    }
  }
  .field-collection-item-field-arbitrary-list-items:not(.inventory-item) {
    .field-name-field-qty {
      display: none !important;
    }
  }
  .inventory-item {
    position: relative;
    border: 3px dashed lighten($gray, 20);
    padding: 2rem;
    margin-bottom: 4rem;
    border-radius: 1rem;
    .content {
      // we don't need margin or padding, because it's already in a box.
      margin-bottom: 0 !important;
      padding-bottom: 1rem !important;
    }
    .field-name-field-qty {
      position: absolute;
      top: -1.5rem;
      right: 0;
      margin-right: 1rem;
      background: lighten($gray, 30);
      padding: 0.5rem 1.5rem;
      border-radius: 1.5rem;
      color: $brand-blue;
      display: inline-block;
      .field-item {
        font-size: 1.3rem;
        font-weight: bold;
        &::before {
          content: '+ ';
        }
        &::after {
          content: ' open slots available';
        }
      }
      &.singular {
        .field-item {
          &::after {
            content: ' open slot available';
          }
        }
      }
    }
  }
}
