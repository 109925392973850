// Page Layout
// -----------------------------------------------------------------------------

/**
 * We don't want the title displayed on any home page.
 */
body {
  &.front {
    .page-header {
      display: none;
    }
  }
}

/**
 * Title should have nice padding on desktop.
 */
@media screen and (min-width: $container-desktop) {
  body:not(.page-admin) {
    .page-header {
      margin-top: 100px;
    }
  }
}

.main {
  min-height: 45vh;
}

header.header-has-fixed-navbar + #main-wrapper {
  padding-top: 100px;
}

/**
 * Header logo.
 */
.main-logo-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  .main-logo {
    height: auto !important;
  }
}

// .header-scrolled .navbar-transparent .navbar-brand .main-logo-wrapper {
//   justify-content: center;
// }

/**
 * About us adjustments.
 */

#about-us {
  .field-type-image {
    margin: 30px;
    margin-bottom: 0;
  }
}

@media #{$mediaquery-not-desktop} {
  .radix-layouts-secondarycolumn1 {
    text-align: center;
  }
}
@media #{$mediaquery-desktop}, #{$mediaquery-large-desktop} {
  .radix-layouts-secondarycolumn2 {
    margin-top: 75px;
  }
}
@media #{$mediaquery-tablet} {
  .radix-layouts-secondarycolumn1 {
    text-align: center;
  }
}

/**
 * Adjust padding for footer since there is no title.
 */
.pane-footer {
  margin-top: 60px;
}

/**
 * Remove the default horizontal line under page title provided by bootstrap.
 */
body {
  &:not(.page-admin):not(.page-node-add):not(.page-node-edit) {
    .page-header {
      border-bottom: none;
    }
  }
}

/**
 * Text styling.
 */
.smaller {
  * {
    font-size: $font-size-smaller;
  }
}

.panopoly-landing-page {
  .event-header + #main-wrapper {
    padding-top: 0;
  }
}
