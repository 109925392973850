// Styles for Tabs, Messages, ..etc
// -----------------------------------------------------------------------------

/**
 * Basic padding styles pulled from Bootstrap
 */
@use 'sass:math';

.pos-r {
  position: relative !important;
}

.pos-a {
  position: absolute !important;
}

.pos-f {
  position: fixed !important;
}

.w-sm {
  width: 25% !important;
}

.w-md {
  width: 50% !important;
}

.w-lg {
  width: 75% !important;
}

.w-full {
  width: 100% !important;
}

.m-a-0 {
  margin: 0 !important;
}

.m-t-0 {
  margin-top: 0 !important;
}

.m-r-0 {
  margin-right: 0 !important;
}

.m-b-0 {
  margin-bottom: 0 !important;
}

.m-l-0 {
  margin-left: 0 !important;
}

.m-x-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.m-y-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-a {
  margin: 20px !important;
}

.m-t {
  margin-top: 20px !important;
}

.m-r {
  margin-right: 20px !important;
}

.m-b {
  margin-bottom: 20px !important;
}

.m-l {
  margin-left: 20px !important;
}

.m-x {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.m-y {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.m-x-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.m-a-md {
  margin: 30px !important;
}

.m-t-md {
  margin-top: 30px !important;
}

.m-r-md {
  margin-right: 30px !important;
}

.m-b-md {
  margin-bottom: 30px !important;
}

.m-l-md {
  margin-left: 30px !important;
}

.m-x-md {
  margin-right: 30px !important;
  margin-left: 30px !important;
}

.m-y-md {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.m-a-lg {
  margin: 60px !important;
}

.m-t-lg {
  margin-top: 60px !important;
}

.m-r-lg {
  margin-right: 60px !important;
}

.m-b-lg {
  margin-bottom: 60px !important;
}

.m-l-lg {
  margin-left: 60px !important;
}

.m-x-lg {
  margin-right: 60px !important;
  margin-left: 60px !important;
}

.m-y-lg {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.p-a-0 {
  padding: 0 !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-l-0 {
  padding-left: 0 !important;
}

.p-x-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-y-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-a {
  padding: 20px !important;
}

.p-t {
  padding-top: 20px !important;
}

.p-r {
  padding-right: 20px !important;
}

.p-b {
  padding-bottom: 20px !important;
}

.p-l {
  padding-left: 20px !important;
}

.p-x {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.p-y {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.p-a-md {
  padding: 30px !important;
}

.p-t-md {
  padding-top: 30px !important;
}

.p-r-md {
  padding-right: 30px !important;
}

.p-b-md {
  padding-bottom: 30px !important;
}

.p-l-md {
  padding-left: 30px !important;
}

.p-x-md {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.p-y-md {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.p-a-lg {
  padding: 60px !important;
}

.p-t-lg {
  padding-top: 60px !important;
}

.p-r-lg {
  padding-right: 60px !important;
}

.p-b-lg {
  padding-bottom: 60px !important;
}

.p-l-lg {
  padding-left: 60px !important;
}

.p-x-lg {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.p-y-lg {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

/**
 * Styles for bootstrap 5ths column (20%).
 */
.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-15 {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}

/**
 * Styles for bootstrap 5ths column 2x (40%).
 */
.col-xs-30,
.col-sm-30,
.col-md-30,
.col-lg-30 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-30 {
  width: 40%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-30 {
    width: 40%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-30 {
    width: 40%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-30 {
    width: 40%;
    float: left;
  }
}

/**
 * Styles for bootstrap 5ths column 3x (60%).
 */
.col-xs-45,
.col-sm-45,
.col-md-45,
.col-lg-45 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col-xs-45 {
  width: 60%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-45 {
    width: 60%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-45 {
    width: 60%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-45 {
    width: 60%;
    float: left;
  }
}

/**
 * Styles for vertically centered blocks.
 */
.block-fill-height {
  display: table;
  width: 100%;
}
.block-xs-top,
.block-xs-bottom,
.block-xs-middle {
  display: table-cell;
  vertical-align: middle;
}
.block-xs-bottom {
  vertical-align: bottom;
}
.block-xs-top {
  vertical-align: top;
}
@media (min-width: 768px) {
  .block-sm-top,
  .block-sm-bottom,
  .block-sm-middle {
    display: table-cell;
    vertical-align: middle;
  }
  .block-sm-bottom {
    vertical-align: bottom;
  }
  .block-sm-top {
    vertical-align: top;
  }
}
@media (min-width: 992px) {
  .block-md-top,
  .block-md-bottom,
  .block-md-middle {
    display: table-cell;
    vertical-align: middle;
  }
  .block-md-bottom {
    vertical-align: bottom;
  }
  .block-md-top {
    vertical-align: top;
  }
}
@media (min-width: 1200px) {
  .block-lg-top,
  .block-lg-bottom,
  .block-lg-middle {
    display: table-cell;
    vertical-align: middle;
  }
  .block-lg-bottom {
    vertical-align: bottom;
  }
  .block-lg-top {
    vertical-align: top;
  }
}

/**
 * Styles for cover images.
 */
.cover {
  background-size: cover;
  background-position: center top;
  height: 100vh;
}

/**
 * Make all buttons consistent styles.
 */
body {
  &:not(.page-admin):not(.page-node-add):not(.page-node-edit):not(
      .page-search
    ):not(.node-type-marketing-plan) {
    .pane-content {
      .form-actions {
        text-align: center;
      }
      .btn {
        --_button-bg-color: var(
          --button-bg-color,
          var(--kisaco-event-primary-color, #{$brand-blue})
        );
        --_button-border-color: var(
          --button-border-color,
          var(--kisaco-event-primary-color, #{$brand-blue})
        );
        --_button-text-color: var(
          --button-text-color,
          var(--kisaco-event-primary-color-text, white)
        );
        --_button-border-width: var(--button-border-width, 2px);
        --_button-bg-color-hover: var(
          --button-bg-color-hover,
          var(--_button-text-color)
        );
        --_button-text-color-hover: var(--button-text-color-hover, white);
        --_button-font-weight: var(--button-font-weight, 700);
        --_button-padding: var(
          --button-padding,
          var(--button-padding-y, 0.5rem) var(--button-padding-x, 2em)
        );
        --_button-font-size: var(--button-font-size, var(--text-size-200));
        --_button-shadow: var(--button-shadow, none);

        border-radius: 0;
        // border: var(--_button-border-width) solid var(--_button-border-color);
        text-transform: uppercase;
        padding: var(--_button-padding);
        background-color: var(--_button-bg-color);
        color: var(--_button-text-color);
        @include transition(background-color 0.3s);
        @include transition(color 0.3s);
        &.fill {
          background-color: var(--_btn-background-color);
        }
      }
      .btn:hover {
        background-color: var(--_button-text-color);
        color: var(--_button-bg-color);
      }
    }

    .inverted-pane {
      .pane-content {
        .btn {
          border-color: #ffffff;
          color: #ffffff;
          background-color: none;
          transition: all 0.3s;
          &.fill {
            background-color: $brand-blue;
          }
        }
        .btn:hover {
          background-color: $brand-white;
          color: $brand-blue;
          * {
            color: $brand-blue;
          }
        }
      }
    }
  }
}

.btn {
  &.btn-circle {
    background: $brand-red;
    padding: 3em;
    border-radius: 1000px;
    clear: both;
    color: $brand-white;
    width: 184px;
    height: 180px;
    text-align: center;
    &:hover {
      background: $brand-white;
      color: $brand-red;
      @include transition(background-color 0.8s);
      @include transition(color 0.8s);
    }
  }
}

.pane-content .btn {
  &.btn-outline {
    border: 2px solid $brand-white !important;
    border-radius: 0 !important;
    background: none !important;
    padding: 8px;
    &:hover {
      background: $brand-white !important;
      color: $brand-blue !important;
    }
  }
}

/**
 * Styles for Video Container.
 */
@media #{$mediaquery-not-phone} {
  .video-container {
    display: block;
    overflow: hidden;
    width: 104%;
    right: 0;
    @include transform-style(preserve-3d);
    @include transform(skew(17deg));
    margin-right: -15%;
    z-index: 10;
    height: 500px;
    position: absolute;
    top: 0;
    right: 0;
    video {
      @include transform(skew(-17deg));
      height: 140%;
      width: auto;
      float: left;
      position: relative;
      margin-left: -15%;
    }

    // Taken from Vimeo's responsive embed code
    // Margin adjusted for KR
    .responsive-vimeo-embed {
      @include transform(skew(-17deg));
      padding: 56.25% 0 0 0;
      margin-left: -20%;
      margin-right: -10px;
    }
  }
}

@media #{$mediaquery-phone} {
  .video-container {
    margin-top: -8px;
    video {
      max-width: 100%;
      height: auto;
    }
    .responsive-vimeo-embed {
      padding: 56.25% 0 0 0;
    }
  }
}

@media #{$mediaquery-not-phone} {
  .video-overflow-preventer {
    overflow: hidden;
    height: 511px;
  }
}

/**
 * Styling for primary callout on home page.
 */
@media #{$mediaquery-not-phone} {
  .main-callout {
    padding-left: 19%;
    padding-top: 13%;
  }
}

/**
 * Compensate for the fact that the video module should be flush with the
 * header.
 */
.flush-header {
  margin-top: -32px;
  margin-bottom: -11px;
}

/**
 * Special handling for iPad portrait.
 */
@media #{$mediaquery-tablet} {
  .video-container {
    max-height: 450px;
    width: 100%;
    margin-right: 0;
    @include transform(skew(0deg));
    video {
      @include transform(skew(0deg));
      height: 140%;
    }
  }
  .video-overflow-preventer {
    height: 400px;
  }
  .main-callout {
    padding-right: 12%;
    padding-left: 12%;
    padding-top: 6%;
    padding-bottom: 13%;
  }
}

/**
 * Special handling for small desktop / iPad landscape.
 */
@media #{$mediaquery-desktop} {
  .video-container {
    max-height: 460px;
    width: 105%;
    margin-right: -18%;
    video {
      height: 100%;
    }
  }
  .main-callout {
    padding-right: 1%;
    padding-left: 7%;
    padding-bottom: 11%;
    padding-top: 16%;
  }
  .flush-header {
    margin-bottom: -18px;
  }
  .video-overflow-preventer {
    overflow: hidden;
    height: 478px;
  }
}

/**
 * Style lists and DDs.
 */
.page-node-add {
  dd {
    padding-bottom: 5px;
    border-bottom: 1px solid $gray-light;
    margin-bottom: 10px;
  }
}

/**
 * Style OLs.
 */
$ol-size: 56px;
$ol-padding-left: 18px;
.pane-content {
  ol:not(.carousel-indicators) {
    display: block;
    padding: 8px 0 0 ($ol-size + $ol-padding-left);
    list-style: none;
    counter-reset: numList;
    li {
      // float: left;
      position: relative;
      line-height: $ol-size * 0.5;
      margin-bottom: $ol-size - math.div($ol-size, 3);
      &:before {
        counter-increment: numList;
        content: counter(numList);
        float: left;
        position: absolute;
        left: -($ol-size + $ol-padding-left);
        top: -4px;
        text-align: center;
        color: $brand-blue;
        line-height: $ol-size - 3px;
        width: $ol-size;
        height: $ol-size;
        background: none;
        border: 2px solid $brand-blue;
        font-size: $font-size-h4;
        font-weight: bold;
        -moz-border-radius: 999px;
        border-radius: 999px;
      }
    }
    &.start-at-2 {
      counter-reset: numList 1;
    }
    &.start-at-3 {
      counter-reset: numList 2;
    }
    &.start-at-4 {
      counter-reset: numList 3;
    }
    &.start-at-5 {
      counter-reset: numList 4;
    }
    &.start-at-6 {
      counter-reset: numList 5;
    }
    &.start-at-7 {
      counter-reset: numList 6;
    }
    &.start-at-8 {
      counter-reset: numList 7;
    }
    &.start-at-9 {
      counter-reset: numList 8;
    }
  }
}

/**
 * Header lines to left and right of H3's.
 */
.header-wrapper {
  text-align: center;
}
@media #{$mediaquery-not-phone} {
  .header-line {
    background: $brand-blue;
    width: 112px;
    height: 1px;
    margin: 11px 15px;
    display: inline-block;
  }
}

@media #{$mediaquery-tablet} {
  .tablet-header-exceeds {
    .header-line {
      display: none;
    }
  }
}

/**
 * Styles for horizontal rules.
 * Creates a thin line with an outlined arrow in the center.
 * Usage: 
 * <div class="line-separator">
 * <div class="sl"></div><div class="triangle"></div><div class="sl"></div>
 * </div>
 */

.line-separator {
  margin-bottom: 50px;
  text-align: center;
}

.sl {
  display: inline-block;
  border-top: $line-width solid $line-color;
  width: calc(50% - #{$arrow-size + 1px});
}

/**
 * Creates a thin line with an outlined arrow to the right.
 * Usage: 
 * <div class="line-separator">
 * <div class="sl-l"></div><div class="triangle"></div><div class="sl-r"></div>
 * </div>
 */

.sl-l {
  @extend .sl;
  width: calc(85% - #{$arrow-size + 1px});
}

.sl-r {
  @extend .sl;
  width: calc(15% - #{$arrow-size + 1px});
}

.triangle {
  display: inline-block;
  height: $arrow-size;
  width: $arrow-size;
  transform: rotate(45deg);
  transform-origin: center center;
  border-bottom: $line-width solid $line-color;
  border-right: $line-width solid $line-color;
  margin-left: ($arrow-size * 0.2);
  margin-right: ($arrow-size * 0.2);
  margin-bottom: ($arrow-size * 0.5) * -1;
}

/**
 * Creates a thick line with a filled arrow in the center.
 * Usage: 
 * <div class="diamond-line"></div><div class="arrow-down"></div>
 */

.diamond-line {
  border-bottom: $thick-line-width solid $line-color;
  margin-top: 20px;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: $arrow-size solid transparent;
  border-right: $arrow-size solid transparent;
  border-bottom: ($arrow-size - 10px) solid $line-color;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: $arrow-size solid transparent;
  border-right: $arrow-size solid transparent;
  border-top: ($arrow-size - 14px) solid $line-color;
  margin: 0 calc(50% - #{$arrow-size});
  margin-bottom: 10px;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: $arrow-size solid transparent;
  border-bottom: $arrow-size solid transparent;
  border-left: ($arrow-size - 10px) solid $line-color;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: $arrow-size solid transparent;
  border-bottom: $arrow-size solid transparent;
  border-right: ($arrow-size - 10px) solid $line-color;
}

/**
 * Styles for beveled corners.
 */
$bevel-size: 45px;
$bevel-background-color: $brand-white;
.bevel.tr {
  background: -moz-linear-gradient(
      45deg,
      $bevel-background-color $bevel-size,
      $bevel-background-color $bevel-size
    ),
    -moz-linear-gradient(135deg, $bevel-background-color $bevel-size, $bevel-background-color
          $bevel-size),
    -moz-linear-gradient(225deg, transparent $bevel-size, $bevel-background-color
          $bevel-size),
    -moz-linear-gradient(315deg, $bevel-background-color $bevel-size, $bevel-background-color
          $bevel-size);
  background: -o-linear-gradient(
      45deg,
      $bevel-background-color $bevel-size,
      $bevel-background-color $bevel-size
    ),
    -o-linear-gradient(135deg, $bevel-background-color $bevel-size, $bevel-background-color
          $bevel-size),
    -o-linear-gradient(225deg, transparent $bevel-size, $bevel-background-color
          $bevel-size),
    -o-linear-gradient(315deg, $bevel-background-color $bevel-size, $bevel-background-color
          $bevel-size);
  background: -webkit-linear-gradient(
      45deg,
      $bevel-background-color $bevel-size,
      $bevel-background-color $bevel-size
    ),
    -webkit-linear-gradient(135deg, transparent $bevel-size, $bevel-background-color
          $bevel-size),
    -webkit-linear-gradient(225deg, $bevel-background-color $bevel-size, $bevel-background-color
          $bevel-size),
    -webkit-linear-gradient(315deg, $bevel-background-color $bevel-size, $bevel-background-color
          $bevel-size);
}
.bevel.all,
.bevel.trbl,
.bevel.tlbr,
.bevel.tr {
  background-position: bottom left, bottom right, top right, top left;
  -moz-background-size: 51% 51%;
  -webkit-background-size: 51% 51%;
  background-size: 51% 51%;
  background-repeat: no-repeat;
  padding: 30px;
  height: 130px;
}

.listing {
  background-size: cover;
  color: $brand-white;
  height: 440px;
  .element-invisible {
    display: none !important;
  }
  /**
   * Style overlay content.
   */
  .blur-overlay-popup-content {
    max-height: 91px;
    min-height: (91px * 0.5);
    overflow: hidden;
    margin-bottom: 15px;
    position: relative;
  }
  &.listing-hidden {
    display: none !important;
  }
  &:hover {
    .blur-overlay-popup {
      pointer-events: all;
    }
  }
}

/**
 * Define styles for blurred overlay.
 */
.blur-overlay-wrapper {
  position: relative;
  overflow: hidden;
  .blur-overlay-background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .blur-overlay-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .blur-overlay-content-inner {
    padding: 20px;
  }
  .blur-overlay-popup {
    position: absolute;
    bottom: -460px;
    padding: 20px;
    padding-bottom: 50px;
    opacity: 0;
    pointer-events: none;
    @include transition(0.6s opacity ease-out);
    .blur-overlay-popup-content {
      padding-right: 2em;
      font-size: $font-size-small;
    }
    .fa {
      color: $brand-white;
      margin-right: 8px;
    }
  }
  &:hover {
    .blur-overlay-background {
      filter: blur(10px);
      @include transition(0.6s filter ease-out);
    }
    .blur-overlay-content {
      background: rgba($brand-blue, 0.6);
      @include transition(0.6s background ease-out);
    }
    .blur-overlay-popup {
      bottom: 0;
      opacity: 1;
      @include transition-delay(1.2s);
      @include transition(0.2s bottom ease-out);
    }
  }
}

@media #{$mediaquery-phone} {
  .blur-overlay-wrapper {
    .blur-overlay-content-inner {
      padding-left: 5px;
      padding-right: 0;
    }
    .blur-overlay-popup {
      padding-left: 5px;
      padding-right: 0;
      .blur-overlay-popup-content {
        padding-right: 2em;
      }
    }
  }
}

@media #{$mediaquery-tablet} {
  .blur-overlay-wrapper {
    .blur-overlay-content-inner {
      padding-left: 15px;
      padding-right: 15px;
    }
    .blur-overlay-popup {
      padding-left: 15px;
      padding-right: 0;
    }
  }
}

@media #{$mediaquery-desktop} {
  .blur-overlay-wrapper {
    .blur-overlay-content-inner {
      padding-left: 10px;
      padding-right: 0;
    }
    .blur-overlay-popup {
      padding-left: 10px;
      padding-right: 0;
    }
  }
}

.pseudo-anchor {
  cursor: pointer;
}

/**
 * Provides a hidden title to each event teaser node, so it can be found via Cmd + F.
 */
.hidden-title {
  position: absolute;
  top: 0;
  color: white;
  font-size: 1.2rem;
}
