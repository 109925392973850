.kisaco-video-playlist {
  &--player {
    .kisaco-video-playlist--meta {
      h4 {
        font-weight: bold;
      }
    }
  }
  &--playlist {
    border: none;
    resize: none;
    &.uk-panel-scrollable {
      /* UK breakpoints */
      @media screen and (max-width: 959px) {
        overflow: none;
        height: auto;
      }
    }
  }
}

.media-element {
  &.media-vimeo-video {
    padding: 56.25% 0 0 0;
    position: relative;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      aspect-ratio: 16 / 9;
    }
  }
}
