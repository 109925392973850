.icon-circular {
  display: inline-block;
  width: 72px;
  height: 72px;
  border-radius: 48px;
  background: $brand-white;
  margin: 15px 20px 0 0;
  @include transition(background-color 0.125s);
  text-align: center;
}

.icon-circular:hover {
  text-decoration: none;
  background-color: #bbc5c9;
}

.icon-circular svg,
.icon-circular i {
  display: block;
  fill: $brand-white;
  color: $brand-white;
  margin-top: 25%;
}

.kisaco-sharing-fixed-sidebar {
  position: relative;
  .kisaco-social-sharing {
    position: static;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 0.4rem;
    @media screen and (max-width: 600px) {
      justify-content: center;
    }
    align-items: center;
    @media screen and (max-width: 1349px) {
      padding-bottom: 2rem;
    }
    @media screen and (min-width: 600px) and (max-width: 1349px) {
      padding-right: 5rem;
    }
    @media screen and (min-width: 1350px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: fixed;
      z-index: 1;
      gap: 0;
    }
    .social-sharing-icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      color: white;
      width: 5rem;
      height: 5rem;
      @media screen and (min-width: 1350px) {
        &:first-child {
          border-top-right-radius: 5px;
        }
        &:last-child {
          border-bottom-right-radius: 5px;
        }
      }
      @media screen and (max-width: 1349px) {
        border-radius: 100rem;
        padding: 0.5rem;
        width: 4rem;
        height: 4rem;
      }
      &.twitter {
        background-color: #1da1f2;
      }
      &.facebook {
        background-color: #4267b2;
      }
      &.linkedin {
        background-color: #0077b5;
      }
      &.email {
        background-color: #e1306c;
      }
      &.link {
        background-color: #999;
      }
    }
  }
}
