:root {
  --kisaco-event-navbar-background-color: #ffffff;
  --kisaco-event-navbar-link-color: #222222;
  --kisaco-event-navbar-link-hover: #444444;
  --kisaco-event-navbar-link-special-background-color: #000000;
  --kisaco-event-navbar-link-special-background-color-hover: #000000ee;
  --kisaco-event-navbar-link-special-text-color: #ffffff;
}

/**
 * Overrides for events pages.
 */

body.logged-in {
  &.page-event,
  &.node-type-event,
  &.is-event-subpage {
    /**
     * Admin toolbar covers the top part of admin pages.
     */
    padding-top: 29px !important;
  }
}

/**
 * We want to hit both the front-end event page and admin pages.
 * .page-event is only rendered on the front-end.
 * .node-type-event is rendered on both (but for this context, we can assume
 * it represents an add/edit page.)
 */
.page-event,
.node-type-event,
.is-event-subpage {
  .date-display-range {
    display: inline;
  }

  font-weight: 200;

  /**
   * No need for main wrapper to have a 132px padding at the top.
   */
  #main-wrapper {
    padding-top: 0;
  }

  /**
   * Restyle main navigation.
   */
  .navbar {
    // background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0,0,0, 0.2) 60%, rgba(0, 0, 0, 0.6) 100%);
    @include transition(all 1s ease);
    margin-bottom: 0;
    border-radius: 0;
  }

  .navbar-default {
    .navbar-nav {
      @media #{$mediaquery-not-hamburger-nav} {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      > li {
        height: auto;
        line-height: normal;
        & > a {
          height: auto;
          display: block;
          @media #{mediaquery-not-hamburger-nav} {
            border-bottom: 5px solid transparent;
            border-top: 5px solid transparent;
            white-space: nowrap;
          }
        }
        &.highlighted {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          line-height: normal;
          height: auto;
          margin-left: 15px;
          margin-right: 15px;
          > a {
            border-top: none !important;
            border-bottom: none !important;
            display: block;
            height: auto;
            line-height: 3.5rem;
            border-radius: 1000px;
          }
        }
        &.last {
          .dropdown-menu {
            left: unset;
            right: 0;
          }
        }
      }
      /**
       * Adjustments for mobile.
       */
      @media #{$mediaquery-hamburger-nav} {
        > li {
          a {
            border-bottom: none;
            border-top: none;
          }
          &.highlighted {
            a {
              border: none;
              padding: 15px 5rem;
            }
          }
        }
      }
    }
  }

  .navbar-default .navbar-nav.condensed > li > a {
    padding-left: 13px;
    padding-right: 13px;
  }

  .navbar-brand {
    margin-top: 5px;
    width: auto !important;
    max-width: 255px !important;
    background: $brand-white;
    text-align: center;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 0.2rem;
    &:hover {
      background: white;
    }
    img {
      max-height: 80px;
      &#logo {
        border-radius: 0.2rem;
        margin: 0 auto;
        float: none;
        padding: 2px 0;
        height: auto;
        flex-shrink: 0;
      }
    }
  }

  .navbar-default .navbar-toggle {
    margin-top: 13px;
  }

  .menu-flags {
    margin-top: 13px;
    margin-left: 15px;
    float: right;
    width: 36px;
    height: 36px;
    max-width: 36px;
    max-height: 36px;
    @media #{$mediaquery-phone} {
      width: 100%;
      max-width: 100%;
    }

    @media #{$mediaquery-not-phone} and (max-width: 991px) {
      text-align: right;
      width: 50%;
      max-width: 50%;
    }

    .language-dropdown-wrapper {
      @media screen and (max-width: 991px) {
        width: 100%;
        .current-language {
          width: 36px;
          margin: 0 5px;
        }
        .current-language,
        .language-options,
        .language-option {
          display: inline-block;
        }
        .language-option {
          margin: 0 5px;
        }
      }

      @media screen and (min-width: 992px) {
        width: 66px;
        margin-left: -15px;
        .current-language {
          padding-left: 15px;
        }
        .language-options {
          padding-top: 13px;
          visibility: hidden;
          opacity: 0;
          @include transition(visibility 0s, opacity 0.2s linear);
          &.language-options-showing {
            visibility: visible;
            opacity: 1;
          }
          .language-option {
            border-top: 1px solid rgba($brand-white, 0.2);
            padding: 10px 0;
            padding-left: 15px;
            @include transition(background 0.3s);
            &:hover {
              background: $brand-white;
            }
          }
        }
      }
    }
  }

  /**
   * Vertically centers the hamburger menu and adjust style.
   */
  .navbar-default {
    .navbar-toggle {
      margin-top: 14px;
    }
  }

  .event-center-info {
    max-width: 350px;
    margin: 0 auto;
    border-radius: 25px;
    background: linear-gradient(
      to bottom,
      rgba($brand-white, 0.85) 0%,
      rgba($brand-white, 1) 9%,
      rgba($brand-white, 1) 70%,
      rgba($brand-white, 0.8) 100%
    );
    padding: 30px;
    font-size: $font-size-small;
    font-weight: bold;
    border: none;
    .fa {
      font-size: $font-size-h4;
    }
    .event-main-logo {
      margin: 0.3em 0 0;
      /**
       * May need to remove this if we get transparent logos.
       */
      background: $brand-white;
      border-radius: 10px;
      padding: 10px 10px 0;
    }
    .event-presenter {
      .event-presenter-line {
        margin: 1em 0em;
        border-top: 2px solid $gray-light;
      }
      border-bottom: 2px solid $gray-light;
      color: $gray;
      margin: 0 4.5em 0.6em;
      padding: 0 0 1.2em;
      font-weight: normal;
      font-size: 12px;
      img {
        max-width: 70% !important;
        margin: 0 auto;
      }
    }
    &.has-tagline {
      .event-main-logo {
        margin-top: 0;
        padding-top: 0;
      }
      .event-tagline {
        font-size: 95%;
        padding-bottom: 5px;
      }
    }
    .event-main-location {
      margin-top: 0.3em;
      padding-top: 10px;
    }
    .date-display-range {
      display: inline;
    }
  }

  .main-ctas {
    .btn-circle {
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.35) 0%,
        rgba(0, 0, 0, 0.45) 100%
      );
    }
    .cta {
      text-transform: uppercase;
      color: $brand-white;
      font-weight: bold;
      margin: 10px 0;
    }
    .blurb {
      border-top: 7px solid $brand-white;
      color: $brand-white;
      font-size: $font-size-h2;
      padding-top: 0.5em;
      text-shadow: 2px 2px 5px $brand-black;
      line-height: 1.2em;
      @media #{$mediaquery-phone} {
        border-top: none;
        border-left: 7px solid $brand-white;
        margin-bottom: 1em;
        font-size: $font-size-h3;
        padding-left: 0.4em;
        text-align: left;
        padding-bottom: 0.5em;
      }
    }
    div:nth-child(even) {
      .blurb {
        @media #{$mediaquery-phone} {
          text-align: right;
          border-left: none;
          padding-left: 0;
          padding-right: 0.4em;
          border-right: 7px solid $brand-white;
        }
      }
    }
  }

  #footer {
    border-top: 1px solid $brand-white;
    margin: 0;
    padding: 36px 0;
    .social-media-icons {
      margin-bottom: 60px;
      margin-top: 20px;
      .icon-circular {
        margin-left: 1.2em;
        margin-right: 1.2em;
        .fa {
          margin-top: 20px;
          font-size: 32px !important;
        }
        &:hover {
          background: $gray-light !important;
        }
      }
    }

    .registered-company-info {
      font-size: 12px;
      text-align: center;
    }
  }

  .social-media-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    .icon-circular {
      &:hover {
        background: $gray-light !important;
      }
    }
  }

  .preso-thumb {
    width: 300px;
    margin-bottom: 8px;
  }
}

/**
 * Apply specific styles only to the page itself, not admin pages.
 */
.page-event {
  /**
   * Button styles.
   */
  .old-event-layout {
    .btn:not(.btn-circle) {
      border: none !important;
      border-radius: 2px !important;
    }
  }

  .btn {
    border: none;
    border-radius: 2px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .btn-event-hero {
    margin-top: 5px;
    padding-left: 14px;
    background-color: #f58d8c;
    color: $brand-black;
    font-weight: 500;
    font-size: 16px;

    &:hover,
    &:focus {
      background-color: #f99796;
    }

    @media (max-width: 767px) {
      margin-top: 5px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.22);
    }

    &__arrow {
      padding: 11px 0 12px 21px;
      margin: -15px -10px -14px 27px;
      border-left: solid 1px #f17170;
    }
  }
}

/**
 * Add / Edit navigation fix.
 */
.node-type-event {
  &.page-node-edit,
  &.page-node-add {
    #main-wrapper {
      padding-top: 80px;
    }
  }
}

/**
 * Not using a stretching header on events sites, so
 * remove the extra height.
 */
@media #{$mediaquery-phone} {
  .page-event,
  .node-type-event {
    .navbar {
      margin-bottom: 0;
    }
    .navbar-default {
      .navbar-nav {
        > li {
          height: 50px;
          > a {
            height: 50px;
            line-height: 40px;
          }
        }
      }
    }
    .event-center-info {
      max-width: 100%;
      border-radius: 0;
      margin-top: 35px;
      margin-bottom: 25px;
    }
    .main-ctas {
      .btn-circle {
        border-radius: 5px;
        padding: 10px 15px;
        height: auto;
        width: 95%;
        text-align: left;
        margin-bottom: 20px;
        text-transform: uppercase;
        font-size: 1.2em;
        .fa {
          font-size: 1.2em;
        }
      }
      .cta {
        display: none;
      }
    }
    /**
     * Center the menu flags on mobile.
     */
    .menu-flags {
      float: none;
      clear: both;
      margin: 0 auto;
      text-align: center;
    }

    #footer {
      .social-media-icons {
        .icon-circular {
          margin-left: 0.3em;
          margin-right: 0.3em;
        }
      }
    }
  }
}

@media #{$mediaquery-not-phone} {
  .page-event,
  .node-type-event {
    .event-center-info-container {
      padding-left: 4em;
      padding-right: 4em;
    }
    .main-ctas {
      padding-top: 120px;
    }
  }
}

@media #{$mediaquery-desktop} {
  .page-event,
  .node-type-event {
    /**
     * Ensure the logo can't get cut off on shrunk desktop browsers.
     */
    .navbar-default .navbar-nav > li {
      & > a {
        font-size: $font-size-smaller;
        padding-right: 9px;
        padding-left: 8px;
      }
    }
    .event-center-info-container {
      padding-top: 2em;
    }
    .main-ctas {
      padding-top: 30px;
    }
  }
}

@media #{$mediaquery-large-desktop} {
  .page-event,
  .node-type-event {
    /**
     * Ensure the logo can't get cut off on shrunk desktop browsers.
     */
    .event-center-info-container {
      // padding-top: 62px; // Header height
    }
    .event-center-info {
      max-width: 440px;
    }
  }

  .page-event,
  .node-type-event {
    .old-event-layout {
      .event-center-info-container {
        padding-top: 2.5em;
      }
    }
  }
}

/**
 * Overrides for event teasers.
 */
.view-events .view-content {
  border-top: 1px solid $gray-light;
}

/**
 * Style calendar filters.
 */
.view-filters {
  padding-bottom: 60px;
  display: table;
  height: 60px;
}

.filter-by-text {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  float: none;
  padding-right: 10px;
}

@media #{$mediaquery-phone} {
  .filter-by-text {
    width: 100%;
    float: left;
  }
  form#views-exposed-form-events-panel-pane-events {
    .views-exposed-form {
      .views-exposed-widget {
        width: 100%;
      }
    }
  }
}
form#views-exposed-form-events-panel-pane-events,
form#views-exposed-form-events-panel-pane-events-archive,
.events-calendar.via-fpp {
  // display: inline;
  // float: left;
  #edit-tid-wrapper label {
    display: none;
  }
  .views-submit-button {
    display: none;
  }
  .views-exposed-form {
    .views-exposed-widget {
      width: 350px;
    }
  }

  .form-type-select {
    /**
     * Handling all selects through select2 javascript library.
     * The actual select needs to be hidden.
     */
    .select2-container {
      .select2-selection--single {
        padding: 18px;
        height: 60px;
        border: none;
        border-radius: 0;
        background: $brand-blue;
        font-weight: bold;
        .select2-selection__rendered {
          color: $brand-white;
          line-height: 25px;
        }
      }
    }
    .select2-container--default {
      .select2-selection--single {
        .select2-selection__arrow {
          width: 38px;
          height: 51px;
          b {
            border-color: $brand-white transparent transparent transparent;
            border-width: 12px 11px 0 10px;
            margin-left: -19px;
          }
        }
      }
    }
  }
}

.select2-dropdown {
  background: rgba($brand-blue, 0.9) !important;
  color: $brand-white;
  border: none !important;
  border-radius: 0 !important;
  .select2-search--dropdown {
    display: none !important;
  }
  .select2-results__option {
    padding: 10px 13px;
  }
  .select2-results__option[aria-selected='true'] {
    background: $brand-gray !important;
  }
}

@media #{$mediaquery-not-phone} {
  #views-exposed-form-events-panel-pane-events {
    display: inline;
    float: left;
  }
}

.listing {
  &.event-listing {
    border-right: 1px solid $brand-white;
    border-left: 1px solid $gray-light;
    color: $brand-white;
    height: 440px;
    .field-name-field-date {
      font-size: $font-size-h1 - 4px;
      font-weight: bold;
      color: $brand-white;
      text-transform: uppercase;
      line-height: 1em;
    }
    .element-invisible {
      display: none !important;
    }
    /**
     * Style overlay content.
     */
    .blur-overlay-popup-content {
      max-height: 91px;
      min-height: (91px * 0.5);
      overflow: hidden;
      margin-bottom: 15px;
      position: relative;
    }
    /**
     * Style logo.
     */
    .field-name-field-basic-image-image {
      @extend .bevel, .tr;
      background-color: $brand-white;
      margin-left: -15px;
      margin-right: -15px;
      padding: 0.7em 1.7em 0.7em 1.7em;
      img {
        max-height: 70px;
      }
    }
  }
}

#events-listings {
  padding-bottom: 0px;
  .pane {
    padding-bottom: 0px;
  }
}

@media #{$mediaquery-phone} {
  .listing {
    &.event-listing {
      .field-name-field-date {
        font-size: $font-size-h2 + 3px;
      }
    }
  }
}

@media #{$mediaquery-tablet} {
  .listing {
    &.event-listing {
      .field-name-field-date {
        font-size: $font-size-h2;
      }
    }
  }
}

@media #{$mediaquery-desktop} {
  .listing {
    &.event-listing {
      .field-name-field-date {
        font-size: $font-size-h2 - 1px;
      }
    }
  }
}

/**
 * Handle event subpage template.
 */
.no-cover {
  .event-cover,
  .event-header {
    display: none;
  }
}

/**
 * Handle styling for events tabs / events calendar.
 */
#events-calendar {
  padding-bottom: 0 !important;
}
.events-calendar {
  &--display-mode {
    &--grid,
    &--grid-simple {
      .target-sticky {
        display: none;
      }
    }
  }

  .pane {
    padding-bottom: 0;
  }
  .events-calendar-tabs-wrapper {
    /**
     * Should be in a container instead.
     */
    /*padding: 0 60px 30px;*/
    padding-bottom: 30px;
  }
  ul.events-calendar-tabs {
    border-bottom: 1px solid $gray;
    li {
      a {
        font-size: $font-size-h3;
        font-weight: bold;
        text-transform: uppercase;
        color: $gray;
        border-radius: 0;
        &:hover {
          color: $gray;
          background: none;
        }
      }
      &.active {
        border-bottom: 5px solid $brand-blue;
        a {
          background: none;
          color: $brand-blue;
        }
      }
    }
  }
  .has-tabs {
    .field-name-field-group-name {
      /**
       * Placeholder hook in case needed.
       */
    }
  }
}

/**
 * Card icons
 */

.payment-card-acceptance {
  margin-bottom: 20px;
  text-align: center;

  ul {
    margin: 20px 0;
    list-style-type: none;

    li {
      display: inline-block;
      margin: 0 10px;
    }
  }
}

@media #{$mediaquery-desktop}, #{$mediaquery-large-desktop} {
  .events-calendar {
    ul.events-calendar-tabs {
      li:last-child {
        a {
          text-align: right;
        }
      }
      li:first-child {
        a {
          text-align: left;
        }
      }
    }
  }
}

/**
 * Events, Panopoly Landing Pages, and Taxonomy term (categories) pages 
 * benefit from the ATF styling.
 */
.page-event,
.node-type-event,
.is-event-subpage,
.panopoly-landing-page,
.page-taxonomy-term {
  .btn-event {
    border: solid 1px $gray-dark;
    border-radius: 3px;
    padding: 6px 14px;
    font-size: 16px;

    &:hover,
    &:focus {
      background-color: #f3f3f3;
    }

    &__arrow {
      margin-left: 35px;
      font-size: 12px;
    }
  }

  .event-header {
    padding: 85px 0 0;
    position: relative;
    background-size: 200%;
    background-position: center top, center top;

    @media #{$mediaquery-not-phone} {
      background-size: cover;
      // margin-top: 62px; // Fixed header height
    }

    @media #{$mediaquery-not-phone} {
      padding: 85px 0 40px;
    }

    &__video {
      &-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        overflow: hidden;
        display: none;

        @media #{$mediaquery-not-phone} {
          display: block;
        }

        iframe {
          width: 100vw;
          height: 56.25vw; // Given a 16:9 aspect ratio, 9/16*100 = 56.25
          min-height: 100vh;
          min-width: 177.77vh; // Given a 16:9 aspect ratio, 16/9*100 = 177.77
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 1;
    }

    &.event-header-sponsor-stripe-plain::after {
      margin-top: 100px;
      margin-bottom: -100px;
      height: calc(100% - 100px);
    }

    img {
      max-width: 100%;
    }

    .btn {
      transition: 0.2s ease-out;
    }

    .date-display-range {
      display: inline-block;
    }

    &__inner {
      position: relative;
      z-index: 2;
    }

    &__tagline {
      margin-top: 0;
      margin-bottom: 15px;
      font-size: 24px;
      font-weight: 500;
      color: $brand-white;
      line-height: 1.2;

      @media #{$mediaquery-not-phone} {
        font-size: 26px;
      }
    }

    &__body {
      margin-bottom: 15px;
      max-width: 520px;

      p:last-child {
        margin-bottom: 0;
      }
    }

    &__title {
      margin: 0 0 10px;
      font-size: $font-size-small;
      font-weight: 100;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $brand-white;
      &.larger {
        font-size: 28px;
        font-weight: 500;
        color: $brand-white;
        line-height: 1.2;

        @media #{$mediaquery-not-phone} {
          font-size: 38px;
        }
      }
    }

    &__intro {
      padding-top: 40px;
      margin-bottom: -20px;
      color: $brand-white;

      @media screen and (min-width: 992px) {
        max-width: 750px;
      }

      &--center {
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        .event-header__body {
          margin-left: auto;
          margin-right: auto;
        }
      }

      @media #{$mediaquery-not-phone} {
        margin-bottom: 85px;
      }
    }

    &__info {
      margin: 0 -15px;
      padding-top: 30px;
      background-color: $brand-white;

      @media #{$mediaquery-not-phone} {
        display: flex;
        flex-wrap: wrap;
        // margin: 0 -20px;
        margin: 0;
        padding: 0;
      }

      &-col {
        padding: 20px 0;
        margin: 0 20px;
        font-size: 16px;

        @media #{$mediaquery-not-phone} {
          margin: 20px 0;
          padding: 0 20px;
          display: flex;
          flex-direction: column;
          flex: 1 0 50%;
        }

        @media screen and (min-width: 992px) {
          flex: 1;
        }

        &:nth-child(3) {
          @media #{$mediaquery-tablet} {
            padding: 20px 0;
            margin: 0 20px;
            border-top: solid 2px $gray-light;
          }
        }

        &:not(:last-child) {
          @media #{$mediaquery-not-phone} {
            border-right: solid 2px $gray-light;
          }

          @media #{$mediaquery-phone} {
            border-bottom: solid 2px $gray-light;
          }
        }

        &__top {
          margin-bottom: 15px;

          @media #{$mediaquery-not-phone} {
            margin-bottom: 20px;
          }
        }

        &__bottom {
          margin-top: auto;
        }
      }
    }

    &__logo {
      max-width: 100%;
      margin-bottom: 20px;
    }

    &__location {
      margin-bottom: 5px;
    }

    &__location,
    &__date {
      letter-spacing: 1px;
      text-transform: uppercase;

      .fa {
        width: 20px;
        text-align: center;
        font-size: 20px;

        @media #{$mediaquery-not-phone} {
          font-size: 22px;
        }
      }
    }

    &__video {
      position: relative;
      display: block;
      color: #fff;
      min-height: 1px;

      &__thumb {
        position: relative;

        &::after {
          width: 100%;
          height: 100%;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.4);
        }
      }

      &__play {
        position: absolute;
        bottom: 15px;
        left: 15px;
        font-size: 16px;

        .fa {
          margin-right: 5px;
        }
      }
    }
  }
}

/**
 * Tweak events calendar to show the right hover color.
 */
.blur-overlay-cta a.btn:hover {
  color: $brand-blue !important;
}

.target-primary-partners,
.target-secondary-partners {
  .uk-card {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .is-first {
    margin-left: 15px;
  }
  .last-of-group {
    margin-right: 15px;
  }
  .uk-card {
    margin-right: 15px;
  }
  .view-all-sponsors-tab {
    margin-top: 9.5px;
    border-radius: 0 0 15px 15px;
    padding: 5px 15px 5px 20px;
    a {
      text-decoration: none;
      color: white;
      font-size: 12px;
    }
  }
  .uk-slidenav {
    margin-top: 35px;
  }
  h6 {
    margin-bottom: 3px;
  }

  &--plain {
    .uk-card-default {
      box-shadow: none;
    }

    .shell {
      margin-bottom: -4px !important;
    }
  }

  &--inverted {
    .uk-card-default {
      background: none;
      padding: 0;
      box-shadow: none;
      img {
        @include inverted-logo;
      }
    }
    .view-all-sponsors-tab {
      display: none !important;
    }
    .is-first {
      margin-left: 0;
    }
  }
}

.target-primary-partners {
  &--plain {
    h6 {
      small {
        strong {
          color: #555 !important;
        }
      }
    }
  }
}
.sponsor-background-strip {
  height: 150px;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  border-top: 1px solid #999;
  &--plain {
    background: white !important;
    height: 100px;
  }
  &--inverted {
    background: none !important;
  }
}

@media screen and (max-width: 639px) {
  .sponsor-background-strip {
    opacity: 0;
  }
  .view-all-sponsors-tab {
    opacity: 0;
  }
}

@media screen and (max-width: 959px) {
  .sponsor-background-strip {
    height: 221px;
  }
}
@media screen and (min-width: 960px) and (max-width: 1199px) {
  .sponsor-background-strip {
    height: 158px;
  }
}

/**
 * Event headers use uk-sticky which doesn't need to compensate for the
 * menu pushing down the header. But portfolio pages don't use it,
 * so we need to manually add the height of the header to the ATF module.
 */
.page-taxonomy-term,
.panopoly-landing-page:not(.is-event-subpage) {
  .event-header {
    @media #{$mediaquery-not-phone} {
      margin-top: 62px; // Fixed header height
    }
  }
}

/**
 * Small fix for img pixels that bump the entire event site down.
 */
body.node-type-event > img[width='1'][height='1'] {
  position: absolute;
}

/**
 * Fix loading spinner sometimes showing randomly on the page.
 */
.fieldable-panels-pane-events-calendar {
  position: relative;
}

/**
 * If only a logo is added to the above the fold module, make sure it doesn't span
 * the entire width of the page.
 */
.event-header__info-col__top {
  max-width: 500px;
}

.fieldable-panels-pane-on-demand-events-purchase-block {
  .kisaco-events {
    &--date-column {
      display: none;
    }
  }
}
