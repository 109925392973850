/**
 * Overrides for q-tip popup styles
 */

.qtip {
  font-size: $font-size-small;
  line-height: $line-height-base;
}
.content-match-pane {
  .media {
    padding-top: 10px;
    border-top: 1px solid $gray-light;
  }
  img.media-object {
    width: 100px;
    height: auto;
  }
  .qtip-tip {
    display: none !important;
  }
  h3 {
    font-size: $font-size-h6;
    margin-top: 5px;
  }
  ul {
    margin-left: 0;
    padding-left: 0;
    li {
      margin-left: 2px;
      font-size: $font-size-h6;
      padding: 5px;

    }
  }
  .name a {
    color: $brand-blue !important;
  }
}

.content-match-results {
  font-weight: normal;
  .warning {
    color: red;
  }
}

.annotated-field {
  i.fa {
    margin: 4px 10px;
  }
}