/**
 * Extends vanillatoasts (toaster popup) styles.
 */
#vanillatoasts-container {
  z-index: 9000;
  font-family: inherit;
  width: 400px;
  p {
    margin-bottom: 10px !important;
  }
  
} 
.vanillatoasts-toast {
  cursor: default;
  border-radius: 3px;
  
  .vanillatoasts-dismiss {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    padding-top: 0;
    font-size: 22px;
    color: black;
    cursor: pointer;
    z-index: 9001;
  }

}
.vanillatoasts-info {
  border-bottom-color: $brand-blue;
}
.vanillatoasts-text {
  height: 100%;
  max-height: 344px;
  overflow: scroll;
}