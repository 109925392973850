// variable for headshot width
$headshot-width: 200px;
$headshot-gap: 20px;

body.node-type-founder-journey-profile {
  .field-name-field-video-intro {
    margin-top: 6rem;
    font-weight: bold;
  }
  .vimeofield-embed {
    margin-top: 1rem;
    margin-bottom: -5rem;
  }
  .submitted {
    padding-bottom: 2rem;
    font-size: 1.6rem;
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 2rem;
    text-align: right;
    font-weight: bold;
    @media screen and (max-width: 767px) {
      text-align: center;
      padding-top: 2rem;
    }
  }
  #main-wrapper {
    padding-top: 0;
  }
  .journey-profile.content {
    @media screen and (min-width: 768px) {
      padding-top: 80px;
    }
  }
  .cover-header {
    padding-top: 100px;
    justify-content: center;
  }
  .portfolio-title h1 {
    color: white;
    font-weight: bold;
  }
  .headshot {
    display: flex;
    gap: 2rem;
    @media screen and (min-width: 768px) {
      position: absolute;
      z-index: 1;
      margin-top: -100px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 6rem;
      text-align: center;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2rem;
    }
    img {
      border-radius: 1000px;
      width: 200px;
      height: 200px;
      object-fit: cover;
      border: 3px solid #f2f2f2;
    }
  }
  .founder-info {
    @media screen and (min-width: 768px) {
      margin-top: 10px;
      margin-left: calc(
        ($headshot-width + $headshot-gap) * var(--headshot-count)
      );
    }
    @media screen and (max-width: 767px) {
      text-align: center;
    }
    p {
      margin-bottom: 0.2rem;
    }
    .tag {
      border-radius: 1000px;
      background-color: $brand-info;
      color: white;
      font-size: 1.6rem;
      font-weight: bold;
      padding: 0.3rem 2rem;
      margin-top: 0;
      margin-left: -5px;
    }
  }
  .company-description {
    color: $brand-blue;
  }
  .company-logo {
    padding-bottom: 2rem;
  }
  .company-url {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    .fa-link {
      color: $brand-blue;
    }
  }
  .company-stats {
    hr {
      margin-top: 0;
      margin-bottom: 0;
    }
    position: relative;
    .icon {
      position: absolute;
      width: 50px;
      top: -2.6rem;
      left: 3rem;
    }
    padding-top: 2.5rem;
    margin-top: 6rem;
    &.top {
      margin-top: 2rem;
    }
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding-bottom: 1rem;
    .stat-inner {
      padding: 2rem 4rem;
      &.cta {
        .btn {
          background-color: $brand-blue !important;
          color: white !important;
          padding: 1rem 2rem;
          font-size: 1.2rem;
          font-weight: bold;
          width: 100%;
        }
      }
      &.main-cta {
        .btn {
          font-weight: bold;
          font-size: 1.2rem;
          width: 100%;
          padding: 1rem 2rem;
        }
      }
    }
  }
  .cta {
    text-align: center;
    a {
      &.btn {
        background-color: $brand-blue !important;
        color: white !important;
        border-radius: 5px;
        padding: 1rem 2rem;
        font-size: 1.2rem;
        font-weight: bold;
        width: 100%;
      }
    }
    p.small {
      font-size: 1.5rem;
      color: $brand-blue;
      margin-top: 1rem;
      a {
        text-decoration: underline;
      }
    }
  }
  blockquote {
    position: relative;
    &::before {
      content: '';
      background-image: url(/sites/all/themes/kisaco/assets/images/k-quote.png);
      background-repeat: no-repeat;
      background-size: 53px;
      font-size: 15rem;
      color: $brand-blue;
      position: absolute;
      left: 3.7rem;
      top: 3rem;
      height: 53px;
      width: 53px;
    }
    color: $brand-blue;
    background-color: rgba($brand-blue, 0.15);
    border-left: none;
    font-weight: bold;
    font-style: normal;
    font-size: 1.9rem;
    max-width: 270px;
    float: left;
    margin-top: 2rem;
    margin-right: 3rem;
    margin-left: -4rem;
    padding: 3.5rem;
    padding-top: 8.5rem;
    @media screen and (max-width: 768px) {
      float: none;
      margin: 0 auto;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
  .more-profiles {
    padding-bottom: 3rem;
    .uk-card-media-top img {
      height: 180px;
      object-fit: cover;
    }
    .card-type {
      font-size: 1.2rem;
      font-weight: bold;
      color: $brand-gray;
      text-transform: uppercase;
    }
    .card-info {
      color: $brand-blue;
    }
    .uk-card-footer {
      border-top: none;
      a {
        border: 2px solid $brand-blue;
        font-size: 1.5rem;
        padding: 0.3rem 1.5rem;
      }
    }
  }
  div[data-autoload-id] {
    .stat-inner.highlight {
      background-color: #f2f2f2;
    }
    .label {
      color: $brand-blue;
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 100;
      padding-left: 0;
      margin-left: 0;
      &::before {
        // font-awesome building icon
        font-family: fontawesome;
        content: '\f0f7';
        font-size: 1.2rem;
        margin-right: 0.5rem;
        margin-left: 0;
        padding-left: 0;
      }
    }
    .value {
      font-size: 1.8rem;
      font-weight: bold;
      color: $brand-blue;
      // if the parent element has a class of "stat-leadership"...
    }
    .stat-leadership {
      .leadership-bundle {
        display: flex;
        gap: 5px;
        align-items: center;
      }
      .value {
        font-weight: normal;
        .fa {
          color: $brand-blue;
          font-size: 1.2rem;
        }
      }
    }
  }
}

.node-founder-journey-profile {
  &.node-view-featured,
  &.node-view-teaser,
  &.node-view-teaser-alternate {
    padding: 2rem;
    @media screen and (min-width: 768px) {
      padding: 7rem;
    }
    h2 {
      padding-top: 0;
      font-weight: 800;
    }
    background: linear-gradient(
        240deg,
        rgba($brand-blue, 0.3) 0%,
        rgba($brand-blue, 0.6) 30%,
        rgba($brand-blue, 1) 70%,
        rgba($brand-blue, 1) 100%
      ),
      var(--background-image);
    background-size: cover, cover;
    background-position: center center, right center;
    // box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
    .content-inner {
      .field-name-field-company-logo {
        img {
          filter: contrast(50%) grayscale(100%) brightness(20);
          max-height: 100px;
          width: auto;
        }
      }
      @media screen and (width > 768px) {
        display: flex;
        gap: 8rem;
        .client-info {
          .node-speaker {
            text-align: center;
            .field-label {
              display: none;
            }
            .pane-content {
              padding-bottom: 0;
            }
            .field-name-field-featured-image {
              img {
                border-radius: 100rem;
                border: 1px solid white;
                max-width: 200px;
              }
            }
            .field-name-field-linkedin-url {
              display: none;
            }
            h2 {
              &::before {
                content: 'With';
              }
              font-size: $font-size-h4;
              text-align: center;
            }
            .field-name-field-featured-image {
              img {
                border-color: white;
              }
            }
          }
        }
      }
      .field-name-body,
      .field-name-field-company-description {
        font-size: 2rem;
        padding-bottom: 2rem;
        @media screen and (width < 768px) {
          text-align: center;
        }
      }
      * {
        color: white;
      }
      .cta a {
        border-color: white !important;
        color: white !important;
        &:hover {
          background-color: white;
          color: $brand-blue;
        }
      }
      h2 {
        @media screen and (width < 768px) {
          font-size: $font-size-h5;
        }
        @media screen and (width > 768px) {
          text-align: left;
        }
      }
      .field-name-field-key-statistic {
        .field-item {
          @media screen and (width > 768px) {
            padding-left: 0 !important;
          }
        }
        container-type: normal;
        .content {
          padding-top: 3rem;
          @media screen and (width > 768px) {
            i.fa {
              margin-left: 5px;
            }
            .field-name-field-description {
              padding-left: 0;
              text-align: left;
            }
            .field-name-field-description .field-item {
              margin: 0;
            }
            text-align: left;
          }
        }
      }
      .fa {
        font-size: 2.6em;
      }
    }
  }
}
