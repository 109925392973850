$vh: 100vh;
$margin-offset-top: 0;

.video-cover-adjust {
  min-height: $vh;
  margin-top: $margin-offset-top;
  position: relative;
  &::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    height: $vh;
    width: 100vw;
    margin-top: $margin-offset-top;
    z-index: 1;
    left: 0;
    pointer-events: none;
  }
  iframe {
    transform: translate(-50%, -50%) scale(1.15);
  }
}