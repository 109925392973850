footer#footer {
  box-shadow: 0 50vh 0 50vh var(--theme-footer-background-color);

  a.btn-default {
    background-color: var(--theme-footer-background-color);
    &:hover {
      background-color: $brand-white;
      color: var(--theme-footer-background-color);
    }
  }

  /**
   * Default (fallback) background color for the footer.
   * This gets overridden by kisaco.style.css.php (or
   * kisaco-events.style.css.php) contextually.
   */
  background-color: var(--theme-footer-background-color);
  * {
    color: $brand-white;
  }

  border-top: 1px solid $brand-white;

  .kisaco-footer {
    ul * {
      border-color: rgba($brand-white, 0.3);
    }

    // background-color: $brand-blue;
    // * {
    //   color: $brand-white;

    //   /**
    //     * Override UIkit's standard border color.
    //     */
    // }

    margin-top: 0;
    h3 {
      font-weight: bold;
      color: $brand-white;
      text-decoration: uppercase;
      margin-bottom: 30px;
    }

    &--links {
    }

    &--contact {
      font-size: 13px;
      font-weight: bold;

      .social-media-icons {
        margin-bottom: 60px;
        margin-top: 20px;
        .icon-circular {
          margin-right: 1em;
          display: inline-block;
          width: 64px;
          height: 64px;
          border-radius: 1000px;
          background: $brand-white;
          margin: 15px 20px 0 0;
          @include transition(background-color 0.125s);
          text-align: center;
          .fa {
            margin-top: 20px;
            font-size: 26px !important;
            color: var(--theme-footer-background-color);
          }
          &:hover {
            background: $gray-light !important;
            text-decoration: none;
          }
          svg,
          i {
            display: block;
            // fill: $brand-blue;
            // color: $brand-blue;
            margin-top: 25%;
          }
        }
      }
    }

    &--about {
      font-size: 13px;

      li {
        display: inline-grid;
        grid-template-columns: 90px auto;

        .icon {
          grid-column: 1;
          text-align: center;
          * {
            color: $brand-white;
          }
        }

        .content {
          grid-column: 2;
        }
      }
    }
  }
}

.page-event {
  footer {
    a.btn-default {
      border: 2px solid $brand-white;
    }
  }
}

/**
 * Footer should be centered.
 */
.copyright {
  text-align: center;
}

/**
 * Footer styling.
 */

#footer {
  margin-bottom: 0;
  margin-top: 0;
  padding: 30px 0 20px;
}

.footer {
  // color: #1D3459;
}

.footer-nav {
  text-align: center;
}

.footer-nav ul {
  padding-left: 0;
}

.footer-nav li {
  display: inline;
}

.footer-nav li + li::before {
  content: ' | ';
}

.footer-nav li a {
  text-transform: lowercase;
}

/**
 * Fix footer nav so it doesn't display each link as block.
 */
.footer-nav .nav > li > a {
  display: inline;
  padding: 10px 6px;
}

.pane-kisaco-global-kisaco-research-social-media {
  h4 {
    font-weight: bold;
  }
}
@media #{$mediaquery-not-desktop} {
  .pane-kisaco-global-kisaco-research-social-media {
    text-align: center;
  }
  .icon-circular {
    margin-right: 10px;
    margin-left: 10px;
  }
}
