// Helpers
// -----------------------------------------------------------------------------

:root {
  --group-count-first-of-eight: 0;
}

@media screen and (min-width: 1200px) {
  .uk-child-width-1-8\@l>* {
    width: calc((100% * 1 / 8.001) - ((1/8 * 15px) * var(--group-count-first-of-eight)));
  }
}

.uk-list-inline {
  margin: 0;
  padding-left: 0;
  > li {
    list-style: none;
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }
}