$speaker-overlap: 15px;

.kisaco-module-webinars {
  .uk-card-media-top {
    &.kisaco-ondemand {
      img {
        width: 80%;
        height: 200px;
        object-fit: contain;
        object-position: center center;
        padding: 40px;
        display: block;
        margin: 0 auto;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  .uk-card-body {
    padding-top: 20px;
  }
  .speakers {
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    // justify-content: center;
    // margin-left: -$speaker-overlap;
    img {
      margin-right: -$speaker-overlap;
      border: 2px solid $brand-white;
      min-width: 60px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    &.featured {
      margin-bottom: 20px;
    }
  }
  .sponsors {
    img {
      width: 80px;
    }
    &.featured {
      img {
        width: 120px;
      }
    }
  }
  .title-sponsor {
    .uk-width-auto {
      padding-left: 10px;
    }
  }
  .uk-icon svg {
    margin-top: -4px;
    margin-left: 7px;
  }
  .btn.register-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    span {
      margin-top: 3px;
    }
  }
}

/**
 * If a webinar is shown in an inverted pane, the header will blend with the
 * background. Add a white border around it so it's distinct.
 */
.inverted-pane {
  .kisaco-module-webinars {
    .uk-card-header {
      border: 2px solid $brand-white;
      border-bottom: none;
    }
  }
}
