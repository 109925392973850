// Mixins
// -----------------------------------------------------------------------------

@mixin transform-style($style) {
  -webkit-transform-style: $style;
  -moz-transform-style: $style;
  -ms-transform-style: $style;
  transform-style: $style;
}

@mixin transform($style) {
  -webkit-transform: $style;
  -moz-transform: $style;
  -o-transform: $style;
  -ms-transform: $style;
  transform: $style;
}

/**
 * ATF logo sizing.
 */
$max-atf-logo-width: 300px;
$max-atf-logo-height: auto;

@mixin atf-logo-size {
  object-fit: contain;
  max-width: $max-atf-logo-width;
  max-height: $max-atf-logo-height;
}

@mixin smaller-menu {
  padding-right: 20px;
  padding-left: 60px;
  .nav > li {
    a {
      padding-right: 12px;
      padding-left: 12px;
      font-size: 16px;
      // line-height: 16px !important;
      white-space: nowrap;
    }
    > a {
      padding-top: 16px;
    }
  }

  /**
   * Animal Health, Nutrition & Technology
   * is the longest portfolio menu item we have.
   * Not really much we can do except apply a special
   * hack for edge browsers.
   */
  .nav > li.menu-link-animal-health-nutrition--technology {
    a {
      font-size: 14px;
    }
    & ~ li {
      a {
        font-size: 14px;
      }
    }
  }
}

@mixin inverted-logo {
  filter: contrast(50%) grayscale(100%) brightness(20);
}

@mixin generic-tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
  margin-top: 0.6rem;
}

@mixin generic-tag {
  border-radius: 100px;
  background-color: $gray-light;
  padding: 0.2rem 1rem;
  font-size: 1rem;
  font-weight: normal;
  // white-space: nowrap;
}

@mixin filterset-button {
  width: 100%;
  height: 100%;

  padding-left: 20px;
  padding-right: 20px;

  font-size: 16px;
  font-weight: bold;
  color: var(--base-color);
  text-align: left;
  border: 1px solid var(--base-color);

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;

  &::after {
    content: '\F107';
    font-family: 'FontAwesome';
    font-size: 3rem;
    width: 20px;
    height: 20px;
    text-align: right;
    // border: 1px solid black;
    line-height: 18px;
    text-align: center;
    // transition: all 0.2s;
    transform-origin: center center;
  }
  &[aria-expanded='true'] {
    &::after {
      transform: rotate(180deg);
    }
  }
}

@mixin caption {
  background: none;
  border: 0;
  padding: 0;
  text-align: left;
  line-height: 1.3;
  font-size: 1.4rem;
}
