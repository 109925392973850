.events-calendar.via-fpp {

  .uk-list-inline > li {
    list-style: none;
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }

  .filter-set {
    select {
      width: 100%;
    }
    .uk-search {
      .uk-search-input.uk-form-large {
        border: 1px solid $brand-blue;
        /**
         * Match the height of select2 dropdowns.
         */
        height: 60px;
        padding-left: 20px;
        padding-right: 20px;
      }
      .uk-search-icon {
        width: 50px;
      }
    }

    .pseudo-select {
      display: none;
    }
    
  }

  &.events-calendar--display-mode--grid {
    .calendar-tab-content {
      background-color: $brand-blue;
    }
  }

  .target-sticky {
    .uk-margin-large {
      padding-bottom: 15px;
      border-bottom: 1px solid $brand-gray;
      margin-bottom: 30px;
    }
  }

  .events-calendar-display {

    .tag {
      text-transform: uppercase;
      font-size: 1.2rem;
      letter-spacing: 2px;
      padding: 3px 10px;
      background-color: $gray-light;
      color: $brand-white;
    }

    .btn {
      &.btn-primary {
        background-color: $brand-blue !important;
        color: $brand-white !important;
      }
    }

    &--grid, &--grid-simple {

      .is-flagship-event,
      .is-sticky {
        .uk-card {
          background-color: $sticky;
        }
      }

      .date {
        .year {
          color: $gray;
          font-weight: 100;
        }
      }
      .uk-card {
        border: 1px solid lighten($gray, 25%);
      }

      .uk-card-header {
        background-color: $brand-white;

        .tag {
          border-radius: 0 0 0 10px;
        }
      }

      .uk-card-body {
        .tag {
          border-radius: 4px 0 10px 0;
        }
      }

      .uk-card-header,
      .uk-card-body {
        // &.has-logo {
        //   padding-top: 20px;
        // }
        padding-top: 30px;
        padding-bottom: 20px;

        &:not(.has-logo) {
          h4 {
            padding-top: 10px;
          }
        }

        .logo-wrapper {
          height: 80px;
          img {
            max-height: 80px;
            margin-top: -15px;
          }
        }

      }

      .uk-card-footer {
        padding-bottom: 40px;

        .btn {
          font-size: 1.1rem;
          padding: 2px 8px !important;
        }
      }

    }

    &--grid {

      .month-heading {
        // h1, h2, h3, h4, h5, h6 {
        //   color: $brand-white;
        // }
      }

      .icons {
        padding-top: 20px;
      }

      .flagship-marker {
        background-color: $brand-white;
        margin-bottom: -20px;
        padding: 0.5rem 1rem;
        border-radius: 15px;
        font-weight: 900;
        letter-spacing: 2px;
        color: $brand-blue;
      }

      .title {
        a {
          color: $brand-white;
        }
      }

      .date {
        text-align: left;
        padding-top: 0;
        font-size: 23px;
        .year {
          color: $brand-white;
        }
      }

      .action-buttons {
        .btn {
          transition: all 0.4s !important;
          font-size: 1.3rem;
        }
        .btn-primary {
          border-color: $brand-white !important;
          color: $brand-blue !important;
          background-color: $brand-white !important;
          &:hover {
            background-color: $brand-blue !important;
            color: $brand-white !important;
            border-color: $brand-blue !important;
          }
        }
        .btn-default {
          border-color: $brand-white !important;
          color: $brand-white !important;
        }
      }

    }

    &--list, &--list-simple {

      .uk-list-divider {
        /**
         * UIkit lists skip the first border at the top of the list, but
         * we actually want a border always.
         */
        border-top: 1px solid #e5e5e5;
      }

      .logo-wrapper {
        max-width: 265px;
        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
        @media screen and (min-width: 767px) {
          height: 100px;
        }
        img {
          max-height: 100px;
          margin-top: -15px;
          @media screen and (max-width: 768px) {
            margin: 15px 0;
          }
        }
      }

      .target-headline-partners {
        a {
          &:nth-child(n+2) {
            margin-left: 10px;
          }
        }
        img {
          background-color: white;
          padding: 7px 15px;
          border: 1px solid $gray-light;
          max-height: 70px;
        }
      }

    }

    &--list-simple {

      .tag {
        border-radius: 0 0 10px 0;
      }

      .is-flagship-event,
      .is-sticky {
        background-color: $sticky;
        border-bottom: 10px solid white;
        padding-bottom: 20px;
      }
    }

    &--list {

      .uk-list li {
        @media screen and (min-width: 640px) {
          padding-bottom: 12px;
        }
      }

      .background-wrapper-list {
        min-height: 280px;
      }

      .title a,
      .desc,
      .date {
        color: $brand-white;
      }

      .year {
        font-weight: 300;
      }

      .tag {
        border-radius: 0 0 10px 0;
      }

      .flagship-marker {
        background-color: $brand-white;
        border-radius: 0 0 0 20px;
        text-transform: uppercase;
        font-size: 1.2rem;
        letter-spacing: 2px;
        padding: 5px 20px 12px;
      }

      .action-buttons {
        .btn {
          transition: all 0.4s !important;
          font-size: 1.3rem;
        }
        .btn-primary {
          border-color: $brand-white !important;
          color: $brand-blue !important;
          background-color: $brand-white !important;
          &:hover {
            background-color: $brand-blue !important;
            color: $brand-white !important;
            border-color: $brand-blue !important;
          }
        }
        .btn-default {
          border-color: $brand-white !important;
          color: $brand-white !important;
        }
      }

      @media screen and (max-width: 767px) {}
      @media screen and (min-width: 768px) {}
      @media screen and (max-width: 400px) {}
      @media screen and (min-width: 401px) and (max-width: 767px) {}
      @media screen and (min-width: 768px) and (max-width: 991px) {}
      @media screen and (min-width: 992px) and (max-width: 1199px) {}
      @media screen and (min-width: 1200px) {}
      /* UK breakpoints */
      @media screen and (max-width: 639px) {
        .logo-wrapper {
          border-radius: 20px;
          background-color: $brand-white;
          padding: 20px;
        }
      }
      @media screen and (min-width: 640px) {}
      @media screen and (min-width: 640px) and (max-width: 959px) {}
      @media screen and (min-width: 960px) and (max-width: 1199px) {}
      @media screen and (min-width: 1200px) and (max-width: 1599px) {}
      @media screen and (min-width: 1600px) {}

    }

  }

  /**
   * Hide all sponsor buttons on "Past" tab.
   */
  .target-past {

    .action-buttons {
      &--reg-button,
      &--sponsor-button {
        display: none;
      }
    }
  }

}

/**
 * Enables pale yellow background color for featured events on list views.
 */
.target-sticky {
  .events-calendar--display-mode--list &,
  .events-calendar--display-mode--list-simple & {
    .events-calendar-display {
      background-color: $sticky;
      padding: 20px 20px 0;
      border-bottom: none !important;
    }
  }
}

@media screen and (min-width: 768px) {
  .md-pr-0 {
    padding-right: 0;
    margin-right: -30px;
  }
}