/**
 * Define the default tile height.
 */
$tile-height: 300px;

/**
 * Define the color variations we'll be rotating through every 5 tiles.
 */
$tile-colors: (
  "1": #9DAEC9,
  "2": #C1D0E8,
  "3": #1D3459,
  "4": #EDF0F4,
  "5": #D0DAEA,
);

/**
 * Tile grid is structured as follows:
 * 
 * <section class="tile-grid">
 *   <div class="tile-set">
 *     <div class="tile tile-image">[image]</div>
 *     <div class="tile-content">[content]</div>    
 *   </div>
 *   <div class="tile-set">...</div>
 *   <div class="tile-set">...</div>
 *   <div class="tile-set">...</div>
 * </section>
 * 
 */
.tile-grid {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100vw;
  .tile-set {
    display: flex;
    flex: 1 1 50%;
    
    @media (max-width: 767px) {
      flex: 1 1 100%;
      flex-direction: column;
    }
    align-items: flex-start;
    
    .tile {
      flex: 1 1 25%;
      overflow: hidden;
      
      @media (max-width: 767px) {
        flex: 1 1 100%;
        flex-direction: column;
        min-width: 100%;
        overflow: visible;
      }
      height: $tile-height;
      max-height: $tile-height;
      &.tile-image img {
        min-width: 100%;
        height: $tile-height;
        object-fit: cover;
      }
      &.tile-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 40px;
      }
      &:nth-child(3) {
        display: none;
      }
    }
    
    /**
     * The first tile set should span 100% the flex wrap width, and its first
     * tile should be twice the size of any other tile (50%).
     */
    &:first-child {
      flex: 1 1 100%;
      .tile {
        &:first-child {
          flex: 1 1 50%;
        }
        &:nth-child(3) {
          display: block;
        }
      }
    }
    
    /**
     * Every 4th and 5th tiles should have its direction reversed.
     */
    &:nth-child(4n), &:nth-child(4n+5) {
      flex-direction: row-reverse;
    }
    
    /**
     * Loop through each color variation every 5 tiles.
     */
    @each $key, $color in $tile-colors {
      &:nth-child(5n+#{$key}) {
        background: $color;
      }
    }
    
    /**
     * For the one color that's dark blue, 
     * make the tile content white instead.
     */
    &:nth-child(5n+3) {
      .tile-content {
        * {
          color: $brand-white;
        }
        .btn {
          background-color: $brand-white !important;
          color: $brand-blue !important;
          border: 2px solid $brand-white !important;
          &:hover, &:active, &:focus {
            background-color: transparent !important;
            color: $brand-white !important;
          }
        }

      }
    }
    
  }

  /**
   * Additional style fixes needed for Drupal.
   */
  .field-name-field-url {
    margin-top: 15px;
    .btn-primary {
      background-color: $brand-blue !important;
      color: $brand-white !important;
      transition: all 0.3s;
      &:hover, &:active, &:focus {
        background-color: transparent !important;
        color: $brand-blue !important;
      }
    }
  }

  /**
   * Ensure FC admin links are still visible and don't screw up the layout.
   */
  .field-collection-view-links {
    position: absolute;
    li a {
      background: white;
    }
  }

}

/**
 * Remove the bottom margin that field collection containers
 * usually have.
 */
.fieldable-panels-pane-tile-grid {
  .field-collection-container {
    margin-bottom: 0;
  }
}