/**
 * Display styles for registration pane.
 */
@mixin date-display-styles($content) {
  display: flex;
  border-radius: 2px;
  border: 2px solid red;
  flex-direction: column;
  text-align: center;
  font-weight: bold;
  padding: 10px;
  color: red;
  &:before {
    content: $content;
  }
}
.field-collection-item-field-package {
  .date-display-range {
    @include date-display-styles('This listing only appears from ');
  }
  .date-display-single {
    @include date-display-styles('This listing only appears after ');
  }
}

/**
 * Hide the date range from the public.
 */
body:not(.logged-in) {
  .field-collection-item-field-package {
    .date-display-range {
      display: none !important;
    }
  }
}
