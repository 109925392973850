// variable for headshot width
$headshot-width: 200px;
$headshot-gap: 20px;

body.node-type-case-study,
.node-case-study {
  .case-study-main {
    line-height: 1.6;
  }

  .case-study-header {
    .pane {
      padding-bottom: 15px;
    }
  }

  .case-study-sidebar {
    &:has(.field) {
      > div {
        // box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        @media screen and (width > 767px) {
          border-left: 1px solid #e6e6e6;
          padding: 5rem;
        }
      }
    }
  }

  .row-1 {
    margin-top: 5rem;
  }

  .field-name-field-speakers {
    .p-t-md {
      padding-top: 0 !important;
    }
  }

  .node-speaker {
    .inside.panels-flexible-region-inside {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      > div:nth-child(1) {
        order: 3; /* Ashley Kalinauskus */
      }

      > div:nth-child(1) {
        order: 2; /* [headshot] */
      }

      > div:nth-child(2) {
        order: 4; /* Founder and CEO */
      }

      > div:nth-child(3) {
        order: 5; /* Torigen, Inc. */
      }

      > div:nth-child(4) {
        order: 1; /* [logo] */
      }

      // for any children after the 4th, just hide them completely.
      > div:nth-child(n + 7) {
        display: none;
      }

      .field-name-field-company {
        display: none;
      }
    }
    h2 {
      &::before {
        content: 'With';
      }
      padding-top: 0;
      margin: 0;
      font-size: $font-size-h4;
    }
    .field-label {
      display: none;
    }
    .field-name-field-featured-image {
      text-align: center;
      img {
        border-radius: 100rem;
        border: 1px solid var(--kisaco-primary-color);
        max-width: 200px;
      }
    }
    .field-name-field-company-logo {
      img {
        max-height: 100px;
        width: auto;
      }
    }
    .pane-entity-field {
      text-align: center;
      .pane-content {
        padding-bottom: 0 !important;
      }
    }
    .field-name-field-linkedin-url,
    .field-name-field-featured-categories {
      display: none;
    }
  }
  .interstitial,
  .case-study-header {
    &:has(.field) {
      padding-top: 5rem;
      padding-bottom: 5rem;
      background-color: #f2f2f2;
    }
    .field-name-field-client-feedback {
      font-size: clamp(2.5rem, 4vw, 4rem);
      text-align: center;
      color: var(--kisaco-primary-color);
    }
  }
  .field-name-field-basic-image-image {
    text-align: center;
    .panopoly-image-full {
      border: 1px solid #f2f2f2;
    }
  }
  .field-name-field-video-intro {
    margin-top: 6rem;
    font-weight: bold;
  }
  .vimeofield-embed {
    margin-top: 1rem;
    margin-bottom: -5rem;
  }
  .submitted {
    padding-bottom: 2rem;
    font-size: 1.6rem;
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 2rem;
    text-align: right;
    font-weight: bold;
    @media screen and (max-width: 767px) {
      text-align: center;
      padding-top: 2rem;
    }
  }
  #main-wrapper {
    padding-top: 0;
  }
  .case-study.content {
    @media screen and (min-width: 768px) {
      padding-top: 80px;
    }
  }
  .cover-header {
    padding-top: 100px;
    justify-content: center;
  }
  .portfolio-cover-content {
    padding-top: 5rem;
  }
  .portfolio-title {
    .content-type {
      margin-top: 2rem;
      margin-bottom: 0;
      color: white;
      font-weight: bold;
    }
    h1 {
      color: white;
      font-weight: bold;
      margin-top: 0;
    }
  }
  .headshot {
    display: flex;
    gap: 2rem;
    @media screen and (min-width: 768px) {
      position: absolute;
      z-index: 1;
      margin-top: -100px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 6rem;
      text-align: center;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2rem;
    }
    img {
      border-radius: 1000px;
      width: 200px;
      height: 200px;
      object-fit: cover;
      border: 3px solid #f2f2f2;
    }
  }
  .founder-info {
    @media screen and (min-width: 768px) {
      margin-top: 10px;
      margin-left: calc(
        ($headshot-width + $headshot-gap) * var(--headshot-count)
      );
    }
    @media screen and (max-width: 767px) {
      text-align: center;
    }
    p {
      margin-bottom: 0.2rem;
    }
    .tag {
      border-radius: 1000px;
      background-color: $brand-info;
      color: white;
      font-size: 1.6rem;
      font-weight: bold;
      padding: 0.3rem 2rem;
      margin-top: 0;
      margin-left: -5px;
    }
  }
  .company-description {
    color: $brand-blue;
  }
  .company-logo {
    padding-bottom: 2rem;
  }
  .company-url {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    .fa-link {
      color: $brand-blue;
    }
  }
  .company-stats {
    hr {
      margin-top: 0;
      margin-bottom: 0;
    }
    position: relative;
    .icon {
      position: absolute;
      width: 50px;
      top: -2.6rem;
      left: 3rem;
    }
    padding-top: 2.5rem;
    margin-top: 6rem;
    &.top {
      margin-top: 2rem;
    }
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding-bottom: 1rem;
    .stat-inner {
      padding: 2rem 4rem;
      &.cta {
        .btn {
          background-color: $brand-blue !important;
          color: white !important;
          padding: 1rem 2rem;
          font-size: 1.2rem;
          font-weight: bold;
          width: 100%;
        }
      }
      &.main-cta {
        .btn {
          font-weight: bold;
          font-size: 1.2rem;
          width: 100%;
          padding: 1rem 2rem;
        }
      }
    }
    .cta {
      text-align: center;
      a {
        &.btn {
          background-color: $brand-blue !important;
          color: white !important;
          border-radius: 5px;
          padding: 1rem 2rem;
          font-size: 1.2rem;
          font-weight: bold;
          width: 100%;
        }
      }
      p.small {
        font-size: 1.5rem;
        color: $brand-blue;
        margin-top: 1rem;
        a {
          text-decoration: underline;
        }
      }
    }
  }
  blockquote {
    position: relative;
    &::before {
      content: '';
      background-image: url(/sites/all/themes/kisaco/assets/images/k-quote.png);
      background-repeat: no-repeat;
      background-size: 53px;
      font-size: 15rem;
      color: $brand-blue;
      position: absolute;
      left: 3.7rem;
      top: 3rem;
      height: 53px;
      width: 53px;
    }
    color: $brand-blue;
    background-color: rgba($brand-blue, 0.15);
    border-left: none;
    font-weight: bold;
    font-style: normal;
    font-size: 1.9rem;
    max-width: 270px;
    float: left;
    margin-top: 2rem;
    margin-right: 3rem;
    margin-left: -4rem;
    padding: 3.5rem;
    padding-top: 8.5rem;
    @media screen and (max-width: 768px) {
      float: none;
      margin: 0 auto;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
  .more-profiles {
    padding-bottom: 3rem;
    .uk-card-media-top img {
      height: 180px;
      object-fit: cover;
    }
    .card-type {
      font-size: 1.2rem;
      font-weight: bold;
      color: $brand-gray;
      text-transform: uppercase;
    }
    .card-info {
      color: $brand-blue;
    }
    .uk-card-footer {
      border-top: none;
      a {
        border: 2px solid $brand-blue;
        font-size: 1.5rem;
        padding: 0.3rem 1.5rem;
      }
    }
  }
  div[data-autoload-id] {
    .stat-inner.highlight {
      background-color: #f2f2f2;
    }
    .label {
      color: $brand-blue;
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 100;
      padding-left: 0;
      margin-left: 0;
      &::before {
        // font-awesome building icon
        font-family: fontawesome;
        content: '\f0f7';
        font-size: 1.2rem;
        margin-right: 0.5rem;
        margin-left: 0;
        padding-left: 0;
      }
    }
    .value {
      font-size: 1.8rem;
      font-weight: bold;
      color: $brand-blue;
      // if the parent element has a class of "stat-leadership"...
    }
    .stat-leadership {
      .leadership-bundle {
        display: flex;
        gap: 5px;
        align-items: center;
      }
      .value {
        font-weight: normal;
        .fa {
          color: $brand-blue;
          font-size: 1.2rem;
        }
      }
    }
  }
}

.node-case-study {
  // FIXME: why does typography.scss even have this as a rule???
  h2 > a {
    pointer-events: all;
    cursor: pointer;
  }

  &.node-view-featured,
  &.node-view-teaser,
  &.node-view-teaser-alternate {
    padding: 2rem;
    @media screen and (min-width: 768px) {
      padding: 7rem;
    }
    h2 {
      padding-top: 0;
      font-weight: 800;
    }
    background: linear-gradient(
        240deg,
        rgba($brand-blue, 0.3) 0%,
        rgba($brand-blue, 0.6) 30%,
        rgba($brand-blue, 1) 70%,
        rgba($brand-blue, 1) 100%
      ),
      var(--background-image);
    background-size: cover, cover;
    background-position: center center, right center;
    // box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
    .content-inner {
      .field-name-field-company-logo {
        img {
          filter: contrast(50%) grayscale(100%) brightness(20);
          max-height: 100px;
          width: auto;
        }
      }
      @media screen and (width > 768px) {
        display: flex;
        gap: 8rem;
        .client-info {
          .node-speaker {
            h2 {
              text-align: center;
            }
            .field-name-field-featured-image {
              img {
                border-color: white;
              }
            }
          }
        }
      }
      .field-name-field-case-study-type {
        .field-item {
          font-size: $font-size-h3;
          font-weight: 800;
          text-transform: uppercase;
          opacity: 0.9;
          @media screen and (max-width: 768px) {
            font-size: $font-size-h5;
            line-height: 1.2rem;
            text-align: center;
          }
          &:before {
            content: 'Client Case Study: ';
          }
        }
      }
      .field-name-body {
        font-size: 2rem;
        @media screen and (width < 768px) {
          text-align: center;
        }
      }
      * {
        color: white;
      }
      h2 {
        @media screen and (width < 768px) {
          font-size: $font-size-h5;
        }
        @media screen and (width > 768px) {
          text-align: left;
        }
      }
      .field-name-field-key-statistic {
        .field-item {
          @media screen and (width > 768px) {
            padding-left: 0 !important;
          }
        }
        container-type: normal;
        .content {
          padding-top: 3rem;
          @media screen and (width > 768px) {
            i.fa {
              margin-left: 5px;
            }
            .field-name-field-description {
              padding-left: 0;
              text-align: left;
            }
            .field-name-field-description .field-item {
              margin: 0;
            }
            text-align: left;
          }
        }
      }
      .fa {
        font-size: 2.6em;
      }
    }
    .pane-content & {
      .cta {
        margin-top: 2rem;
        @media screen and (width < 768px) {
          margin: 0 auto;
          text-align: center;
          margin-bottom: 4rem;
        }
      }
      .btn {
        border: 2px solid white !important;
        color: white !important;
      }
    }
  }
}
