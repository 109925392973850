/**
 * Styles for topic icons.
 */



/**
 * Topic count for calculating width of each topic button.
 */
@use "sass:math";

$topic-count: 9;
 
.topic-icon-button {
  border: 1px solid lighten($gray, 20%);
  padding: 14px 14px 10px;
  width: calc(#{math.div(100%, $topic-count)} - 10px);
  max-width: calc(#{math.div(100%, $topic-count)} - 10px);
  margin-right: 10px;
  min-height: 118px;
  background: $brand-white;
  color: $brand-blue;
  @include transition(all 0.3s ease);
  .views-field-name {
    margin-top: 5px;
    line-height: floor($font-size-tiny * $line-height-base);
    a, span.field-content {
      font-size: $font-size-tiny;
    }
  }
  i.fa {
    @include transition(all 0.15s ease);
  }
}

.topic-icon-button-link:hover {
  .topic-icon-button:hover {
    margin-top: -5px;
    margin-bottom: 15px;
    border: 1px solid $brand-blue;
    i.fa {
      @include transform(scale(1.06));
    }
  }
}

@media #{$mediaquery-phone} {
  .topic-icon-button {
    width: calc(#{(100% * 0.5)} - 8px);
    max-width: calc(#{(100% * 0.5)} - 8px);
    margin-bottom: 16px;
  }
  .views-row-odd {
    .topic-icon-button {
      margin-right: 16px;
    }
  }
  .views-row-even {
    .topic-icon-button {
      margin-right: 0;
    }
  }
}

@media #{$mediaquery-tablet} {
  .topic-icon-button {
    width: calc(#{(100% * 0.2)} - 10px);
    max-width: calc(#{(100% * 0.2)} - 10px);
    margin-bottom: 10px;
  }
}

@media #{$mediaquery-desktop} {
  .topic-icon-button {
    min-height: 135px;
  }
}

@media #{$mediaquery-desktop} {
  .view-topics {
    .view-content {
      display: flex;
      align-items: stretch;
      justify-content: center;
      .topic-icon-button {
        width: calc(#{math.div(1160px, $topic-count)} - 10px);
        max-width: calc(#{math.div(1160px, $topic-count)} - 10px);
      }
    }
  }
}