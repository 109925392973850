.event-subheader,
.event-home-header {
  margin-top: -22px;
  clear: both;

  background-color: var(--kisaco-event-primary-color);
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    var(--kisaco-event-primary-background-image);
  background-size: cover, cover;

  h5 {
    font-weight: normal;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .event-name-small {
    letter-spacing: 1px;
  }

  .event-date-location-lockup {
    gap: 15px;
    @media screen and (max-width: 767px) {
      text-align: center;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0;
    }
  }

  @media screen and (max-width: 767px) {
    text-align: center;
    .uk-align-right {
      float: none;
      text-align: center;
    }
  }
}
