.page-careers {

  /**
   * Style image on sidebar.
   */
  .radix-layouts-sidebar {
    .field-name-field-basic-image-image {
      padding: 4rem;
    }
  }

  // h4 {
  //   font-size: 30px;
  // }

  /**
   * Style job opportunities.
   */
  #available-jobs {

    .view-content {
      .col-sm-6.general-content-list-item {

        div {
          background: $gray-lighter;
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          padding: 2rem;
          border-radius: 5px;
          height: 180px;
          @media screen and (max-width: 767px) {
            height: auto;
          }
          
        }

        padding-bottom: 30px;
        
      }
    }

  }

  /**
   * Style the team experiences section.
   */
  .view-team-experiences {

    padding-top: 40px;

    padding-bottom: 70px;
    .carousel-inner {
      @media screen and (max-width: 767px) {
        .item {
          padding-left: 50px;
          padding-right: 50px;
        }
      }
    }
    .carousel-indicators {
      margin-bottom: -70px;
    }
    h4.field-content {
      font-weight: bold;
    }
    .views-field-field-featured-image {
      width: 130px;

      @media screen and (min-width: 768px) {
        float: left;
      }

      img {
        width: 130px;
        border-radius: 1000px;
        margin-top: 20px;
      }
    }
    .views-field-field-testimonial-author,
    .views-field-field-content {

      @media screen and (min-width: 768px) {
        margin-left: 130px;
        padding-left: 30px;
      }
      
    }
  }

}