/**
 * Styles for side-by-side content.
 */

.fieldable-panels-pane-side-by-side-text {
  .field-name-field-basic-image-image {
    margin-bottom: 2rem;
  }
  .field-name-field-text-and-body {
    margin-top: 3vmax;
    .field-items {
      h3 {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }
  iframe {
    aspect-ratio: 4/3;
    width: 100%;
  }
}
