/**
 * Style definitions for registration (packages).
 */

.pane-content-packages {
  margin-top: 20px;

  ul.field-items {
    li.kisaco-package {
      > div.package {
        display: flex;
        flex-direction: column;
        height: 100%;
        .field-collection-item-field-package {
          border: 1px solid $gray-lighter;
          border-radius: 5px;
          padding: 15px;
          padding-bottom: 25px;
          margin-bottom: 20px;
          height: 100%;
          .content {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .field-name-field-date {
              flex: 0 2 auto; // always shrink if possible
            }
            .field-name-field-group-name {
              display: flex;
              align-items: flex-end;
              text-transform: uppercase;
              font-weight: 200;
              width: 100%;
              justify-content: center;
              flex: 0 1 40px;
              .field-items {
                text-align: center;
              }
            }
            .field-name-field-price {
              font-size: $font-size-h1;
              font-weight: bold;
              text-align: center;
              background: $gray-lighter;
              min-height: 150px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              /**
               * Since there might be an email address here.
               */
              word-wrap: break-word;
            }
            .field-name-field-includes {
              margin: 0.5em 0 1.4em;
              font-weight: 200;
              text-align: left;
              .field-item {
                padding: 0.6em;
              }
            }
            .field-name-field-tickets-remaining {
              text-align: center;
              color: #bf1d2e;
            }
            .field-name-field-url {
              margin-top: 10px;
              text-align: center;
            }
          }
        }
      }
    }
  }
  &.packages--time_based {
    .field-name-field-tickets-remaining {
      display: none;
    }
  }
  &.packages--inventory_based {
    .package--tickets-remaining--0 {
      //display: none;
    }
  }
}

#package-iframe-holder {
  iframe {
    border: 0;
    width: 100%;
    height: 1000px;
  }
}

#package-iframe-loader {
  display: none;
}

@media #{$mediaquery-phone} {
  #package-iframe-holder {
    margin-left: -25px;
    margin-right: -15px;
    overflow: hidden;
  }
  .pane-content-packages {
    .field-items {
      .field-collection-item-field-package {
        .field-name-field-group-name {
          height: auto;
          .field-items {
            position: static;
          }
        }
      }
    }
  }
}

.loading-text {
  line-height: 3em;
}
