/**
 * On the testimonials page, add a horizontal rule between each.
 */

.node-testimonial {
  padding-top: 3em;
  padding-bottom: 3em;
  border-bottom: 1px solid $brand-gray;
  /**
   * In the context of a carousel, remove the padding at the top.
   */
  .carousel-inner & {
    padding-top: 0;
  }
  img {
    border-radius: 100px;
    /**
     * Panopoly-image-square was originally 200px.
     * Overridden to 500px, need to make it stays 200px here.
     */
    width: 200px;
  }
  .pane-node-field-testimonial-author {
    .field-item::before {
      content: '\00a0\00a0\2014\00a0'; // m-dash and a space
    }
  }
  .field-item p {
    &:first-of-type {
      margin-top: -30px;
    }
    margin-left: 15px;
    .carousel-inner & {
      font-weight: bold;
    }
  }
  .field-name-field-testimonial-author {
    margin-left: 15px;
  }
  /**
   * Override default 30px padding imposed on all .pane-content elements.
   * We want the author to be flush up against the testimonial itself.
   */
  .pane-content {
    padding-bottom: 0 !important;
  }
}
/**
 * Style quote marks.
 */
.fa-quote-left {
  color: rgba($brand-gray, 0.35);
  .light-pane .carousel-inner & {
    color: rgba($brand-white, 0.85);
  }
}

@media #{$mediaquery-desktop}, #{$mediaquery-large-desktop} {
  .node-testimonial {
    margin-left: 9em;
    margin-right: 9em;
  }
}

/**
 * Center image on mobile.
 */
@media #{$mediaquery-phone} {
  .field-name-field-featured-image {
    text-align: center;
  }
  /**
   * Restrict the body width so the arrows are still visible.
   */
  .carousel-inner {
    .field-item p {
      margin-left: 45px;
      margin-right: 45px;
    }
    .fa-quote-left {
      margin-left: 40px;
    }
    .field-name-field-testimonial-author {
      margin-left: 45px;
    }
  }
}

@media #{$mediaquery-tablet} {
  /**
   * Restrict the body width so the arrows are still visible.
   */
  .carousel-inner {
    .node-testimonial {
      margin-left: 5em;
      margin-right: 5em;
    }
  }
}
