.page-event,
.node-type-event,
.is-event-subpage {
  .brand-event-primary {
    background: var(--kisaco-event-primary-color);
    color: var(--kisaco-event-primary-color-text);
  }
  .inverted-pane {
    background: var(--kisaco-event-primary-color);
    color: var(--kisaco-event-primary-color-text);
    .pane-title {
      color: var(--kisaco-event-primary-color-text);
    }
    .pane-content {
      .btn {
        &:hover {
          background: var(--kisaco-event-primary-color-text);
          color: var(--kisaco-event-primary-color);
        }
      }
    }
  }
  .navbar {
    background: var(--kisaco-event-navbar-background-color);
    color: var(--kisaco-event-navbar-link-color);
    box-shadow: var(--kisaco-event-navbar-box-shadow);
    li.highlighted {
      > a {
        background: var(--kisaco-event-navbar-link-special-background-color);
        color: var(--kisaco-event-navbar-link-special-text-color) !important;
        &:hover {
          background-color: var(
            --kisaco-event-navbar-link-special-background-color-hover
          );
        }
      }
    }
  }
  footer {
    background: var(--kisaco-event-primary-color);
    color: var(--kisaco-event-primary-color-text);
  }
  a.icon-circular {
    background: var(
      --kisaco-event-navbar-link-special-background-color
    ) !important;
    color: var(--kisaco-event-navbar-link-special-text-color) !important;
  }
  #footer {
    a {
      background: var(--kisaco-event-primary-color);
      color: var(--kisaco-event-primary-color-text);
    }
    .icon-circular {
      background: #ffffff !important;
      color: var(--kisaco-event-primary-color-text) !important;
    }
  }
  .header-line {
    background: var(--kisaco-event-primary-color);
    color: var(--kisaco-event-primary-color-text);
  }
  .cta-fixed-sidebar {
    background: var(--kisaco-event-primary-color);
    color: var(--kisaco-event-primary-color-text);
  }
  .btn-circle {
    background: var(--kisaco-event-primary-color);
    color: var(--kisaco-event-primary-color-text);
    &:hover {
      background: var(--kisaco-event-primary-color-text);
      color: var(--kisaco-event-primary-color);
    }
  }
  footer#footer {
    .kisaco-footer--contact {
      .social-media-icons {
        .icon-circular {
          .fa {
            color: var(--kisaco-event-primary-color);
          }
        }
      }
    }
  }
  .brand-event-primary-content {
    color: var(--kisaco-event-primary-color);
  }
  .parallax-pane {
    color: var(--kisaco-event-primary-color-text) !important;
    background: linear-gradient(
        var(--kisaco-event-primary-color-transparent-50) 0%,
        var(--kisaco-event-primary-color-transparent-50) 100%
      ),
      var(--kisaco-event-primary-background-image);
    background-size: cover, cover;
    background-position: center center, center center;
    .pane-title {
      color: var(--kisaco-event-primary-color-text);
    }
    * {
      color: var(--kisaco-event-primary-color-text) !important;
    }
  }
  .inverted-pane {
    color: var(--kisaco-event-primary-color-text) !important;
    * {
      color: var(--kisaco-event-primary-color-text) !important;
    }
  }
  .page-header {
    .title {
      color: var(--kisaco-event-primary-color);
    }
  }
  .pane-title {
    color: var(--kisaco-event-primary-color);
  }
  #main-menu {
    .social-media-icons {
      a {
        background-color: var(--kisaco-event-primary-color-text);
        i {
          color: var(--kisaco-event-primary-color);
        }
      }
    }
  }
  .navbar-default .navbar-nav > .active > a,
  .navbar-default .navbar-nav > .active > a:hover,
  .navbar-default .navbar-nav > .active > a:focus,
  .navbar-default .navbar-nav > li > a:hover,
  .navbar-default .navbar-nav > li > a:focus-within {
    border-bottom-color: var(--kisaco-event-navbar-link-hover);
    color: var(--kisaco-event-navbar-link-hover);
  }

  .navbar-expanded {
    #main-menu {
      .social-media-icons {
        a {
          background-color: var(--kisaco-event-navbar-link-color);
          i {
            color: var(--kisaco-event-navbar-background-color);
          }
        }
      }
    }
  }
  .navbar-default {
    .navbar-nav {
      > li {
        > a {
          color: var(--kisaco-event-navbar-link-color);
          &:hover {
            color: var(--kisaco-event-navbar-link-hover);
          }
        }
      }
    }
    .navbar-toggle {
      &:hover {
        span.icon-bar {
          background: var(--kisaco-event-navbar-link-color);
        }
      }
      &:focus {
        span.icon-bar {
          background: var(--kisaco-event-primary-color);
        }
      }
    }
  }
  .navbar-collapse {
    background-color: var(--kisaco-event-navbar-background-color);
  }
  .pane-content {
    a {
      color: var(--kisaco-event-primary-color);
    }
    .btn {
      --_button-bg-color: var(
        --button-bg-color,
        var(--kisaco-event-primary-color)
      );
      --_button-border-color: var(
        --button-border-color,
        var(--kisaco-event-primary-color)
      );
      --_button-border-width: var(--button-border-width, 2px);
      --_button-bg-color-hover: var(
        --button-bg-color-hover,
        var(--color-primary)
      );
      --_button-text-color-hover: var(--button-text-color-hover, white);
      --_button-text-color: var(
        --button-text-color,
        var(--kisaco-event-primary-color-text)
      );
      --_button-font-weight: var(--button-font-weight, 700);
      --_button-padding: var(
        --button-padding,
        var(--button-padding-y, 0.5rem) var(--button-padding-x, 1rem)
      );
      --_button-font-size: var(--button-font-size, var(--text-size-200));
      --_button-shadow: var(--button-shadow, none);

      border-color: var(--_button-bg-color);
      background: var(--_button-bg-color);
      color: var(--_button-text-color);
      &:hover {
        border-color: var(--_button-border-color);
        background: var(--_button-text-color);
        color: var(--_button-bg-color);
      }
    }
    .btn.btn-outline {
      &:hover {
        color: var(--kisaco-event-primary-color);
      }
    }
    .field-collection-item-field-leadership,
    .field-collection-item-field-features {
      .leadership-pane {
        border-top-color: var(--kisaco-event-primary-color);
      }
      .leadership-description-wrapper {
        background: var(--kisaco-event-primary-color);
      }
      .field-name-field-title {
        color: var(--kisaco-event-primary-color);
      }
    }
  }
  h3.field-item {
    color: var(--kisaco-event-primary-color);
  }
  h4 {
    color: var(--kisaco-event-primary-color);
  }
  h5 {
    color: var(--kisaco-event-primary-color);
  }
  h6 {
    color: var(--kisaco-event-primary-color);
  }
  .brand-event-primary-text-color {
    color: var(--kisaco-event-primary-color);
  }
  .field-name-field-text-and-body {
    .field-items {
      .col-md-6.even {
        &:after {
          background: var(--kisaco-event-primary-color);
        }
      }
      .col-md-6.odd {
        &:before {
          background: var(--kisaco-event-primary-color);
        }
      }
    }
  }
  ol {
    li {
      &:before {
        border-color: var(--kisaco-event-primary-color);
        color: var(--kisaco-event-primary-color);
      }
    }
  }
  .basic-pane {
    .carousel-indicators {
      .active {
        background: var(--kisaco-event-primary-color);
      }
      li {
        border-color: var(--kisaco-event-primary-color);
      }
    }
    .diamond-line {
      border-bottom-color: var(--kisaco-event-primary-color);
    }
    .arrow-down {
      border-top-color: var(--kisaco-event-primary-color);
    }
  }
  .light-pane {
    .carousel-indicators {
      .active {
        background: var(--kisaco-event-primary-color);
      }
      li {
        border-color: var(--kisaco-event-primary-color);
      }
    }
    .diamond-line {
      border-bottom-color: var(--kisaco-event-primary-color);
    }
    .arrow-down {
      border-top-color: var(--kisaco-event-primary-color);
    }
    #mini-panel-about_us_condensed {
      .fa {
        color: var(--kisaco-event-primary-color);
      }
    }
  }
  .sl {
    border-top-color: var(--kisaco-event-primary-color);
  }
  .sl-l {
    border-top-color: var(--kisaco-event-primary-color);
  }
  .sl-r {
    border-top-color: var(--kisaco-event-primary-color);
  }
  .triangle {
    border-bottom-color: var(--kisaco-event-primary-color);
    border-right-color: var(--kisaco-event-primary-color);
  }
  .map-overlay {
    .arrow-right {
      border-left-color: var(--kisaco-event-primary-color);
    }
    .arrow-down {
      border-top-color: var(--kisaco-event-primary-color);
    }
  }
  .blur-overlay-wrapper {
    &:hover {
      .blur-overlay-content {
        background: var(--kisaco-event-primary-color-transparent-60);
      }
    }
  }
  .chart {
    .percentage {
      color: var(--kisaco-event-primary-color);
    }
    .chart-label {
      color: var(--kisaco-event-primary-color);
    }
  }
  .pane-content-agenda {
    .field-name-field-day {
      color: var(--kisaco-event-primary-color);
    }
    .field-name-field-time {
      color: var(--kisaco-event-primary-color);
    }
    .agenda-webform {
      h3 {
        color: var(--kisaco-event-primary-color);
      }
    }
  }
  .pane-content-packages {
    .field-name-field-group-name {
      color: var(--kisaco-event-primary-color);
    }
    .field-name-field-price {
      color: var(--kisaco-event-primary-color);
    }
    .field-name-field-includes {
      .fa {
        color: var(--kisaco-event-primary-color);
      }
    }
  }
  #package-iframe-loader {
    .fa {
      color: var(--kisaco-event-primary-color);
    }
  }
  .field-name-field-leadership {
    ul.leadership-tabs {
      li.active {
        border-bottom-color: var(--kisaco-event-primary-color);
        a {
          color: var(--kisaco-event-primary-color);
        }
      }
    }
  }
  .sponsor-description {
    color: var(--kisaco-event-primary-color);
    * {
      color: var(--kisaco-event-primary-color);
    }
    a {
      &:link {
        color: var(--kisaco-event-primary-color);
      }
      &:visited {
        color: var(--kisaco-event-primary-color);
      }
      &:active {
        color: var(--kisaco-event-primary-color);
      }
      &:focus {
        color: var(--kisaco-event-primary-color);
      }
    }
    .header-line {
      background: var(--kisaco-event-primary-color);
    }
    .arrow-down {
      border-top-color: var(--kisaco-event-primary-color);
    }
    .close-button {
      .fa {
        color: var(--kisaco-event-primary-color);
      }
    }
  }
  .attendee-description {
    color: var(--kisaco-event-primary-color);
    * {
      color: var(--kisaco-event-primary-color);
    }
    .header-line {
      background: var(--kisaco-event-primary-color);
    }
  }
  .attendee-content {
    a {
      &:link {
        color: var(--kisaco-event-primary-color);
      }
      &:visited {
        color: var(--kisaco-event-primary-color);
      }
      &:active {
        color: var(--kisaco-event-primary-color);
      }
      &:focus {
        color: var(--kisaco-event-primary-color);
      }
    }
  }
  .pane-about-us-condensed {
    .fa {
      color: var(--kisaco-event-primary-color);
    }
  }
  .speaker-full-bio {
    .fa {
      color: var(--kisaco-event-primary-color);
    }
  }
  .language-options {
    background: var(--kisaco-event-primary-color);
  }
  .countdownclock {
    color: var(--kisaco-event-primary-color);
  }
  .uk-heading-line > ::after,
  .uk-heading-line > ::before {
    border-bottom-color: var(--kisaco-event-primary-color);
  }
}

.event-subheader .subpage-logo {
  max-height: 120px;
  width: auto;
  margin-bottom: 1rem;
}
